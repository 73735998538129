import DashboardStrategy from './dashboard.strategy';
import DashboardData from './dashboard.data';
import React, { useState } from 'react';
import { useGlobal } from 'reactn';

export function Dashboard(props) {
  const [mode, setMode] = useState('activation');
  const [brand, setBrand]: [any, any] = useGlobal('brand');

  if (!brand) {
    return <div></div>;
  }

  if (
    !brand.dataConfig ||
    !brand.dataConfig.reportingTerm ||
    !brand.firstDataRecordDate ||
    !brand.lastDataRecordDate
  ) {
    return <DashboardStrategy setMode={setMode} {...props} />;
  }

  if (mode === 'strategy') {
    return <DashboardStrategy setMode={setMode} {...props} />;
  } else {
    return <DashboardData setMode={setMode} {...props} />;
  }
}

export default Dashboard;
