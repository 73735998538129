import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../../../components/decoration/divider';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import {
  MigrationOvalLeftKey,
  MigrationOvalLostKey,
  MigrationOvalRetainKey,
  MigrationOvalRightKey,
  MigrationOvalRightSmall,
} from '../migration';
import { useGlobal } from 'reactn';
import { Loader } from '../../../../components/loader';
import { ISegmentData, SEGMENTS } from '../../../../metrics/enum';
import { HeadlineMigrationPercent } from '../segment.data.panels';
import { GridUnit } from '../../ladders/components/ladder.step';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

export const SegmentMigraitonPanelContainer = styled.div`
  h2 {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 500;
    color: #657c89;
    margin: 16px 0;
  }

  .segment-counts {
    text-align: center;
  }

  .segment-count-summary {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
    font-weight: 500;
    margin: 4px 0;
  }

  .segment-count-label {
    font-family: Helvetica;
    font-size: 11px;
    color: #8c8c8c;
    text-align: center;
    line-height: 16px;
    font-weight: 500;
    margin: 4px 0;
  }

  .segment-addition {
    font-family: Helvetica;
    font-size: 52px;
    font-weight: 500;
    color: #71b9ca;
    line-height: 52px;
    margin: 0;

    .plus {
      color: #eee;
    }
  }

  .segment-total {
    font-family: Helvetica;
    font-size: 52px;
    font-weight: 500;
    color: #8c8c8c;
    line-height: 52px;
    margin: 0;

    .plus {
      color: #eee;
    }
  }

  .fa-arrow-up {
    font-size: 11px;
  }
`;

export const SegmentMigrationDivider = styled.hr`
  border: 1px dashed #c8c8c8;
`;

export const SegmentMigraitonMovementPanelContainer = styled.div`
  .movement-headline {
    font-family: Helvetica;
    font-size: 14px;
    color: #545454;
    text-align: center;
  }

  .ring {
    border-radius: 9999%;
    width: 120px;
    height: 120px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 40px solid #38627c;

    span {
      font-size: 40px;
      font-weight: bold;
      color: #38627c;
    }
  }

  .ring-loyalists {
    width: calc(120px - 32px);
    height: calc(120px - 32px);
    border: 32px solid #38627c;
  }

  .ring-casuals {
    width: calc(120px - 16px);
    height: calc(120px - 16px);
    border: 16px solid #38627c;
  }

  .ring-prospects {
    width: calc(120px - 8px);
    height: calc(120px - 8px);
    border: 8px solid #38627c;
  }

  .ring-lostsouls {
    border: none;
    width: 120px;
    height: 120px;
    background: black;

    span {
      color: #fff;
    }
  }

  .ring-cheerleaders {
    width: 120px;
    height: 120px;
    background: #38627c;
    border: none;

    span {
      color: #fff;
    }
  }

  .movements-container {
    height: 160px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .movements {
    text-align: right;
    width: 100%;
  }

  .movement {
    text-align: right;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    space-between: 8px;
  }

  .movement-value {
    margin-right: 8px;
  }

  .movement-to {
    .movement {
      align-items: center;
      justify-content: flex-start;
    }

    .movement-value {
      margin-right: 0px;
    }
  }

  .lostsouls {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    background: #000;
    border-radius: 9999%;

    margin-right: 8px;
    color: #fff;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      display: flex;
      color: #fff;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .prospects {
    width: 22px;
    height: 22px;
    border: 1px solid #38627c;
    display: inline-block;
    border-radius: 9999%;
    margin-right: 8px;
    color: #fff;
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .casuals {
    width: 20px;
    height: 20px;
    border: 2px solid #38627c;
    display: inline-flex;
    border-radius: 9999%;
    margin-right: 8px;
    color: #fff;
    position: relative;
    display: inline-block;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .loyalists {
    width: 16px;
    height: 16px;
    border: 4px solid #38627c;
    display: inline-block;
    border-radius: 9999%;

    margin-right: 8px;
    color: #fff;
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .cheerleaders {
    width: 24px;
    height: 24px;
    background: #38627c;
    border-radius: 9999%;

    margin-right: 8px;
    color: #fff;
    position: relative;
    display: inline-block;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      display: flex;
      color: #fff;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .migration-forward {
    width: 31px;
    height: 24px;
    display: inline-flex;
  }

  .migration-backward {
    width: 31px;
    height: 24px;
    display: inline-flex;
  }

  .migration-lost {
    width: 31px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .migration-none {
    width: 31px;
    height: 24px;
    display: inline-flex;
  }
`;

const SegmentMigrationBreakoutContainer = styled.div`
  .migration-value {
    text-align: center;
    font-size: 13px;
  }

  .migration-stay {
    margin-top: -28px;

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 9999%;
      border: 2px solid #71b9ca;
      display: inline-block;
      background: #fff;
    }
  }
`;

const segments = ['lostsouls', 'prospects', 'casuals', 'loyalists', 'cheerleaders'];

const renderAbbreviation = (segment: string) => {
  switch (segment) {
    case 'lostsouls':
      return 'X';
    case 'prospects':
    case 'casuals':
    case 'loyalists':
      return segment.slice(0, 1).toUpperCase();
    case 'cheerleaders':
      return segment.slice(0, 2).toUpperCase();
  }
}

const renderMovementFrom = (data: ISegmentData, segment: string, previousUsers: number) => {
  if (data.migration.from[segment] > 0) {
    return (
      <div className="movement">
        <span className="movement-value">
          ({formatterPercent.format(data.migration.from[segment] / previousUsers)}) {data.migration.from[segment]}
        </span>
        <span className={segment}>
          <span>{renderAbbreviation(segment)}</span>
        </span>
        {segments.indexOf(segment) > segments.indexOf(data.segment.id) && (
          <span className="migration-backward">
            <MigrationOvalLeftKey width={'31px'} height={'24px'} />
          </span>
        )}
        {segments.indexOf(segment) < segments.indexOf(data.segment.id) && (
          <span className="migration-forward">
            <MigrationOvalRightKey width={'31px'} height={'24px'} />
          </span>
        )}
      </div>
    )
  }
}

const renderMovementTo = (data: ISegmentData, segment: string, previousUsers: number) => {
  if (data.migration.to[segment] > 0) {
    return (
      <div className="movement">
        {segment === 'lostsouls' && (
          <span className="migration-lost">
            <MigrationOvalLostKey width={'20px'} height={'20px'} />
          </span>
        )}
        {segment !== 'lostsouls' && segments.indexOf(segment) < segments.indexOf(data.segment.id) && (
          <span className="migration-backward">
            <MigrationOvalLeftKey width={'31px'} height={'24px'} />
          </span>
        )}
        {segment !== 'lostsouls' && segments.indexOf(segment) > segments.indexOf(data.segment.id) && (
          <span className="migration-forward">
            <MigrationOvalRightKey width={'31px'} height={'24px'} />
          </span>
        )}
        
        <span className={segment}>
          <span>{renderAbbreviation(segment)}</span>
        </span>
        <span className="movement-value">
          {data.migration.to[segment]} ({formatterPercent.format(data.migration.to[segment] / previousUsers)})
        </span>
      </div>
    )
  }
}

export function SegmentMigrationCountPanel({ segment }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (quantDataLoading) {
    return (
      <SegmentMigraitonPanelContainer>
        <Loader />
      </SegmentMigraitonPanelContainer>
    );
  }

  const data: ISegmentData = quantData[segment.type];

  if (!data) {
    return null;
  }

  const migration = data.migration;
  const movement = data.movement;

  const segmentTotal = data.current.count;
  const segmentTotalLastPeriod = data.previous.count;
  const segmentTotalDifference = segmentTotal - segmentTotalLastPeriod;

  const segmentAdditionIsPositive = segmentTotalDifference > 0;
  const segmentTotalIsPositive = segmentTotalDifference > 0;
  const segmentTotalDifferencePercent =
    segmentTotalDifference / segmentTotalLastPeriod;

  const previousUsers =
    segment.type === SEGMENTS.PROSPECTS
      ? movement.migrationOut
      : segment.type === SEGMENTS.LOSTSOULS
      ? movement.migrationIn
      : data.previous.users;

  const migrationCountsComponent =
    segment.type === SEGMENTS.LOSTSOULS ||
    segment.type === SEGMENTS.PROSPECTS ? null : (
      <Padding all={3}>
        <Grid>
          <Grid.Unit size={{ sm: 1 / 2 }}>
            <div className="segment-counts">
              <h3 className="segment-addition">
                <span className="plus">+</span>
                {formatterCounts.format(segmentTotalDifference)}
              </h3>
              <h4 className="segment-count-label">Added to Segment</h4>
              <h5 className="segment-count-summary">
                from {formatterCounts.format(segmentTotalLastPeriod)}{' '}
                <span
                  className={`fa fa-arrow-${
                    segmentAdditionIsPositive ? 'up' : 'down'
                  }`}
                />
              </h5>
            </div>
          </Grid.Unit>
          <Grid.Unit size={{ sm: 1 / 2 }}>
            <div className="segment-counts">
              <h3 className="segment-total">
                {formatterCounts.format(segmentTotal)}
              </h3>
              <h4 className="segment-count-label">Segment Total</h4>
              <h5 className="segment-count-summary">
                from {formatterCounts.format(segmentTotalLastPeriod)}{' '}
                <span
                  className={`fa fa-arrow-${
                    segmentTotalIsPositive ? 'up' : 'down'
                  }`}
                />{' '}
                {formatterPercent.format(segmentTotalDifferencePercent)} /{' '}
                {formatterCounts.format(segmentTotalDifference)}
              </h5>
            </div>
          </Grid.Unit>
        </Grid>
      </Padding>
    );

  function getCurrentSegmentTotal() {
    let totalMigrationFrom = 0;
    let totalMigrationTo = 0;
    Object.values(data.migration.from).forEach((entry) => {
      totalMigrationFrom += entry;
    });
    Object.values(data.migration.to).forEach((entry) => {
      totalMigrationTo += entry;
    });

    return totalMigrationFrom - totalMigrationTo;
  }

  return (
    <>
      <SegmentMigraitonPanelContainer>
        <h2>COUNT & MIGRATION</h2>

        <Divider />
        {migrationCountsComponent}
        {migrationCountsComponent && (
          <Padding all={1}>
            <SegmentMigrationDivider />
          </Padding>
        )}
        <Padding all={3}>
          <SegmentMigraitonMovementPanelContainer>
            <Grid>
              <Grid.Unit size={{ sm: 1 / 2 }}>
                <h2 className="movement-headline">MOVEMENT FROM</h2>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 1 / 2 }}>
                <h2 className="movement-headline">MOVEMENT TO</h2>
              </Grid.Unit>
            </Grid>
            <Grid>
              <Grid.Unit size={{ sm: 1 / 3 }}>
                <div className="movements-container movement-from">
                  <div className="movements">
                    {Object.keys(data.migration.from).map(segment => (
                      renderMovementFrom(data, segment, previousUsers)
                    ))}
                  </div>
                </div>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 1 / 3 }}>
                <div className="movements-container">
                  <div className={`ring ring-${data.segment.id}`}>
                    <span>{data.abreviation}</span>
                  </div>
                </div>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 1 / 3 }}>
                <div className="movements-container movement-to">
                  <div className="movements">
                    {Object.keys(data.migration.to).map(segment => (
                      renderMovementTo(data, segment, previousUsers)
                    ))}
                  </div>
                </div>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 1 }}></Grid.Unit>
            </Grid>
          </SegmentMigraitonMovementPanelContainer>
        </Padding>
      </SegmentMigraitonPanelContainer>
      <Padding all={1}>
        <SegmentMigrationDivider />
        <SegmentMigrationBreakoutContainer>
          <Grid>
            <Grid.Unit size={{ sm: 1 / 3 }}>
              <div className="migration-value">
                {segment.type === SEGMENTS.PROSPECTS ? formatterCounts.format(segmentTotal) : formatterCounts.format(movement.migrationIn)}
              </div>
            </Grid.Unit>
            <Grid.Unit size={{ sm: 1 / 3 }}>
              <div className="migration-value">
                <div className="migration-stay">
                  <span className="icon"></span>
                  {segment.type !== SEGMENTS.LOSTSOULS &&
                    segment.type !== SEGMENTS.PROSPECTS && (
                      <div>
                        {formatterPercent.format(
                          movement.stayed / data.previous.users
                        )}{' '}
                        / {formatterCounts.format(movement.stayed)}
                      </div>
                    )}
                  {segment.type === SEGMENTS.LOSTSOULS && (
                      <div>
                        {formatterCounts.format(getCurrentSegmentTotal())}
                      </div>
                  )}
                  {segment.type === SEGMENTS.PROSPECTS && (
                      <div>
                        {formatterCounts.format(data.current.count - data.movement.migrationOut)}
                      </div>
                  )}
                </div>
              </div>
            </Grid.Unit>
            <Grid.Unit size={{ sm: 1 / 3 }}>
              <div className="migration-value">
                {formatterCounts.format(movement.migrationOut)}
              </div>
            </Grid.Unit>
          </Grid>
        </SegmentMigrationBreakoutContainer>
      </Padding>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {/*<HeadlineMigrationPercent>
                          <img
                            style={{ width: 20, height: 20 }}
                            src="/static/movement.png"
                          />
                          Movement
                        </HeadlineMigrationPercent>*/}

        <HeadlineMigrationPercent>
          <MigrationOvalRightKey />
          Migration Forward
        </HeadlineMigrationPercent>
        <HeadlineMigrationPercent>
          <MigrationOvalLeftKey />
          Attrition
        </HeadlineMigrationPercent>
        <HeadlineMigrationPercent>
          <MigrationOvalRetainKey />
          Retention
        </HeadlineMigrationPercent>
        <HeadlineMigrationPercent>
          <MigrationOvalLostKey />
          Lost Soul
        </HeadlineMigrationPercent>
      </div>
    </>
  );
}