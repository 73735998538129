import * as React from 'react';
import * as store from 'store';
export function session() {
  const [session, setSession] = React.useState(store.get('session'));

  function updateSession(token) {
    console.log('update session');
    store.set('session', token);
    return setSession(token);
  }

  return { session, updateSession };
}

export default session;
