import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import { Link } from 'react-router-dom';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
} from '../../components/typography';
import { LinkList, LinkListItem } from '../../components/lists';
import { Divider } from '../../components/decoration/divider';
import { ButtonHollow } from '../../components/buttons';
import { AlignCenter } from '../../components/containers/align';
import SideBar from '../../components/navigation/sidebar';
import breakpoint from 'styled-components-breakpoint';
import { getSegments } from '../../data';
import SVGInline from 'react-svg-inline';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import cacIcon from "../../components/typography/icons/cacIcon";
import { Brand } from './choose';
import {
  makePeriods,
  formatterPercent,
  formatterPercentLong,
  formatterCounts,
  formatter,
  PeriodText,
  SegmentDataArrow,
  SegmentDataArrowContainer,
} from './segment';
import { Loader } from '../../components/loader';
import SelectInput from '../../components/inputs/select';
import { GridUnit } from './ladders/components/ladder.step';
import DateInput from '../../components/inputs/date';
import { DataDateInput } from './components/inputs/date';

import {
  Toggle,
  ToggleActive,
  ToggleActiveBorder,
  SegmentPanel as SegmentPanelStrategy,
} from './dashboard.strategy';

import { makePeriodsCompare } from './segment';
import moment from 'moment';

import { useGlobal } from 'reactn';
import {
  MigrationOvalLeft,
  MigrationOvalLeftKey,
  MigrationOvalLost,
  MigrationOvalLostKey,
  MigrationOvalRetain,
  MigrationOvalRetainKey,
  MigrationOvalRight,
  MigrationOvalRightKey,
} from './components/migration';

import {
  SegmentPanel,
  ProspectPanel,
  SegmentInsightPanel,
} from './components/segment.data.panels';
import { SegmentMenu, SegmentMenuItem } from './components/segment.menu';
import { CurrentPeriod } from './components/current.period';

export const SegmentTitle = styled(HeadlineThree as any)`
  color: #657c89;
  font-size: 14px;
`;
export const SegmentText = styled(Paragraph as any)``;

export const Segments = styled.div`
  position: relative;
`;

export function getBorderBottom(index) {
  return 'none';
}

interface DashboardProps {
  segments: any[];
  downloads: any;
  brand: any;
  history: any;
  setMode: any;
  dataTerm: any;
  dataConfig: any;
  lastDataRecordDate: any;
  firstDataRecordDate: any;
}

export const HeadlineMigrationPercent = styled(HeadlineTwo as any)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 8px 8px;
  color: #545454;

  img {
    margin-right: 4px;
  }
`;

export function Dashboard(props: DashboardProps) {
  const largest = props.segments.reduce((val, segment) => {
    if (!segment.contents.attributes) {
      return val;
    }

    return segment.contents.attributes.length > val
      ? segment.contents.attributes.length
      : val;
  }, 0);

  const largestHeight = Math.ceil((largest / 30) * 18);

  return (
    <Container>
      <SideBar active={'home'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 1 }}>
          <Padding all={2} top={3} bottom={2}>
            <Grid>
              <Grid.Unit size={{ sm: 1, md: 10 / 16 }}>
                <SVGInline
                    className="title-icon"
                    style={{ marginRight: '15px', marginTop: '15px', height: '45px', width: '45px' }}
                    svg={ cacIcon }/>
                <HeadlineOne>CUSTOMER ACTIVATION CYCLE</HeadlineOne>
              </Grid.Unit>
              {/*<Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                <div style={{ textAlign: 'right' }}>
                  <SelectInput
                    inputProps={{
                      value: term,
                      onChange: (e) => setTerm(e.target.value),
                    }}
                    options={[
                      { value: '1-month', label: '1 Month' },
                      { value: '3-months', label: '3 Months' },
                      { value: '6-months', label: '6 Months' },
                      { value: '9-months', label: '9 Months' },
                      { value: '1-year', label: '1 Year' },
                    ]}
                    style={{ margin: 0 }}
                  />
                </div>
                  </Grid.Unit>*/}
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 16 }}>
                <Padding style={{ display: 'none' }} left={2} right={2}>
                  <DataDateInput />
                </Padding>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 16 }}>
                <div
                  style={{
                    visibility: 'hidden',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <DataDateInput />
                </div>
              </Grid.Unit>
            </Grid>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1 / 2 }}>
                    <Padding top={3} left={2} right={2}>
                      <SegmentMenu>
                        <SegmentMenuItem
                          active={true}
                          label={'SEGMENT ACTIVATION'}
                        />
                        <SegmentMenuItem
                          onClick={(e) => props.setMode('strategy')}
                          active={false}
                          label={'DESCRIPTIONS'}
                        />
                      </SegmentMenu>
                      {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          height: '31px',
                        }}
                      >
                        <ToggleActive
                          style={{
                            fontSize: 14,
                          }}
                        >
                          SEGMENT ACTIVATION <ToggleActiveBorder />
                        </ToggleActive>
                        <Toggle
                          style={{
                            fontSize: 14,
                          }}
                          onClick={(e) => props.setMode('strategy')}
                        >
                          DESCRIPTIONS
                        </Toggle>
                      </div> */}
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 / 2 }}>
                    <Padding top={3} left={3} right={2}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <div style={{ display: 'inline-block' }}>
                          <DataDateInput />
                        </div>
                      </div>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Padding left={0} right={0}>
                      <Divider />
                    </Padding>
                  </Grid.Unit>
                </Grid>

                <Segments>
                  <Grid>
                    {props.segments.map((segment, i) => {
                      if (segment.id === 'prospect') {
                        return (
                          <ProspectPanel
                            key={segment.id}
                            id={segment.id}
                            brand={props.brand}
                            index={i}
                            term={null}
                            segment={segment}
                            dataTerm={props.dataTerm}
                            dataConfig={props.dataConfig}
                            largestHeight={largestHeight}
                          />
                        );
                        return null;
                      }

                      return (
                        <SegmentPanel
                          key={segment.id}
                          id={segment.id}
                          brand={props.brand}
                          index={i}
                          term={null}
                          segment={segment}
                          dataTerm={props.dataTerm}
                          dataConfig={props.dataConfig}
                          largestHeight={largestHeight}
                        />
                      );
                    })}
                  </Grid>
                  <Grid>
                    {props.segments.map((segment, i) => {
                      return (
                        <SegmentInsightPanel
                          key={segment.id}
                          id={segment.id}
                          brand={props.brand}
                          index={i}
                          term={null}
                          segment={segment}
                          dataTerm={props.dataTerm}
                          dataConfig={props.dataConfig}
                        />
                      );
                    })}
                  </Grid>
                </Segments>
              </Padding>
              <Grid>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div
                        style={{
                          padding: '16px 16px',
                          display: 'block',
                          width: '100%',
                        }}
                      >
                        <div style={{ textAlign: 'left' }}>
                          {/*<HeadlineMigrationPercent>
                          <img
                            style={{ width: 20, height: 20 }}
                            src="/static/movement.png"
                          />
                          Movement
                        </HeadlineMigrationPercent>*/}

                          <HeadlineMigrationPercent>
                            <MigrationOvalRightKey />
                            Migration Forward
                          </HeadlineMigrationPercent>
                          <HeadlineMigrationPercent>
                            <MigrationOvalLeftKey />
                            Migration Backward
                          </HeadlineMigrationPercent>
                          <HeadlineMigrationPercent>
                            <MigrationOvalRetainKey />
                            Retention
                          </HeadlineMigrationPercent>
                          <HeadlineMigrationPercent>
                            <MigrationOvalLostKey />
                            Lost Soul
                          </HeadlineMigrationPercent>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: '-2px',
                    }}
                  >
                    <CurrentPeriod />
                  </div>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export const StrategicLibrary = (props) => {
  return (
    <Grid.Unit size={{ sm: 1, md: 3 / 12 }}>
      <Padding all={2}>
        <Box>
          <Padding all={2}>
            <Grid>
              <Grid.Unit size={{ sm: 1 }}>
                <Padding all={2}>
                  <HeadlineTwo>STRATEGIC LIBRARY</HeadlineTwo>
                  <Divider />
                </Padding>
                <Padding all={2}>
                  <LinkList>
                    <LinkListItem>
                      <Link to={`/brands/${props.brand}/findings/stakeholder`}>
                        Stakeholder Findings
                      </Link>
                    </LinkListItem>
                    <LinkListItem>
                      <Link to={`/brands/${props.brand}/findings/consumer`}>
                        {' '}
                        Consumer Findings
                      </Link>
                    </LinkListItem>
                    <LinkListItem>
                      <Link to={`/brands/${props.brand}/ladders/affinity`}>
                        Affinity Ladder
                      </Link>
                    </LinkListItem>
                    <LinkListItem>
                      <Link to={`/brands/${props.brand}/ladders/loyalty`}>
                        Loyalty Ladder
                      </Link>
                    </LinkListItem>
                    <LinkListItem>
                      <Link to={`/brands/${props.brand}`}>
                        Customer Activation Cycle
                      </Link>
                    </LinkListItem>
                    <LinkListItem>
                      <Link to={`/brands/${props.brand}/story-universe`}>
                        Story Universe
                      </Link>
                    </LinkListItem>
                  </LinkList>
                </Padding>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 1 }}>
                <Padding all={2}>
                  <HeadlineTwo>DOWNLOADS</HeadlineTwo>
                  <Divider />
                  <Padding all={2}>
                    <LinkList>
                      <LinkListItem>
                        <a
                          target="_blank"
                          style={{ display: 'flex', alignItems: 'center' }}
                          href={`${
                            process.env.REACT_APP_API || 'http://localhost:3000'
                          }/${props.downloads.stakeholderFindings}`}
                        >
                          {' '}
                          <SVGInline
                            style={{ marginRight: '2px', height: '13px' }}
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                          />
                          Stakeholder Findings
                        </a>
                      </LinkListItem>
                      <LinkListItem>
                        <a
                          style={{ display: 'flex', alignItems: 'center' }}
                          href={`${
                            process.env.REACT_APP_API || 'http://localhost:3000'
                          }/${props.downloads.consumerFindings}`}
                          target="_blank"
                        >
                          <SVGInline
                            style={{ marginRight: '2px', height: '13px' }}
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                          />
                          Consumer Findings
                        </a>
                      </LinkListItem>
                      <LinkListItem>
                        <a
                          style={{ display: 'flex', alignItems: 'center' }}
                          href={`${
                            process.env.REACT_APP_API || 'http://localhost:3000'
                          }/${props.downloads.customerActivationCycle}`}
                          target="_blank"
                        >
                          <SVGInline
                            style={{ marginRight: '2px', height: '13px' }}
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                          />
                          Customer Activiation Cycle
                        </a>
                      </LinkListItem>
                      <LinkListItem>
                        <a
                          style={{ display: 'flex', alignItems: 'center' }}
                          target="_blank"
                          href={`${
                            process.env.REACT_APP_API || 'http://localhost:3000'
                          }/${props.downloads.loyaltyArchitecture}`}
                        >
                          <SVGInline
                            style={{ marginRight: '2px', height: '13px' }}
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                          />
                          Loyalty Architecture
                        </a>
                      </LinkListItem>
                      <LinkListItem>
                        <a
                          style={{ display: 'flex', alignItems: 'center' }}
                          target="_blank"
                          href={`${
                            process.env.REACT_APP_API || 'http://localhost:3000'
                          }/${props.downloads.loyaltyBusinessPlan}`}
                        >
                          <SVGInline
                            style={{ marginRight: '2px', height: '13px' }}
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                          />
                          Loyalty Business Plan
                        </a>
                      </LinkListItem>
                      {props.downloads.brandBook && (
                        <LinkListItem>
                          <a
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            target="_blank"
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.brandBook}`}
                          >
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Brand Book
                          </a>
                        </LinkListItem>
                      )}
                    </LinkList>
                  </Padding>
                </Padding>
              </Grid.Unit>
            </Grid>
          </Padding>
        </Box>
      </Padding>
    </Grid.Unit>
  );
};

export const GET_BRAND = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      name
      loyalist {
        id
        contents
      }
      prospect {
        id
        contents
      }
      cheerleader {
        id
        contents
      }
      casual {
        id
        contents
      }
      firstDataRecordDate
      lastDataRecordDate
      dataConfig {
        reportingTerm
        prospects {
          users
          costPer
        }
      }
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export function DashboardLoader(props: any) {
  const { data, error, loading } = useQuery(GET_BRAND, {
    variables: { id: props.match.params.brand },
  });

  if (error) return null;
  if (loading || !data) return null;

  const segments = [
    { ...data.brand.prospect, name: 'PROSPECTS', id: 'prospect' },
    { ...data.brand.casual, name: 'CASUALS', id: 'casual' },
    { ...data.brand.loyalist, name: 'LOYALISTS', id: 'loyalist' },
    { ...data.brand.cheerleader, name: 'CHEERLEADERS', id: 'cheerleader' },
  ];

  return (
    <Dashboard
      {...props}
      brand={props.match.params.brand}
      downloads={data.brand.downloads || {}}
      segments={segments}
      dataTerm={data.brand.dataConfig.reportingTerm}
      setMode={props.setMode}
      firstDataRecordDate={data.brand.firstDataRecordDate}
      lastDataRecordDate={data.brand.lastDataRecordDate}
      dataConfig={data.brand.dataConfig}
    />
  );
}

export default DashboardLoader;
