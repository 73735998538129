import * as React from 'react';
import {
  insightsCountAsPercentage,
  insightsMigrationForward,
  insightsRevenueAsPercentage,
} from './common';

export function insightsCasualCountAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsCountAsPercentage(benchmark, value, score);
}

export function insightsCasualRevenueAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsRevenueAsPercentage(benchmark, value, score);
}

export function insightsCasualMigrationForward(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsMigrationForward(benchmark, value, score);
}

export function insightsCasualCostToConvert(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: 'New Visitor Cost to Convert above standard',
        short: 'New Visitor Cost',
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: 'New Visitor Cost to Convert below standard',
        short: 'New Visitor Cost',
        negative: true,
      },
    ];
  }
}

export function insightsCasualCostToActivate(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: 'Cost to Activate above standard',
        short: 'Cost to Activte',
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: 'Cost to Activate below standard',
        short: 'Cost to Activte',
        negative: true,
      },
    ];
  }
}
