import * as React from 'react';
import { IQuantData, ISegmentData, SEGMENTS } from './enum';
import { scoreCasuals } from './formulas/casuals';
import { scoreCheerleaders } from './formulas/cheerleaders';
import { scoreLoyalists } from './formulas/loyalists';
import { scoreProspects } from './formulas/prospects';

export const STANDARD_SCALE = [
  { low: 0, high: 0.25, value: 0 },
  { low: 0.25, high: 0.5, value: 1 },
  { low: 0.5, high: 0.75, value: 2 },
  { low: 0.75, high: 1, value: 3 },
  { low: 1, high: 1.25, value: 4 },
  { low: 1.25, high: 100, value: 5 },
];

export const PROSPECT_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.015,
};

export const CASUAL_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.15,
  COUNT_AS_PERCENTAGE: 0.7,
  REVENUE_AS_PERCENTAGE: 0.33,
  ACTIVATION_COST: 1,
  CONVERSION_COST: 1,
};

export const LOYALIST_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.2,
  COUNT_AS_PERCENTAGE: 0.2,
  REVENUE_AS_PERCENTAGE: 0.33,
  ACTIVATION_COST: 1,
  IND_VALUE: 3,
};

export const CHEERLEADER_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.8,
  COUNT_AS_PERCENTAGE: 0.1,
  REVENUE_AS_PERCENTAGE: 0.33,
  ACTIVATION_COST: 1,
  IND_VALUE: 12,
};

export function scoreProspectSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreProspects(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    PROSPECT_SCORING_BENCHMARKS
  );
}

export function scoreCasualSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreCasuals(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    CASUAL_SCORING_BENCHMARKS
  );
}

export function scoreLoyalistSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreLoyalists(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    LOYALIST_SCORING_BENCHMARKS
  );
}

export function scoreCheerleaderSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreCheerleaders(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    CHEERLEADER_SCORING_BENCHMARKS
  );
}

export function getColorForScore(score) {
  if (score > 4) {
    return '#574080';
  }

  if (score > 3) {
    return '#715E94';
  }

  if (score > 2) {
    return '#9C8FB4';
  }

  if (score > 1) {
    return '#BBB3CC';
  }

  if (score > 0) {
    return '#DDD9E6';
  }

  return '#DDD9E6';
}

export function getTextColorForScore(score) {}
