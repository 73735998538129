export default {
  name: `We believe in putting smiles on people's faces`,
  children: [
    {
      name: `Make life easy (eaiser)`,
      children: [
        {
          name: 'SERVICE',
          children: [
            {
              name: 'Cast Iron Guarantee',
              children: [
                {
                  name: 'It works',
                  children: [{ name: 'Functionality' }, { name: 'Longevity' }]
                }
              ]
            },
            {
              name: `Tailored Approach`,
              children: [
                {
                  name: `We're here to help make life go right`,
                  children: [
                    { name: `Stretch your dollars` },
                    { name: `Digital access` },
                    { name: `Human access` }
                  ]
                },
                {
                  name: `Honor the individual`,
                  children: [
                    { name: 'Kids matter' },
                    { name: 'Gender Matters' },
                    { name: 'Size, fit matters' },
                    { name: 'Everyone is an original' }
                  ]
                }
              ]
            },
            {
              name: 'Customer Understanding',
              children: [
                {
                  name: 'Shared worldview',
                  children: [
                    { name: `Family` },
                    { name: `Community` },
                    { name: `Get outisde` },
                    { name: `Lifelong skier` },
                    { name: `Love of the sport` },
                    { name: `Shared experience` }
                  ]
                },
                {
                  name: `Life is hard`,
                  children: [{ name: `Joy is possible` }]
                },
                {
                  name: `Skiing`,
                  children: [
                    { name: `Fun` },
                    { name: `Self-expressive` },
                    { name: `Free-spirited` },
                    { name: `Inclusive` }
                  ]
                }
              ]
            },
            {
              name: `Be Responsible`,
              children: [
                { name: 'Solve a problem' },
                { name: `Remove the burden` },
                { name: `Be posotive` },
                { name: 'Have fun' }
              ]
            },
            {
              name: 'Service Network',
              children: [
                {
                  name: 'Dealers',
                  children: [
                    {
                      name: 'Pick up in store'
                    },
                    { name: 'Find an export' },
                    { name: 'Resort' },
                    { name: 'Local mountain' }
                  ]
                },
                {
                  name: 'Digital',
                  children: [
                    { name: 'Access a store' },
                    { name: 'Access a person' },
                    { name: 'Access a product' },
                    { name: 'Be part of the solution' }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: `Things must work for everyone`,
      children: [
        {
          name: 'COMPANY',
          children: [
            {
              name: 'Our Story',
              children: [
                {
                  name: `Our culture`,
                  children: [{ name: 'Transparent, inclusive' }]
                },
                {
                  name: `Our process`,
                  children: [{ name: 'Solving problems' }]
                },
                { name: `Our Name`, children: [{ name: 'Living the life' }] },
                { name: `Our way`, children: [{ name: 'Pursue happiness' }] },
                { name: `Our why`, children: [{ name: 'Smile' }] }
              ]
            },
            {
              name: `Founding philosophy`,
              children: [
                {
                  name: `Get people outside`,
                  children: [{ name: `It's good for people & planet` }]
                },
                {
                  name: `The love of the sport`,
                  children: [{ name: '88 at 88' }, { name: 'Schussing' }]
                },
                {
                  name: `Integrity`,
                  children: [
                    { name: 'Keep our promises' },
                    { name: 'Do the right thing' }
                  ]
                },
                { name: `Family`, children: [{ name: 'Kids matter' }] },
                {
                  name: `Social good`,
                  children: [{ name: 'Let my people go skiing' }]
                }
              ]
            },
            {
              name: `Character`,
              children: [
                { name: `Win win` },
                { name: `Ski in style` },
                { name: `Realistic` },
                { name: `Thoughtful` },
                { name: `Holistic View` },
                { name: `Transparency` },
                { name: `Skiiing idealized` },
                { name: `Embrace all(inclusive)` },
                { name: `Challenge the status quo` }
              ]
            },
            {
              name: `History`,
              children: [
                {
                  name: `Innovation`,
                  children: [
                    { name: 'List of firsts' },
                    { name: 'Leading the industry' },
                    { name: `There's always a better way` }
                  ]
                },
                {
                  name: `Klaus`,
                  children: [{ name: `Inspiration` }, { name: `Optimism` }]
                },
                {
                  name: `Living the life`,
                  children: [
                    { name: `70's style icon` },
                    { name: `Freewheeling` },
                    { name: `Freedom` }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: `Make skiing more fun`,
      children: [
        {
          name: 'PRODUCT',
          children: [
            {
              name: 'Design Thoughtfullness',
              children: [
                {
                  name: 'Families',
                  children: [
                    { name: 'Moms' },
                    { name: 'Travel' },
                    { name: 'Kids grow' },
                    { name: 'Systems' },
                    { name: 'Bundles' }
                  ]
                },
                {
                  name: 'Technology',
                  children: [
                    { name: 'When it matters' },
                    { name: 'Where it matters' },
                    { name: 'Not over built' },
                    { name: 'Not under built' }
                  ]
                },
                {
                  name: 'Style',
                  children: [
                    { name: 'Colors' },
                    { name: 'Prints' },
                    { name: 'Fun' }
                  ]
                },
                {
                  name: 'Individualized',
                  children: [
                    { name: 'Get noticed' },
                    { name: 'Lets me be me' },
                    { name: 'Everyone is different' }
                  ]
                },
                {
                  name: 'Elegance',
                  children: [{ name: 'Ingenious' }, { name: 'Simple' }]
                },
                {
                  name: 'Empathy',
                  children: [
                    { name: `How it's really used` },
                    { name: 'Who is using it' }
                  ]
                }
              ]
            },
            {
              name: `Responsiblity`,
              children: [
                {
                  name: `Sustainability`,
                  children: [{ name: 'The Natural Step' }, { name: 'Copper' }]
                },
                {
                  name: `Transparency`,
                  children: [{ name: `Where it's made` }]
                },
                { name: `Social Good`, children: [{ name: `How it's made` }] },
                {
                  name: `Longevity`,
                  children: [
                    { name: 'Recycle' },
                    { name: 'Repair' },
                    { name: 'Renew' },
                    { name: 'Rent' }
                  ]
                }
              ]
            },
            {
              name: `Quality Delivered`,
              children: [
                {
                  name: `Solving problems`,
                  children: [
                    { name: 'Sizes for everyone' },
                    { name: 'End of life solved' },
                    { name: 'Fit to perform' },
                    { name: 'Designed to move' },
                    { name: 'Dry all day' },
                    { name: 'Grows with you' },
                    { name: 'Warmth without bulk' },
                    { name: 'Getting on mountain' },
                    { name: 'Getting off mountain' }
                  ]
                },
                {
                  name: `Value algorithm`,
                  children: [
                    { name: 'It works' },
                    { name: 'Durable' },
                    { name: 'Looks Good' }
                  ]
                }
              ]
            },
            {
              name: `New Standard`,
              children: [
                { name: `Complicated made easy` },
                { name: `Short-lived made to last` },
                { name: `Serious made lighthearted` },
                { name: `Expesnive made affordable` },
                { name: `Uncomfortable made comfortable` }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export const wanderlustNodes = {
  name: `We believe the best of hummanity is an inspiring good`,
  children: [
    {
      children: [
        {
          children: [
            {
              children: [{ children: [] }, { children: [] }, { children: [] }]
            },
            { children: [{}, {}, {}] },
            { children: [{ children: [{}] }] },
            { children: [{}, {}, {}, {}] },
            { children: [{}, {}, {}] },
            { children: [{}, {}, {}, {}, {}, {}, {}, {}] }
          ]
        }
      ]
    },
    {
      children: [
        {
          children: [
            {
              children: [
                { children: [{}, {}, {}] },
                { children: [{}, {}] },
                { children: [{}, {}, {}] }
              ]
            },
            {
              children: [
                { children: [{}, {}, {}] },
                { children: [{}, {}, {}] },
                { children: [{}, {}] }
              ]
            },
            { children: [{ children: [{}, {}] }] },
            { children: [{}, {}, {}, {}] },
            { children: [{}, {}, {}, {}] },
            { children: [{ children: [{}] }, { children: [{}] }] }
          ]
        }
      ]
    },
    {
      children: [
        {
          children: [
            {
              children: [
                { children: [{}, {}, {}, {}, {}] },
                { children: [{}, {}, {}] },
                { children: [{}, {}, {}] },
                { children: [{}, {}, {}] },
                { children: [{}, {}, {}, {}, {}] }
              ]
            },
            {
              children: [{ children: [{}, {}] }, { children: [{}, {}, {}] }]
            },
            { children: [{ children: [{}, {}, {}] }] },
            { children: [{}, {}, {}, {}, {}, {}, {}, {}] },
            { children: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}] }
          ]
        }
      ]
    }
  ]
};

export const sitkaNodes = {
  name: `We believe hummanity is an integral part of the ecosystem`,
  children: [
    {
      name: 'Improving the experience of life',
      children: [
        {
          name: 'PRODUCT',
          children: [
            {
              name: 'Quality Defined',
              children: [
                { name: 'Smart Design', children: [{}, {}, {}] },
                { name: 'Innovation', children: [{}, {}] },
                { name: 'Performance', children: [{}, {}, {}] },
                { name: 'Construction', children: [{}, {}] }
              ]
            },
            {
              name: 'Accountable',
              children: [{ name: 'Guarantee', children: [{}] }]
            },
            {
              name: 'Created by hunters',
              children: [
                { name: 'After', children: [{}] },
                { name: 'During' },
                { name: 'Before' }
              ]
            },
            {
              name: 'Holistic Thinking',
              children: [{ name: 'Beyond apparel' }, { name: 'Integrated' }]
            },
            { name: 'As Hunter', children: [{ name: 'Activity' }] },
            {
              name: 'As Predator',
              children: [{ name: 'Activity' }, { name: 'Condition' }]
            }
          ]
        }
      ]
    },
    {
      name: 'Hunting is living',
      children: [
        {
          name: 'SITKA LIFE',
          children: [
            {
              name: 'Preperation',
              children: [
                { name: 'Mental', children: [] },
                { name: 'Physical' },
                { name: 'Emotional' },
                { name: 'Spirtual' }
              ]
            },

            {
              name: 'Persona',
              children: [
                { name: '24/7/365', children: [] },
                { name: 'Lifelong', children: [] },
                { name: 'Ethose permeate everything', children: [] },
                { name: 'A thousand shots' }
              ]
            },
            {
              name: 'History',
              children: [{ name: 'Primal', children: [] }, { name: 'Gentric' }]
            },
            {
              name: 'Knowledge',
              children: [
                { children: [], name: 'Weather' },
                { name: 'Geography' },
                { name: 'Tracking' },
                { name: 'Migration' },
                { name: 'Animal behaviour' },
                { name: 'Species understanding' }
              ]
            },
            {
              name: 'The Garage',
              children: [
                { name: 'Sanctuary', children: [] },
                { name: 'Planning', children: [] },
                { name: 'Inventory', children: [] },
                { name: 'Continuity', children: [] }
              ]
            },
            {
              name: 'Lineage',
              children: [
                { name: 'Family', children: [] },
                { name: 'Mentors' },
                { name: 'Elders' }
              ]
            },
            {
              name: 'Harvest',
              children: [
                { name: 'Instinctual', children: [] },
                { name: 'Connection' }
              ]
            },

            {
              name: 'Culmination',
              children: [{ name: 'Life in one moment', children: [] }]
            },
            {
              name: 'Quiver',
              children: [
                { name: 'Camera', children: [] },
                { name: 'Firearms' },
                { name: 'Bow' }
              ]
            },
            {
              name: 'Mastery',
              children: [
                { name: 'Becoming a predator', children: [] },
                { name: 'Solving the puzzle' },
                { name: 'Satisfaction' },
                { name: 'Always training' },
                { name: 'Always learning' }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Doing many things well',
      children: [
        {
          name: 'COMPANY',
          children: [
            {
              name: 'Integrity',
              children: [
                { name: 'Values', children: [{}, {}, {}, {}, {}, {}, {}, {}] },
                { name: 'Social', children: [{}, {}] },
                { name: 'Ethical', children: [{}] },
                { name: 'Environmental', children: [{}, {}, {}, {}, {}] }
              ]
            },
            {
              name: 'Philosophy',
              children: [
                { name: 'Nature', children: [{}, {}] },
                { name: 'Service', children: [{}] },
                { name: 'Design', children: [{}, {}] },
                { name: 'Harvest', children: [{}, {}, {}] }
              ]
            },
            {
              name: 'Stories',
              children: [
                { name: 'Audio', children: [{}, {}, {}] },
                { name: 'Written' },
                { name: 'Video' }
              ]
            },
            {
              name: 'Distribution',
              children: [
                { name: 'Direct' },
                { name: 'Influencer' },
                { name: 'Retail' },
                { name: 'Wholesale' }
              ]
            },
            {
              name: 'Collective',
              children: [
                { name: 'Customers' },
                { name: 'Athletes' },
                { name: 'Ambassadors' },
                { name: 'Storytellers' },
                { name: 'Writers' },
                { name: 'Photographers' },
                { name: 'Filmmakers' },
                { name: 'Guides' },
                { name: 'Associations' },
                { name: 'Clubs' }
              ]
            }
          ]
        }
      ]
    }
  ]
};
