import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import { getSegment } from '../../data';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { stringToOrderedList } from '../../utils';
import {
  drawStoryUniverse,
  handleMouseOut,
  handleMouseOver,
} from './story-universe/renderer/render';
import { LinkList, LinkListItem, LinkListHeader } from '../../components/lists';
import {
  StoryUniverseContainer,
  Overlay,
  NodeInfo,
} from './story-universe/story.universe.screen';
import moment from 'moment';
import { Loader } from '../../components/loader';
import SelectInput from '../../components/inputs/select';
import DateInput from '../../components/inputs/date';
import { HeadlineMigrationPercent } from './dashboard.data';

import { useGlobal } from 'reactn';
import { SegmentMenu, SegmentMenuItem } from './components/segment.menu';
import { SegmentMigrationCountPanel } from './components/panels/segment.migration.panel';
import { SegmentValuePanel } from './components/panels/segment.value.panel';
import { SegmentBACPanel } from './components/panels/segment.bac.panel';
import { DataDateInput } from './components/inputs/date';
import { SegmentCAC } from './components/segment.cac';
import { SegmentHealthBar } from './components/segment.health';

export const SegmentTitle = styled(HeadlineThree as any)``;
export const SegmentText = styled(Paragraph as any)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  segment: any;
  brand: any;
  name: any;
  nodes: any;
  tag: any;
  segmentId: any;
}

export const NextSegment = styled.div`
  width: 100%;
  text-align: right;
`;

export const MIGRATION = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
        metricDataCompare(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
      }
      segmentMigrationData(
        type: $type
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

const types = {
  prospect: {
    type: 'SEGMENT_PROSPECTS',
    name: 'PROSPECTS',
    tag: 'prospects',
    abv: 'P',
  },
  casual: {
    type: 'SEGMENT_CASUAL',
    name: 'CASUALS',
    tag: 'casuals',
    abv: 'CA',
  },
  cheerleader: {
    type: 'SEGMENT_CHEERLEADER',
    name: 'CHEERLEADERS',
    tag: 'cheerleaders',
    abv: 'CH',
  },
  cheerleaders: {
    type: 'SEGMENT_CHEERLEADER',
    name: 'CHEERLEADERS',
    tag: 'cheerleaders',
    abv: 'CH',
  },
  loyalist: {
    type: 'SEGMENT_LOYALIST',
    name: 'LOYALISTS',
    tag: 'loyalists',
    abv: 'L',
  },
  lostsouls: {
    type: 'SEGMENT_LOSTSOULS',
    name: 'LOST SOULS',
    tag: 'lostsouls',
    abv: 'X',
  },
};

export function Segment(props: DashboardProps) {
  return (
    <Container>
      <SideBar active={'segments'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <div style={{ marginTop: '2.5rem' }}></div>
        </Grid.Unit>

        <Grid.Unit size={{ sm: 1, md: 1 / 2 }}>
          <Padding all={2}>
            <HeadlineOne dataSection={true}>{props.name}</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 1 / 2 }}>
          <SegmentCAC segment={props.segment} />
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1 }}>
          <SegmentHealthBar segment={props.segment} />
        </Grid.Unit>
      </Grid>

      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={3}>
                <Grid>
                  <Grid.Unit size={{ sm: 1 / 2 }}>
                    <Padding top={3} left={3} right={3}>
                      <SegmentMenu>
                        <SegmentMenuItem
                          href={`/brands/${props.brand}/segment/${props.segmentId}`}
                          active={false}
                          label={'SEGMENT ACTIVATION'}
                        />
                        <SegmentMenuItem
                          href={`/brands/${props.brand}/segment/${props.segmentId}/compare`}
                          active={false}
                          label={'COMPARISONS'}
                        />
                        <SegmentMenuItem
                          href={`/brands/${props.brand}/segment/${props.segmentId}/description`}
                          active={true}
                          label={'DESCRIPTION'}
                        />
                      </SegmentMenu>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 / 2 }}>
                    <Padding top={3} left={3} right={3}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <div style={{ display: 'inline-block', opacity: 0 }}>
                          <DataDateInput />
                        </div>
                      </div>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Divider style={{ marginTop: 8, marginBottom: 4 }} />
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>DEFINITION</HeadlineTwo>
                      <Divider />
                      <ParagraphLarge>
                        {props.segment.contents.description}
                      </ParagraphLarge>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                    <Grid>
                      <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                        <Padding all={3}>
                          <HeadlineTwo>STRATEGIES</HeadlineTwo>
                          <Divider />

                          <SegmentText>
                            <b>Attributes:</b>
                            <br />
                            {props.segment.contents.attributes}
                          </SegmentText>
                          <SegmentText>
                            <b>Goals:</b>
                            <br />
                            {props.segment.contents.goals}
                          </SegmentText>
                          <SegmentText>
                            <b>Process:</b>
                            <br />
                            {props.segment.contents.process}
                          </SegmentText>
                          <SegmentText>
                            <b>Key Consumer Insight:</b>
                            <br />
                            {props.segment.contents.insights}
                          </SegmentText>
                        </Padding>
                      </Grid.Unit>
                      <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                        <Padding all={3}>
                          <HeadlineTwo>TRIGGER</HeadlineTwo>
                          <Divider />

                          <SegmentText>
                            <b>Key Experience:</b>
                            <br />
                            {props.segment.contents.triggersKeyExperience}
                          </SegmentText>
                          <SegmentText>
                            <b>Discovery:</b>
                            <br />
                            {props.segment.contents.triggersDiscovery}
                          </SegmentText>
                          <SegmentText>
                            <b>Touchpoint:</b>
                            <OrderedList>
                              {/*props.segment.triggers.touchpoints.map(
                                (touchpoint, i) => {
                                  return (
                                    <OrderedListItem key={i}>
                                      {touchpoint}
                                    </OrderedListItem>
                                  );
                                }
                              )*/}
                              {stringToOrderedList(
                                props.segment.contents.triggersTouchpoint,
                                (touchpoint, i) => {
                                  return (
                                    <OrderedListItem key={i}>
                                      {touchpoint}
                                    </OrderedListItem>
                                  );
                                }
                              )}
                            </OrderedList>
                          </SegmentText>
                        </Padding>
                      </Grid.Unit>
                    </Grid>
                    <Grid>
                      <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                        <Padding all={3}>
                          <HeadlineTwo>TACTICAL COMMS</HeadlineTwo>
                          <Divider />
                          <OrderedList>
                            {/*props.segment.comms.map((touchpoint, i) => {
                              return (
                                <OrderedListItem key={i}>
                                  {touchpoint}
                                </OrderedListItem>
                              );
                            })*/}
                            {stringToOrderedList(
                              props.segment.contents.tacticalComms,
                              (touchpoint, i) => {
                                return (
                                  <OrderedListItem key={i}>
                                    {touchpoint}
                                  </OrderedListItem>
                                );
                              }
                            )}
                          </OrderedList>
                        </Padding>
                      </Grid.Unit>
                      <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                        <Padding all={3}>
                          <HeadlineTwo>CALLS TO ACTION</HeadlineTwo>
                          <Divider />
                          <OrderedList>
                            {/*props.segment.cta.map((touchpoint, i) => {
                              return (
                                <OrderedListItem key={i}>
                                  {touchpoint}
                                </OrderedListItem>
                              );
                            })*/}
                            {stringToOrderedList(
                              props.segment.contents.callsToAction,
                              (touchpoint, i) => {
                                return (
                                  <OrderedListItem key={i}>
                                    {touchpoint}
                                  </OrderedListItem>
                                );
                              }
                            )}
                          </OrderedList>
                        </Padding>
                      </Grid.Unit>
                    </Grid>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <StoryUniverse
                  name={props.name}
                  tag={props.tag}
                  nodes={props.nodes}
                />
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export function makePeriodsCompare(term, base, compare) {
  const terms = {
    '1-year': 1,
    '2-year': 2,
  };

  const periodOneStart = moment(base, 'YYYY-MM-DD')
    .subtract(terms[term], 'years')
    .format('YYYY-MM-DD');

  const periodOneEnd = moment(base, 'YYYY-MM-DD').format('YYYY-MM-DD');

  const periodTwoEnd = moment(compare, 'YYYY-MM-DD').format('YYYY-MM-DD');

  const periodTwoStart = moment(compare, 'YYYY-MM-DD')
    .subtract(terms[term], 'years')
    .format('YYYY-MM-DD');

  const periodThreeStart = moment()
    .subtract(terms[term] * 3, 'years')
    .format('YYYY-MM-DD');

  const periodThreeEnd = moment()
    .subtract(terms[term] * 2, 'years')
    .format('YYYY-MM-DD');

  return {
    periodOneStart,
    periodOneEnd,
    periodTwoStart,
    periodTwoEnd,
    periodThreeStart,
    periodThreeEnd,
  };
}

export function SegmentCompareLoader(props) {}

export function SegmentCompare(props) {
  const [base, setBase] = useGlobal(`${props.compareKeyPrefix}_base`);
  const [compare, setCompare] = useGlobal(`${props.compareKeyPrefix}_compare`);

  const dates = makePeriodsCompare(
    props.brandData.dataConfig.reportingTerm,
    base,
    compare
  );

  useEffect(() => {
    if (!base && props.compareKeyPrefix === 'term_one') {
      setBase(props.lastDataRecordDate);
      setCompareValue(
        moment(props.lastDataRecordDate, 'YYYY-MM-DD')
          .subtract(3, 'months')
          .format('YYYY-MM-DD')
      );
    }

    if (!base) {
      // setBase(props.lastDataRecordDate);
    }
  }, []);

  const options = [
    {
      value: moment(base).format('YYYY-MM-DD'),
      label: 'Today',
    },
    {
      value: moment(base).subtract(1, 'month').format('YYYY-MM-DD'),
      label: '1 Month',
    },
    {
      value: moment(base).subtract(3, 'months').format('YYYY-MM-DD'),
      label: '3 Months',
    },
    {
      value: moment(base).subtract(6, 'months').format('YYYY-MM-DD'),
      label: '6 Months',
    },
    {
      value: moment(base).subtract(9, 'months').format('YYYY-MM-DD'),
      label: '9 Months',
    },
    {
      value: moment(base).subtract(12, 'months').format('YYYY-MM-DD'),
      label: '1 Year',
    },
  ];

  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } = dates;

  const hasBase = !(!base || !compare);

  function setBaseValue(value) {
    setBase(value);
    setCompare(value);
  }

  function setCompareValue(value) {
    setCompare(value);
  }

  return (
    <Padding all={2}>
      <Padding all={2}>
        <Grid>
          <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <Padding left={2} right={2} top={0}>
                  {props.first && <HeadlineTwo>Value</HeadlineTwo>}
                </Padding>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                <DateInput
                  today={props.lastDataRecordDate}
                  min={props.firstDataRecordDate}
                  max={props.lastDataRecordDate}
                  label={'Select Base'}
                  inputProps={{
                    value: base,
                    onChange: (value) => setBaseValue(value),
                  }}
                  options={options}
                  style={{ margin: 0, marginTop: 40 }}
                />
              </Grid.Unit>
              <Grid.Unit
                className="grid-unit"
                style={{ textAlign: 'right' }}
                size={{ sm: 3 / 12 }}
              >
                <Padding left={2} right={2} top={0}>
                  <DateInput
                    today={props.lastDataRecordDate}
                    disabled={!base}
                    min={props.firstDataRecordDate}
                    max={props.lastDataRecordDate}
                    label={'Select Previous'}
                    inputProps={{
                      value: compare,
                      onChange: (value) => setCompareValue(value),
                    }}
                    base={base}
                    options={options}
                    hasRelativeOptions={true}
                    style={{ margin: 0, marginTop: 40 }}
                  />
                </Padding>
              </Grid.Unit>
            </Grid>
          </Grid.Unit>
        </Grid>
      </Padding>
      {hasBase && <SegmentViz dates={dates} {...props} />}
    </Padding>
  );
}

export function SegmentViz(props) {
  const { dates } = props;

  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } =
    props.dates;

  const { data, error, loading } = useQuery(MIGRATION, {
    variables: {
      id: props.brand,
      type: types[props.segmentId].type,
      periodOneStart,
      periodTwoStart,
      periodOneEnd,
      periodTwoEnd,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  return (
    <div>
      {props.segmentId !== 'lostsouls' &&
        props.segmentId !== 'prospect' &&
        props.segmentId !== 'prospects' && (
          <SegmentCountData
            term={props.brandData.dataConfig.reportingTerm}
            segment={props.segmentId}
            brand={props.brandId}
            dates={dates}
            data={data}
          />
        )}

      <SegmentMigration
        term={props.brandData.dataConfig.reportingTerm}
        segment={props.segmentId}
        brand={props.brandId}
        dates={dates}
        showKey={false}
        data={data}
      />
    </div>
  );
}

export function SegmentWithData(props) {
  // const [term, setTerm] = useGlobalState('term');
  const [term, setTerm] = useGlobal('term');

  return (
    <Container>
      <SideBar active={'segments'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <div style={{ marginTop: '2.5rem' }}></div>
        </Grid.Unit>

        <Grid.Unit size={{ sm: 1, md: 1 / 2 }}>
          <Padding all={2}>
            <HeadlineOne>{props.name}</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 1 / 2 }}>
          <SegmentCAC segment={props.segment} />
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1 }}>
          <SegmentHealthBar segment={props.segment} />
        </Grid.Unit>
      </Grid>

      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <Padding top={3} left={3} right={3}>
                    <SegmentMenu>
                      <SegmentMenuItem
                        href={`/brands/${props.brand}/segment/${props.segmentId}`}
                        active={false}
                        label={'SEGMENT ACTIVATION'}
                      />
                      <SegmentMenuItem
                        href={`/brands/${props.brand}/segment/${props.segmentId}/compare`}
                        active={false}
                        label={'COMPARISONS'}
                      />
                      <SegmentMenuItem
                        href={`/brands/${props.brand}/segment/${props.segmentId}/description`}
                        active={true}
                        label={'DESCRIPTION'}
                      />
                    </SegmentMenu>
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <Padding top={3} left={3} right={3}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <div style={{ display: 'inline-block' }}>
                        <DataDateInput />
                      </div>
                    </div>
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 }}>
                  <Divider style={{ marginTop: 8, marginBottom: 4 }} />
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={{ sm: 1 / 1 }}>
                  <Padding all={3}>
                    <SegmentBACPanel segment={props.segment} />
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <Padding all={3}>
                    <SegmentMigrationCountPanel segment={props.segment} />
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <Padding all={3}>
                    <SegmentValuePanel segment={props.segment} />
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>

      <Grid>
        {props.segment !== null && (
          <Grid.Unit size={{ sm: 1 }}>
            <Padding all={2}>
              <Box>
                <Padding all={3}>
                  <Grid>
                    <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                      <Padding all={3}>
                        <HeadlineTwo>DEFINITION</HeadlineTwo>
                        <Divider />
                        <ParagraphLarge>
                          {props.segment.contents.description}
                        </ParagraphLarge>
                      </Padding>
                    </Grid.Unit>
                    <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                      <Grid>
                        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                          <Padding all={3}>
                            <HeadlineTwo>STRATEGIES</HeadlineTwo>
                            <Divider />

                            <SegmentText>
                              <b>Attributes:</b>
                              <br />
                              {props.segment.contents.attributes}
                            </SegmentText>
                            <SegmentText>
                              <b>Goals:</b>
                              <br />
                              {props.segment.contents.goals}
                            </SegmentText>
                            <SegmentText>
                              <b>Process:</b>
                              <br />
                              {props.segment.contents.process}
                            </SegmentText>
                            <SegmentText>
                              <b>Key Consumer Insight:</b>
                              <br />
                              {props.segment.contents.insights}
                            </SegmentText>
                          </Padding>
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                          <Padding all={3}>
                            <HeadlineTwo>TRIGGER</HeadlineTwo>
                            <Divider />

                            <SegmentText>
                              <b>Key Experience:</b>
                              <br />
                              {props.segment.contents.triggersKeyExperience}
                            </SegmentText>
                            <SegmentText>
                              <b>Discovery:</b>
                              <br />
                              {props.segment.contents.triggersDiscovery}
                            </SegmentText>
                            <SegmentText>
                              <b>Touchpoint:</b>
                              <OrderedList>
                                {/*props.segment.triggers.touchpoints.map(
                                (touchpoint, i) => {
                                  return (
                                    <OrderedListItem key={i}>
                                      {touchpoint}
                                    </OrderedListItem>
                                  );
                                }
                              )*/}
                                {stringToOrderedList(
                                  props.segment.contents.triggersTouchpoint,
                                  (touchpoint, i) => {
                                    return (
                                      <OrderedListItem key={i}>
                                        {touchpoint}
                                      </OrderedListItem>
                                    );
                                  }
                                )}
                              </OrderedList>
                            </SegmentText>
                          </Padding>
                        </Grid.Unit>
                      </Grid>
                      <Grid>
                        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                          <Padding all={3}>
                            <HeadlineTwo>TACTICAL COMMS</HeadlineTwo>
                            <Divider />
                            <OrderedList>
                              {/*props.segment.comms.map((touchpoint, i) => {
                              return (
                                <OrderedListItem key={i}>
                                  {touchpoint}
                                </OrderedListItem>
                              );
                            })*/}
                              {stringToOrderedList(
                                props.segment.contents.tacticalComms,
                                (touchpoint, i) => {
                                  return (
                                    <OrderedListItem key={i}>
                                      {touchpoint}
                                    </OrderedListItem>
                                  );
                                }
                              )}
                            </OrderedList>
                          </Padding>
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                          <Padding all={3}>
                            <HeadlineTwo>CALLS TO ACTION</HeadlineTwo>
                            <Divider />
                            <OrderedList>
                              {/*props.segment.cta.map((touchpoint, i) => {
                              return (
                                <OrderedListItem key={i}>
                                  {touchpoint}
                                </OrderedListItem>
                              );
                            })*/}
                              {stringToOrderedList(
                                props.segment.contents.callsToAction,
                                (touchpoint, i) => {
                                  return (
                                    <OrderedListItem key={i}>
                                      {touchpoint}
                                    </OrderedListItem>
                                  );
                                }
                              )}
                            </OrderedList>
                          </Padding>
                        </Grid.Unit>
                      </Grid>
                    </Grid.Unit>
                  </Grid>
                </Padding>
              </Box>
            </Padding>
          </Grid.Unit>
        )}
      </Grid>

      {props.segment !== null && (
        <Grid>
          <Grid.Unit size={{ sm: 1 }}>
            <Padding all={2}>
              <Box>
                <Padding all={2}>
                  <StoryUniverse
                    name={props.name}
                    tag={props.tag}
                    nodes={props.nodes}
                  />
                </Padding>
              </Box>
            </Padding>
          </Grid.Unit>
        </Grid>
      )}
    </Container>
  );
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD'
});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

export const SegmentDataArrow = styled.i`
  color: #545454;

  font-size: 12px;
`;

export const SegmentDataArrowContainer = styled.div`
  width: 20px;
  display: inline-block;
`;

export const SegmentDataRow: any = styled.div`
  background-color: ${(props: any) =>
    props.background ? '#f4fafd' : 'transparent'};
` as any;

export const SegmentDataRowLabel = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowLabelBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-weight: 500;
  font-size: 16px;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowValue = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
  border-right: ${(props: any) =>
    props.border ? '1px solid #d9ebf4' : '1px solid transparent'};

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataRowValueBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
  border-right: ${(props: any) =>
    props.border ? '1px solid #d9ebf4' : '1px solid transparent'};

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9ebf4;
`;

export const PeriodText = styled.span`
  font-size: 12px;
  color: #999999;
`;

export const METRICS = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
        metricDataCompare(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
      }
    }
  }
`;

export function makePeriods(term, start = '3-months') {
  const terms = {
    '1-year': 1,
    '2-year': 2,
  };

  const starts = {
    '1-month': [1, 'month'],
    '3-months': [3, 'months'],
    '6-months': [6, 'months'],
    '9-months': [9, 'months'],
    '1-year': [1, 'year'],
  };

  const a = starts[start];

  const periodOneStart = moment()
    .subtract(terms[term], 'years')
    .format('YYYY-MM-DD');

  const periodOneEnd = moment().format('YYYY-MM-DD');

  const periodTwoEnd = moment().subtract(a[0], a[1]).format('YYYY-MM-DD');

  const periodTwoStart = moment()
    .subtract(terms[term], 'years')
    .subtract(a[0], a[1])
    .format('YYYY-MM-DD');

  const periodThreeStart = moment()
    .subtract(terms[term] * 3, 'years')
    .format('YYYY-MM-DD');

  const periodThreeEnd = moment()
    .subtract(terms[term] * 2, 'years')
    .format('YYYY-MM-DD');

  /*const periodTwoStart = '2017-01-01';
  const periodTwoEnd = '2018-12-31';

  const periodOneStart = '2017-04-01';
  const periodOneEnd = '2019-03-01';

  const periodThreeStart = '';
  const periodThreeEnd = '';*/

  return {
    periodOneStart,
    periodOneEnd,
    periodTwoStart,
    periodTwoEnd,
    periodThreeStart,
    periodThreeEnd,
  };
}

export function SegmentCountData(props) {
  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } =
    props.dates;

  const { data } = props;

  const segment = data.brand.segmentData;

  return (
    <Padding all={2}>
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          <Padding all={3}>
            <Divider />
            <SegmentDataRow background={true}>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Segment Count
                  </SegmentDataRowLabelBold>
                </Grid.Unit>

                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold border={true}>
                    {formatterCounts.format(segment.metricData.users)}
                    <SegmentDataArrowContainer>
                      <SegmentDataArrow
                        className={`fa fa-arrow-${
                          segment.metricData.users >
                          segment.metricDataCompare.users
                            ? 'up'
                            : 'down'
                        }`}
                      />
                    </SegmentDataArrowContainer>
                  </SegmentDataRowValueBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold light={true}>
                    {formatterCounts.format(segment.metricDataCompare.users)}
                  </SegmentDataRowValueBold>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel light={true}>
                    Percentage of Total Count
                  </SegmentDataRowLabel>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue border={true} light={true}>
                    {formatterPercent.format(
                      segment.metricData.usersAsPercentageOfTotal
                    )}
                    <SegmentDataArrowContainer></SegmentDataArrowContainer>
                  </SegmentDataRowValue>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true}>
                    {formatterPercent.format(
                      segment.metricDataCompare.usersAsPercentageOfTotal
                    )}
                  </SegmentDataRowValue>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow background={true}>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel>Segment Revenue</SegmentDataRowLabel>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue border={true}>
                    {formatter.format(segment.metricData.revenue)}
                    <SegmentDataArrowContainer>
                      <SegmentDataArrow
                        className={`fa fa-arrow-${
                          segment.metricData.revenue >
                          segment.metricDataCompare.revenue
                            ? 'up'
                            : 'down'
                        }`}
                      />
                    </SegmentDataArrowContainer>
                  </SegmentDataRowValue>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true}>
                    {formatter.format(segment.metricDataCompare.revenue)}
                  </SegmentDataRowValue>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel light={true}>
                    Percentage of Total Revenue
                  </SegmentDataRowLabel>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true} border={true}>
                    {formatterPercent.format(
                      segment.metricData.revenueAsPercentageOfTotal
                    )}
                    <SegmentDataArrowContainer></SegmentDataArrowContainer>
                  </SegmentDataRowValue>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true}>
                    {formatterPercent.format(
                      segment.metricDataCompare.revenueAsPercentageOfTotal
                    )}
                  </SegmentDataRowValue>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataDivider />
            <SegmentDataRow background={true}>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Individual Revenue
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold border={true}>
                    {formatter.format(
                      segment.metricData.averageIndividualValue
                    )}
                    <SegmentDataArrowContainer>
                      <SegmentDataArrow
                        className={`fa fa-arrow-${
                          segment.metricData.averageIndividualValue >
                          segment.metricDataCompare.averageIndividualValue
                            ? 'up'
                            : 'down'
                        }`}
                      />
                    </SegmentDataArrowContainer>
                  </SegmentDataRowValueBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold light={true}>
                    {formatter.format(
                      segment.metricDataCompare.averageIndividualValue
                    )}
                  </SegmentDataRowValueBold>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel light={true}>
                    Margin per
                  </SegmentDataRowLabel>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true} border={true}>
                    {formatterPercent.format(segment.metricData.marginPerUser)}
                    <SegmentDataArrowContainer></SegmentDataArrowContainer>
                  </SegmentDataRowValue>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true}>
                    {formatterPercent.format(
                      segment.metricDataCompare.marginPerUser
                    )}
                  </SegmentDataRowValue>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow background={true}>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Cost of Goods per
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold border={true}>
                    {formatter.format(segment.metricData.cogsPerUser)}
                    <SegmentDataArrowContainer>
                      <SegmentDataArrow
                        className={`fa fa-arrow-${
                          segment.metricData.cogsPerUser >
                          segment.metricDataCompare.cogsPerUser
                            ? 'up'
                            : 'down'
                        }`}
                      />
                    </SegmentDataArrowContainer>
                  </SegmentDataRowValueBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold light={true}>
                    {formatter.format(segment.metricDataCompare.cogsPerUser)}
                  </SegmentDataRowValueBold>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel>
                    Cost of Aquisition per
                  </SegmentDataRowLabel>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true} border={true}>
                    {formatter.format(segment.metricData.costPerUser)}
                    <SegmentDataArrowContainer></SegmentDataArrowContainer>
                  </SegmentDataRowValue>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true}>
                    {formatter.format(segment.metricDataCompare.costPerUser)}
                  </SegmentDataRowValue>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataDivider />
            <SegmentDataRow background={true}>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Net Individual Value
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold border={true}>
                    {formatter.format(segment.metricData.netRevenuePerUser)}
                    <SegmentDataArrowContainer>
                      <SegmentDataArrow
                        className={`fa fa-arrow-${
                          segment.metricData.netRevenuePerUser >
                          segment.metricDataCompare.netRevenuePerUser
                            ? 'up'
                            : 'down'
                        }`}
                      />
                    </SegmentDataArrowContainer>
                  </SegmentDataRowValueBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold light={true}>
                    {formatter.format(
                      segment.metricDataCompare.netRevenuePerUser
                    )}
                  </SegmentDataRowValueBold>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
            <SegmentDataRow>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold style={{ fontWeight: 'bold' }}>
                    Net Segment Value
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValueBold
                    style={{ fontWeight: 'bold' }}
                    border={true}
                  >
                    {formatter.format(segment.metricData.netRevenue)}
                    <SegmentDataArrowContainer>
                      <SegmentDataArrow
                        className={`fa fa-arrow-${
                          segment.metricData.netRevenue >
                          segment.metricDataCompare.netRevenue
                            ? 'up'
                            : 'down'
                        }`}
                      />
                    </SegmentDataArrowContainer>
                  </SegmentDataRowValueBold>
                </Grid.Unit>
                <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 3 / 12 }}>
                  <SegmentDataRowValue light={true}>
                    {formatter.format(segment.metricDataCompare.netRevenue)}
                  </SegmentDataRowValue>
                </Grid.Unit>
              </Grid>
            </SegmentDataRow>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Padding>
  );
}

export const SegmentMigrationRowLabel = styled.span`
  color: #545454;
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: inline-block;
`;

export const SegmentMigrationRow = styled.div`
  padding: 8px 4px;
  margin-left: 4px;
  color: #545454;
  font-size: 12px;
  line-height: 13px;
  width: 70%;
  text-align: right;
  display: inline-block;
`;

export const SegmentMigrationRowTo = styled(SegmentMigrationRow as any)`
  text-align: left;
  position: relative;
`;

export const SegmentMigrationBadge = styled.span`
  background-color: black;
  border-radius: 9999px;
  font-size: 10px;
  color: #fff;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-left: 8px;
  margin-right: 8px;
`;

export const SegmentMigrationLostSouls = styled(SegmentMigrationBadge as any)`
  width: 24px;
  height: 24px;
`;

export const SegmentMigrationProspects = styled(SegmentMigrationBadge as any)`
  border: 1px solid #509bb0;
  color: #509bb0;
  background: white;
  width: 23px;
  height: 23px;
`;

export const SegmentMigrationCasuals = styled(SegmentMigrationBadge as any)`
  border: 2px solid #509bb0;
  color: #509bb0;
  background: white;
`;

export const SegmentMigrationLoyalists = styled(SegmentMigrationBadge as any)`
  border: 3px solid #509bb0;
  color: #509bb0;
  background: white;
  width: 18px;
  height: 18px;
`;

export const SegmentMigrationCheerleaders = styled(
  SegmentMigrationBadge as any
)`
  background: #509bb0;
  color: #fff;
  width: 24px;
  height: 24px;
`;

export const SegmentCircle = styled.div`
  width: 80px;
  height: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #509bb0;
  color: #509bb0;
  font-size: 24px;
  border-radius: 50%;
`;

export const SegmentCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SegmentMigrationImage = styled.img`
  position: absolute;
  left: -20px;
`;

const MovementFromArrow = styled.img`
  position: absolute;
  right: 16px;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
`;

export function SegmentMigrationKey() {
  return (
    <Grid>
      <Grid.Unit size={{ sm: 1 }}>
        <div style={{ marginTop: 16 }}>
          <div style={{ textAlign: 'center' }}>
            <HeadlineMigrationPercent>
              <img
                style={{ width: 20, height: 20 }}
                src="/static/movement.png"
              />
              Movement
            </HeadlineMigrationPercent>
            <HeadlineMigrationPercent>
              <img
                style={{ width: 20, height: 20 }}
                src="/static/migrate.png"
              />
              Migration
            </HeadlineMigrationPercent>
            <HeadlineMigrationPercent>
              <img style={{ width: 20, height: 20 }} src="/static/stay.png" />
              Stayed
            </HeadlineMigrationPercent>
            <HeadlineMigrationPercent>
              <img style={{ width: 20, height: 20 }} src="/static/lost.png" />
              Lost Souls
            </HeadlineMigrationPercent>
          </div>
        </div>
      </Grid.Unit>
    </Grid>
  );
}

export function SegmentMigration(props) {
  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } =
    props.dates;

  const { data } = props;

  const segment = data.brand.segmentMigrationData;
  const type = types[props.segment].type;

  const migratedIn = [
    'casuals',
    'prospects',
    'lostsouls',
    'cheerleaders',
    'loyalists',
  ].reduce((total, key) => {
    return total + data.brand.segmentMigrationData.from[key];
  }, 0);

  const migratedOut = [
    'casuals',
    'prospects',
    'lostsouls',
    'cheerleaders',
    'loyalists',
  ].reduce((total, key) => {
    return total + data.brand.segmentMigrationData.to[key];
  }, 0);

  const stayed = [
    'casuals',
    'prospects',
    'lostsouls',
    'cheerleaders',
    'loyalists',
  ].reduce((total, key) => {
    return total + data.brand.segmentMigrationData.stayed[key];
  }, 0);

  const total = data.brand.segmentData.metricDataCompare.users;

  if (type === 'SEGMENT_PROSPECTS') {
    return (
      <Padding all={2}>
        <Grid>
          <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
            <Padding all={3}>
              <HeadlineTwo>Migration</HeadlineTwo>
              <Divider />

              <Grid>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <SegmentMigrationRowLabel>
                    MOVEMENT FROM
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 2 / 12 }}></Grid.Unit>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <SegmentMigrationRowLabel>
                    MOVEMENT TO
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                ></Grid.Unit>
                <Grid.Unit size={{ sm: 2 / 12 }}>
                  <SegmentCircleContainer>
                    <SegmentCircle>{types[props.segment].abv}</SegmentCircle>
                  </SegmentCircleContainer>
                </Grid.Unit>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <div style={{ padding: '0 16px' }}>
                    {segment.to.lostsouls > 0 && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_CASUAL'
                              ? `/static/movement.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationLostSouls>X</SegmentMigrationLostSouls>
                        {formatterPercent.format(
                          segment.to.lostsouls / migratedIn
                        )}
                        {' / '}
                        {segment.to.lostsouls}
                      </SegmentMigrationRowTo>
                    )}

                    {(segment.to.casuals > 0 || segment.stayed.casuals > 0) && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_LOSTSOULS'
                              ? `/static/migrate.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationCasuals>CA</SegmentMigrationCasuals>
                        {formatterPercent.format(
                          (type === 'SEGMENT_CASUAL'
                            ? segment.stayed.casuals
                            : segment.to.casuals) / migratedOut
                        )}
                        {' / '}
                        {type === 'SEGMENT_CASUAL'
                          ? segment.stayed.casuals
                          : segment.to.casuals}
                      </SegmentMigrationRowTo>
                    )}

                    {(segment.to.loyalists > 0 ||
                      segment.stayed.loyalists > 0) && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_LOYALIST'
                              ? `/static/stay.png`
                              : type === 'SEGMENT_CASUAL'
                              ? `/static/migrate.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationLoyalists>L</SegmentMigrationLoyalists>
                        {formatterPercent.format(
                          (type === 'SEGMENT_LOYALIST'
                            ? segment.stayed.loyalists
                            : segment.to.loyalists) / migratedOut
                        )}
                        {' / '}
                        {type === 'SEGMENT_LOYALIST'
                          ? segment.stayed.loyalists
                          : segment.to.loyalists}
                      </SegmentMigrationRowTo>
                    )}

                    {(segment.to.cheerleaders > 0 ||
                      segment.stayed.cheerleaders > 0) && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_CHEERLEADER'
                              ? `/static/stay.png`
                              : type === 'SEGMENT_LOYALIST'
                              ? `/static/migrate.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationCheerleaders>
                          CH
                        </SegmentMigrationCheerleaders>
                        {formatterPercent.format(
                          (type === 'SEGMENT_CHEERLEADER'
                            ? segment.stayed.cheerleaders
                            : segment.to.cheerleaders) / migratedOut
                        )}
                        {' / '}
                        {type === 'SEGMENT_CHEERLEADER'
                          ? segment.stayed.cheerleaders
                          : segment.to.cheerleaders}
                      </SegmentMigrationRowTo>
                    )}
                  </div>
                </Grid.Unit>
              </Grid>
              <Divider />
              <Grid>
                <Grid.Unit size={{ sm: 4 / 12 }}>
                  <SegmentMigrationRowLabel></SegmentMigrationRowLabel>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 4 / 12 }}>
                  <SegmentMigrationRowLabel>
                    {/*{data.brand.segmentData.metricData.users} total*/}
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 4 / 12 }}>
                  <SegmentMigrationRowLabel>
                    {migratedOut}
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
              </Grid>
            </Padding>
          </Grid.Unit>
        </Grid>
      </Padding>
    );
  }

  if (type === 'SEGMENT_LOSTSOULS') {
    return (
      <Padding all={2}>
        <Grid>
          <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
            <Padding all={3}>
              <HeadlineTwo>Migration</HeadlineTwo>
              <Divider />

              <Grid>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <SegmentMigrationRowLabel>
                    MOVEMENT FROM
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 2 / 12 }}></Grid.Unit>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <SegmentMigrationRowLabel>
                    MOVEMENT TO
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <div style={{ padding: '0 16px', position: 'relative' }}>
                    {segment.from.lostsouls > 0 && (
                      <SegmentMigrationRow>
                        {segment.from.lostsouls}{' '}
                        <SegmentMigrationLostSouls>X</SegmentMigrationLostSouls>
                      </SegmentMigrationRow>
                    )}
                    {type !== 'SEGMENT_PROSPECT' && segment.from.prospects > 0 && (
                      <SegmentMigrationRow>
                        {segment.from.prospects}{' '}
                        <SegmentMigrationProspects>P</SegmentMigrationProspects>
                      </SegmentMigrationRow>
                    )}
                    {type !== 'SEGMENT_CASUAL' && segment.from.casuals > 0 && (
                      <SegmentMigrationRow>
                        {segment.from.casuals}{' '}
                        <SegmentMigrationCasuals>CA</SegmentMigrationCasuals>
                      </SegmentMigrationRow>
                    )}
                    {type !== 'SEGMENT_LOYALIST' && segment.from.loyalists > 0 && (
                      <SegmentMigrationRow>
                        {segment.from.loyalists}{' '}
                        <SegmentMigrationLoyalists>L</SegmentMigrationLoyalists>
                      </SegmentMigrationRow>
                    )}
                    {type !== 'SEGMENT_CHEERLEADER' &&
                      segment.from.cheerleaders && (
                        <SegmentMigrationRow>
                          {segment.from.cheerleaders}{' '}
                          <SegmentMigrationCheerleaders>
                            CH
                          </SegmentMigrationCheerleaders>
                        </SegmentMigrationRow>
                      )}
                    <MovementFromArrow src="/static/movement.png" />
                  </div>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 2 / 12 }}>
                  <SegmentCircleContainer>
                    <SegmentCircle
                      style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        borderColor: '#fff',
                      }}
                    >
                      {types[props.segment].abv}
                    </SegmentCircle>
                  </SegmentCircleContainer>
                </Grid.Unit>
                <Grid.Unit
                  style={{ textAlign: 'center' }}
                  size={{ sm: 5 / 12 }}
                >
                  <div style={{ padding: '0 16px' }}>
                    {segment.to.lostsouls > 0 && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_CASUAL'
                              ? `/static/movement.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationLostSouls>X</SegmentMigrationLostSouls>
                        {formatterPercent.format(
                          segment.to.lostsouls / migratedIn
                        )}
                        {' / '}
                        {segment.to.lostsouls}
                      </SegmentMigrationRowTo>
                    )}

                    {(segment.to.casuals > 0 || segment.stayed.casuals > 0) && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_LOSTSOULS'
                              ? `/static/migrate.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationCasuals>CA</SegmentMigrationCasuals>
                        {formatterPercent.format(
                          (type === 'SEGMENT_CASUAL'
                            ? segment.stayed.casuals
                            : segment.to.casuals) / migratedIn
                        )}
                        {' / '}
                        {type === 'SEGMENT_CASUAL'
                          ? segment.stayed.casuals
                          : segment.to.casuals}
                      </SegmentMigrationRowTo>
                    )}

                    {(segment.to.loyalists > 0 ||
                      segment.stayed.loyalists > 0) && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_LOYALIST'
                              ? `/static/stay.png`
                              : type === 'SEGMENT_CASUAL'
                              ? `/static/migrate.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationLoyalists>L</SegmentMigrationLoyalists>
                        {formatterPercent.format(
                          (type === 'SEGMENT_LOYALIST'
                            ? segment.stayed.loyalists
                            : segment.to.loyalists) / migratedIn
                        )}
                        {' / '}
                        {type === 'SEGMENT_LOYALIST'
                          ? segment.stayed.loyalists
                          : segment.to.loyalists}
                      </SegmentMigrationRowTo>
                    )}

                    {(segment.to.cheerleaders > 0 ||
                      segment.stayed.cheerleaders > 0) && (
                      <SegmentMigrationRowTo>
                        <SegmentMigrationImage
                          style={{ width: 20, height: 20 }}
                          src={
                            type === 'SEGMENT_CHEERLEADER'
                              ? `/static/stay.png`
                              : type === 'SEGMENT_LOYALIST'
                              ? `/static/migrate.png`
                              : `/static/movement.png`
                          }
                        />
                        <SegmentMigrationCheerleaders>
                          CH
                        </SegmentMigrationCheerleaders>
                        {formatterPercent.format(
                          (type === 'SEGMENT_CHEERLEADER'
                            ? segment.stayed.cheerleaders
                            : segment.to.cheerleaders) / migratedIn
                        )}
                        {' / '}
                        {type === 'SEGMENT_CHEERLEADER'
                          ? segment.stayed.cheerleaders
                          : segment.to.cheerleaders}
                      </SegmentMigrationRowTo>
                    )}
                  </div>
                </Grid.Unit>
              </Grid>
              <Divider />
              <Grid>
                <Grid.Unit size={{ sm: 4 / 12 }}>
                  <SegmentMigrationRowLabel>
                    {migratedIn}
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 4 / 12 }}>
                  <SegmentMigrationRowLabel>
                    {/*{data.brand.segmentData.metricData.users} total*/}
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 4 / 12 }}>
                  <SegmentMigrationRowLabel>
                    {formatterPercent.format(migratedOut / migratedIn)} {' / '}
                    {migratedOut}
                  </SegmentMigrationRowLabel>
                </Grid.Unit>
              </Grid>
            </Padding>
          </Grid.Unit>
        </Grid>
      </Padding>
    );
  }

  return (
    <Padding all={2}>
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          <Padding all={3}>
            <HeadlineTwo>Migration</HeadlineTwo>
            <Divider />
            <Grid>
              <Grid.Unit size={{ sm: 12 / 12 }}>
                <div style={{ margin: '8px 0' }}>
                  <HeadlineOne
                    style={{
                      color: '#509bb0',
                      fontSize: 52,
                      margin: 0,
                    }}
                  >
                    {formatterCounts.format(migratedIn)}
                  </HeadlineOne>
                  <HeadlineTwo
                    style={{
                      margin: 0,
                      fontSize: 16,
                      lineHeight: '19px',
                      fontWeight: 500,
                      color: '#545454',
                      opacity: 0.7,
                    }}
                  >
                    TO {types[props.segment].name}
                  </HeadlineTwo>
                </div>
              </Grid.Unit>
            </Grid>
            <Divider />

            <Grid>
              <Grid.Unit style={{ textAlign: 'center' }} size={{ sm: 5 / 12 }}>
                <SegmentMigrationRowLabel>
                  MOVEMENT FROM
                </SegmentMigrationRowLabel>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 2 / 12 }}></Grid.Unit>
              <Grid.Unit style={{ textAlign: 'center' }} size={{ sm: 5 / 12 }}>
                <SegmentMigrationRowLabel>MOVEMENT TO</SegmentMigrationRowLabel>
              </Grid.Unit>
            </Grid>
            <Grid>
              <Grid.Unit style={{ textAlign: 'center' }} size={{ sm: 5 / 12 }}>
                <div style={{ padding: '0 16px', position: 'relative' }}>
                  {segment.from.lostsouls > 0 && (
                    <SegmentMigrationRow>
                      {segment.from.lostsouls}{' '}
                      <SegmentMigrationLostSouls>X</SegmentMigrationLostSouls>
                    </SegmentMigrationRow>
                  )}
                  {type !== 'SEGMENT_PROSPECT' && segment.from.prospects > 0 && (
                    <SegmentMigrationRow>
                      {segment.from.prospects}{' '}
                      <SegmentMigrationProspects>P</SegmentMigrationProspects>
                    </SegmentMigrationRow>
                  )}
                  {type !== 'SEGMENT_CASUAL' && segment.from.casuals > 0 && (
                    <SegmentMigrationRow>
                      {segment.from.casuals}{' '}
                      <SegmentMigrationCasuals>CA</SegmentMigrationCasuals>
                    </SegmentMigrationRow>
                  )}
                  {type !== 'SEGMENT_LOYALIST' && segment.from.loyalists > 0 && (
                    <SegmentMigrationRow>
                      {segment.from.loyalists}{' '}
                      <SegmentMigrationLoyalists>L</SegmentMigrationLoyalists>
                    </SegmentMigrationRow>
                  )}
                  {type !== 'SEGMENT_CHEERLEADER' && segment.from.cheerleaders && (
                    <SegmentMigrationRow>
                      {segment.from.cheerleaders}{' '}
                      <SegmentMigrationCheerleaders>
                        CH
                      </SegmentMigrationCheerleaders>
                    </SegmentMigrationRow>
                  )}
                  <MovementFromArrow src="/static/movement.png" />
                </div>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 2 / 12 }}>
                <SegmentCircleContainer>
                  <SegmentCircle>{types[props.segment].abv}</SegmentCircle>
                </SegmentCircleContainer>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'center' }} size={{ sm: 5 / 12 }}>
                <div style={{ padding: '0 16px' }}>
                  {segment.to.lostsouls > 0 && (
                    <SegmentMigrationRowTo>
                      <SegmentMigrationImage
                        style={{ width: 20, height: 20 }}
                        src={
                          type === 'SEGMENT_CASUAL'
                            ? `/static/movement.png`
                            : `/static/movement.png`
                        }
                      />
                      <SegmentMigrationLostSouls>X</SegmentMigrationLostSouls>
                      {formatterPercent.format(segment.to.lostsouls / total)}
                      {' / '}
                      {segment.to.lostsouls}
                    </SegmentMigrationRowTo>
                  )}

                  {(segment.to.casuals > 0 || segment.stayed.casuals > 0) && (
                    <SegmentMigrationRowTo>
                      <SegmentMigrationImage
                        style={{ width: 20, height: 20 }}
                        src={
                          type === 'SEGMENT_CASUAL'
                            ? `/static/stay.png`
                            : `/static/movement.png`
                        }
                      />
                      <SegmentMigrationCasuals>CA</SegmentMigrationCasuals>
                      {formatterPercent.format(
                        (type === 'SEGMENT_CASUAL'
                          ? segment.stayed.casuals
                          : segment.to.casuals) / total
                      )}
                      {' / '}
                      {type === 'SEGMENT_CASUAL'
                        ? segment.stayed.casuals
                        : segment.to.casuals}
                    </SegmentMigrationRowTo>
                  )}

                  {(segment.to.loyalists > 0 ||
                    segment.stayed.loyalists > 0) && (
                    <SegmentMigrationRowTo>
                      <SegmentMigrationImage
                        style={{ width: 20, height: 20 }}
                        src={
                          type === 'SEGMENT_LOYALIST'
                            ? `/static/stay.png`
                            : type === 'SEGMENT_CASUAL'
                            ? `/static/migrate.png`
                            : `/static/movement.png`
                        }
                      />
                      <SegmentMigrationLoyalists>L</SegmentMigrationLoyalists>
                      {formatterPercent.format(
                        (type === 'SEGMENT_LOYALIST'
                          ? segment.stayed.loyalists
                          : segment.to.loyalists) / total
                      )}
                      {' / '}
                      {type === 'SEGMENT_LOYALIST'
                        ? segment.stayed.loyalists
                        : segment.to.loyalists}
                    </SegmentMigrationRowTo>
                  )}

                  {(segment.to.cheerleaders > 0 ||
                    segment.stayed.cheerleaders > 0) && (
                    <SegmentMigrationRowTo>
                      <SegmentMigrationImage
                        style={{ width: 20, height: 20 }}
                        src={
                          type === 'SEGMENT_CHEERLEADER'
                            ? `/static/stay.png`
                            : type === 'SEGMENT_LOYALIST'
                            ? `/static/migrate.png`
                            : `/static/movement.png`
                        }
                      />
                      <SegmentMigrationCheerleaders>
                        CH
                      </SegmentMigrationCheerleaders>
                      {formatterPercent.format(
                        (type === 'SEGMENT_CHEERLEADER'
                          ? segment.stayed.cheerleaders
                          : segment.to.cheerleaders) / total
                      )}
                      {' / '}
                      {type === 'SEGMENT_CHEERLEADER'
                        ? segment.stayed.cheerleaders
                        : segment.to.cheerleaders}
                    </SegmentMigrationRowTo>
                  )}
                </div>
              </Grid.Unit>
            </Grid>
            <Divider />
            <Grid>
              <Grid.Unit size={{ sm: 4 / 12 }}>
                <SegmentMigrationRowLabel>
                  {migratedIn}
                </SegmentMigrationRowLabel>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 4 / 12 }}>
                <SegmentMigrationRowLabel>
                  {data.brand.segmentData.metricData.users} total
                </SegmentMigrationRowLabel>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 4 / 12 }}>
                <SegmentMigrationRowLabel>
                  {formatterPercent.format(
                    migratedOut / data.brand.segmentData.metricDataCompare.users
                  )}{' '}
                  {' / '}
                  {migratedOut}
                </SegmentMigrationRowLabel>
              </Grid.Unit>
            </Grid>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Padding>
  );
}

export function flattenStoryNodes(node, tags, d = 0, n = [], root = null) {
  const nodeTags = node.tags
    ? node.tags.map((tag) => (tag ? tag.split(' ').join('') : tag))
    : [];

  if (d === 2) {
    root = node;
  }

  node.root = root;

  const diff = tags.filter((value) => nodeTags.includes(value));
  if (d > 1 && diff.length > 0) {
    n.push(node);
  }

  if (node.children) {
    node.children.forEach((child) =>
      flattenStoryNodes(child, tags, d + 1, n, root)
    );
  }

  n.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

  return n;
}

function groupStoryNodes(nodes) {
  const groups = {};
  for (let node of nodes) {
    if (node.root) {
      if (!groups[node.root.name]) {
        groups[node.root.name] = [];
      }

      groups[node.root.name].push(node);
    }
  }

  return Object.keys(groups).map((key) => groups[key]);
}

export function StoryUniverse(props) {
  const [node, setNode] = React.useState(null);
  const [active, setActive] = React.useState(null);
  const canvas = React.useRef(null);

  React.useLayoutEffect(() => {
    drawStoryUniverse(canvas.current, props.nodes || {}, setNode, [props.tag]);
    // drawStoryUniverse(wanderlust.current, wanderlustNodes, setNode);
    // drawStoryUniverse(sitka.current, sitkaNodes, setNode);
    // drawStoryUniverse(wanderlust.current.getContext('2d'), wanderlustNodes);
    // drawStoryUniverse(sitka.current.getContext('2d'), sitkaNodes);
  }, []);

  function clearTheNode() {
    if (node) node.active = false;
    if (node) handleMouseOut(node);
    setNode(null);
  }

  function selectTheNode(node, i) {
    setNode((n) => {
      if (n) n.active = false;
      handleMouseOut(n);
      node.active = true;
      handleMouseOver(node);
      return node;
    });
  }

  const nodes = flattenStoryNodes(props.nodes, [props.tag]);
  const groupedNodes = groupStoryNodes(nodes);

  return (
    <Grid>
      <Grid.Unit size={{ sm: 1, md: 1 }}>
        <Padding all={2}>
          <HeadlineTwo>
            {props.name ? `${props.name} ` : ''}STORY UNIVERSE
          </HeadlineTwo>
          <Divider />
        </Padding>
      </Grid.Unit>
      <Grid.Unit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={2} style={{ height: '780px', overflow: 'scroll' }}>
          <LinkList>
            {groupedNodes.map((nodes, i) => {
              return (
                <LinkListHeader key={i}>
                  <span>{nodes[0].root.name}</span>
                  {nodes.map((node, i) => {
                    return (
                      <LinkListItem
                        active={node.active}
                        onClick={(e) => selectTheNode(node, i)}
                      >
                        {node.name}
                      </LinkListItem>
                    );
                  })}
                </LinkListHeader>
              );
            })}
          </LinkList>
        </Padding>
      </Grid.Unit>

      <Grid.Unit size={{ sm: 1, md: 9 / 12 }}>
        <StoryUniverseContainer>
          <Overlay className={node ? 'show' : 'hide'}>
            <div className="bg" onClick={(e) => clearTheNode()} />
            <div className="info">
              {node && <NodeInfo setNode={setNode} node={node} />}
            </div>
          </Overlay>
          <div>
            <div
              style={{
                transform: 'scale(0.7)',
                marginTop: 'calc(1180px * -0.15)',
                marginLeft: 'calc(980px * -0.15)',
              }}
            >
              <svg ref={canvas}>
                <g />
              </svg>
            </div>
          </div>
        </StoryUniverseContainer>
      </Grid.Unit>
    </Grid>
  );
}

export const BRAND = gql`
  query ($id: ID, $type: String) {
    brand(id: $id) {
      id
      name
      firstDataRecordDate
      lastDataRecordDate
      segment(type: $type) {
        id
        type
        contents
      }
      dataConfig {
        reportingTerm
      }
      storyUniverse {
        id
        type
        contents
      }
    }
  }
`;

export function SegmentLoader(props: any) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: {
      id: props.match.params.brand,
      type: types[props.match.params.segment].type,
    },
  });

  if (error) return null;
  if (!data || loading) return null;

  if (!data.brand.dataConfig || !data.brand.dataConfig.reportingTerm) {
    return (
      <Segment
        segmentId={props.match.params.segment}
        brand={props.match.params.brand}
        name={types[props.match.params.segment].name}
        tag={types[props.match.params.segment].tag}
        segment={data.brand.segment}
        nodes={data.brand.storyUniverse.contents}
      />
    );
  }

  /*if (
    props.match.params.segment === 'prospects' ||
    props.match.params.segment === 'prospect'
  ) {
    return (
      <Segment
        brand={props.match.params.brand}
        name={types[props.match.params.segment].name}
        tag={types[props.match.params.segment].tag}
        segment={data.brand.segment}
        nodes={data.brand.storyUniverse.contents}
      />
    );
  }*/

  return (
    <Segment
      segmentId={props.match.params.segment}
      brand={props.match.params.brand}
      name={types[props.match.params.segment].name}
      tag={types[props.match.params.segment].tag}
      segment={data.brand.segment}
      nodes={data.brand.storyUniverse.contents}
    />
  );
}

export default SegmentLoader;
