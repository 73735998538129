import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  HeadlineFour,
  Paragraph,
  QuoteBlue,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { LinkList, LinkListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer,
} from '../../components/buttons';
import { getConsumerFindings } from '../../data';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';
import { StyledInput as StyledTextArea } from '../../components/admin/inputs/textarea';
import { StyledInput as StyledText } from '../../components/admin/inputs/text';
import AdminScreen from '../../components/admin/containers/screen';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo-hooks';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from './affinity.ladder';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import CSVInput from '../../components/inputs/csv';
import * as store from 'store';

interface ConsumerFindingsScreenProps {
  brand: any;
  account: any;
  findings: any;
}

export const ThemesGridUnit = styled(Grid.Unit)`
  background-color: #f7fbfd;
`;

export const ThemeHeadline = styled(HeadlineTwo)`
  color: #536976;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0;
`;

export const TextAreaInput = styled(StyledTextArea)`
  border: 1px solid #d7d7d836;
  background: #f6f3f038;
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  height: 140px;
  margin-bottom: 16px;
  // border: none;
  // background: transparent;
`;

export const TextAreaInputTheme = styled(StyledTextArea)`
  border: 1px solid #d7d7d8;
  background: transparent;
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  height: 140px;
`;

export const TextInput = styled(StyledText)`
  border: 1px solid #d7d7d8;
  background: transparent;
  margin-bottom: 16px;
`;

export const UPDATE_DOC = gql`
  mutation($input: DocumentInput) {
    updateDoc(input: $input) {
      id
      type
      contents
    }
  }
`;

export function ConsumerFindingsScreen(props: ConsumerFindingsScreenProps) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(UPDATE_DOC);
  const breadcrumbs =
    store.get('role') !== 'Admin'
      ? [
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
          { label: 'Consumer Findings' },
        ]
      : [
          { label: 'Admin', link: `/admin` },
          {
            label: props.account.name,
            link: `/admin/account/${props.account.id}`,
          },
          { label: 'Brands' },
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
          { label: 'Consumer Findings' },
        ];
  const buttons = [
    {
      label: 'SAVE CONSUMER FINDINGS',
      onClick: save,
    },
  ];

  const [themes, setThemes] = React.useState(
    props.findings.contents.themes || [{ name: 'New Theme', quotes: [] }]
  );

  const [findings, setFindings] = React.useState(props.findings.contents || {});

  function save() {
    try {
      mutation({
        variables: {
          input: {
            id: props.brand.consumerFindings.id,
            type: props.brand.consumerFindings.type,
            contents: JSON.stringify({ ...findings, themes }),
          },
        },
      });
      notify(setGlobal, 'Saved Consumer Findings');
    } catch (e) {}
  }

  function changeField(key, value) {
    return setFindings((findings) => ({ ...findings, [key]: value }));
  }

  const parse = useMutation(PARSE_THEMES);

  async function onUpload(file) {
    const response: any = await parse({ variables: { file } });

    setThemes(response.data.parseThemes);
  }

  return (
    <AdminScreen
      headline={'CONSUMER FINDINGS'}
      breadcrumbs={breadcrumbs}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>PURPOSE</HeadlineTwo>
                      <Divider />
                      <TextAreaInput
                        value={findings.purpose}
                        onChange={(e) => changeField('purpose', e.target.value)}
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 8 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>METHODOLOGY</HeadlineTwo>
                      <Divider />
                      <Grid>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <HeadlineFour>DEMOGRAPHICS</HeadlineFour>
                          <TextAreaInput
                            value={findings.demographics}
                            onChange={(e) =>
                              changeField('demographics', e.target.value)
                            }
                          />
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <Padding left={{ md: 3 }}>
                            <HeadlineFour>AGE</HeadlineFour>
                            <TextAreaInput
                              value={findings.age}
                              onChange={(e) =>
                                changeField('age', e.target.value)
                              }
                            />
                            <HeadlineFour>OCCUPATION</HeadlineFour>
                            <TextAreaInput
                              value={findings.occupation}
                              onChange={(e) =>
                                changeField('occupation', e.target.value)
                              }
                            />
                            <HeadlineFour>GEOGRAPHY</HeadlineFour>
                            <TextAreaInput
                              value={findings.geography}
                              onChange={(e) =>
                                changeField('geography', e.target.value)
                              }
                            />
                          </Padding>
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <Padding left={{ md: 3 }}>
                            <HeadlineFour>ACTIVITIES</HeadlineFour>
                            <TextAreaInput
                              value={findings.activities}
                              onChange={(e) =>
                                changeField('activities', e.target.value)
                              }
                            />
                            <HeadlineFour>PRODUCTS</HeadlineFour>
                            <TextAreaInput
                              value={findings.products}
                              onChange={(e) =>
                                changeField('products', e.target.value)
                              }
                            />
                            <HeadlineFour>BELOVED BRANDS</HeadlineFour>
                            <TextAreaInput
                              value={findings.brands}
                              onChange={(e) =>
                                changeField('brands', e.target.value)
                              }
                            />
                          </Padding>
                        </Grid.Unit>
                      </Grid>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Themes onUpload={onUpload} themes={themes} setThemes={setThemes} />
    </AdminScreen>
  );
}

export const ArrowContainer = styled.span`
  cursor: pointer;
  svg {
    fill: #536976;
  }
`;

export const ThemesHeadline = styled(HeadlineTwo)`
  span {
    margin-top: 2px;
  }

  ${breakpoint('md')`
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
  `}
`;

export const ParagraphQuote = styled(Paragraph)`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const ParagraphDescription = styled(Paragraph)`
  font-size: 16px;
  margin-bottom: 24px;
  color: #222222;
`;

export const Remove = styled.div`
  background: #fff;
  display: flex;
  position: absolute;
  width: 32px;
  height: 32px;
  top: calc(50% - 16px);
  left: -16px;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  z-index: 9999;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    .a {
      stroke: #657c89;
    }
  }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: `8px`,
  margin: `0 0 8px 0`,
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const PARSE_THEMES = gql`
  mutation($file: String) {
    parseThemes(file: $file)
  }
`;

export function Themes(props: any) {
  const [themeIndex, setThemeIndex] = React.useState(0);
  const themes = props.themes;
  const theme = themes[themeIndex] || { quotes: [] };

  const nextTheme = () => {
    if (themeIndex + 1 === themes.length) {
      return setThemeIndex(0);
    }

    setThemeIndex(themeIndex + 1);
  };
  const prevTheme = () => {
    if (themeIndex === 0) {
      return setThemeIndex(themes.length - 1);
    }
    return setThemeIndex(themeIndex - 1);
  };

  const half = Math.ceil(themes.length / 2);

  const left = themes.slice(0, half);
  const right = themes.slice(half, themes.length);

  function addTheme() {
    props.setThemes((themes) => {
      const len = themes.length;

      return [...themes, { name: 'New Theme', description: '', quotes: [] }];
    });
    setTimeout(() => setThemeIndex(themes.length), 200);
  }

  function setThemeField(index, key, value) {
    props.setThemes((themes) => {
      themes[index] = { ...themes[index], [key]: value };

      return [...themes];
    });
  }

  function addQuote() {
    props.setThemes((themes) => {
      const quotes = themes[themeIndex].quotes || [];
      themes[themeIndex] = {
        ...themes[themeIndex],
        quotes: [...quotes, { text: '', tags: [] }],
      };

      return [...themes];
    });
  }

  function updateQuote(i, value) {
    props.setThemes((themes) => {
      themes[themeIndex].quotes[i].text = value;
      return [...themes];
    });
  }

  function removeQuote(i) {
    props.setThemes((themes) => {
      themes[themeIndex].quotes.splice(i, 1);
      return [...themes];
    });
  }

  function removeTheme() {
    props.setThemes((themes) => {
      themes.splice(themeIndex, 1);
      return [...themes];
    });
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    props.setThemes((themes) => {
      themes[themeIndex].quotes = reorder(
        themes[themeIndex].quotes,
        result.source.index,
        result.destination.index
      );
      return [...themes];
    });
  }

  return (
    <Grid>
      <Grid.Unit size={{ sm: 1 }}>
        <Padding all={2}>
          <Box>
            <Padding all={3}>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={2} right={2}>
                    <ThemesHeadline>
                      THEMES{' '}
                      <span>
                        {props.headline
                          ? props.headline
                          : `Themes include a statement summarizing the key takeaway
                        and representative commentary from customers.`}
                      </span>
                    </ThemesHeadline>
                    <Divider />
                  </Padding>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                  <Padding all={2}>
                    <ButtonHollow onClick={addTheme}>Add Theme</ButtonHollow>
                    <CSVInput
                      buttonLabel={'Upload Themes CSV'}
                      onChange={props.onUpload}
                    />
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1, md: 2 / 12 }}>
                  <Padding all={2} left={2}>
                    <LinkList>
                      <DraggableList
                        id="themes-left"
                        items={left}
                        renderItem={(_theme, i) => (
                          <LinkListItem
                            key={`left-${i}`}
                            onClick={(e) => setThemeIndex(i)}
                            active={i === themeIndex}
                          >
                            {_theme.name}
                          </LinkListItem>
                        )}
                      />
                    </LinkList>
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1, md: 3 / 12 }}>
                  <Padding all={2} left={2}>
                    <LinkList>
                      <DraggableList
                        id="themes-right"
                        items={right}
                        renderItem={(_theme, i) => (
                          <LinkListItem
                            key={`left-${i}`}
                            onClick={(e) => setThemeIndex(i)}
                            active={i === themeIndex}
                          >
                            {_theme.name}
                          </LinkListItem>
                        )}
                      />
                    </LinkList>
                  </Padding>
                </Grid.Unit>
                <ThemesGridUnit size={{ sm: 1, md: 7 / 12 }}>
                  <Padding style={{}} all={3} left={3} right={3}>
                    <Remove
                      onClick={(e) => removeTheme()}
                      style={{ top: '-16px' }}
                    >
                      <SVGInline
                        svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>bin</title><path class="a" d="M17.25,21H6.75a1.5,1.5,0,0,1-1.5-1.5V6h13.5V19.5A1.5,1.5,0,0,1,17.25,21Z"></path><line class="a" x1="9.75" y1="16.5" x2="9.75" y2="10.5"></line><line class="a" x1="14.25" y1="16.5" x2="14.25" y2="10.5"></line><line class="a" x1="2.25" y1="6" x2="21.75" y2="6"></line><path class="a" d="M14.25,3H9.75a1.5,1.5,0,0,0-1.5,1.5V6h7.5V4.5A1.5,1.5,0,0,0,14.25,3Z"></path></svg>`}
                      />
                    </Remove>
                    <Grid>
                      <Grid.Unit size={{ xs: 6 / 12, sm: 6 / 12 }}>
                        <TextInput
                          value={theme.name}
                          onChange={(e) =>
                            setThemeField(themeIndex, 'name', e.target.value)
                          }
                        />
                      </Grid.Unit>
                      <Grid.Unit
                        size={{ xs: 6 / 12, sm: 6 / 12 }}
                        style={{ textAlign: 'right' }}
                      >
                        <ArrowContainer onClick={prevTheme}>
                          <SVGInline
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path><path d="M0-.5h24v24H0z" fill="none"></path></svg>`}
                          />
                        </ArrowContainer>
                        <ArrowContainer onClick={nextTheme}>
                          <SVGInline
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path><path d="M0-.25h24v24H0z" fill="none"></path></svg>`}
                          />
                        </ArrowContainer>
                      </Grid.Unit>
                    </Grid>
                    <TextAreaInputTheme
                      value={theme.description}
                      placeholder="Theme Description"
                      onChange={(e) =>
                        setThemeField(themeIndex, 'description', e.target.value)
                      }
                    />
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}>
                            {(theme.quotes || []).map((q, i) => {
                              return (
                                <Draggable
                                  key={i}
                                  draggableId={`draggable-quote-${i}`}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div>
                                        <div
                                          style={{ position: 'relative' }}
                                          key={i}
                                        >
                                          <Remove
                                            onClick={(e) => removeQuote(i)}
                                          >
                                            <SVGInline
                                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>bin</title><path class="a" d="M17.25,21H6.75a1.5,1.5,0,0,1-1.5-1.5V6h13.5V19.5A1.5,1.5,0,0,1,17.25,21Z"></path><line class="a" x1="9.75" y1="16.5" x2="9.75" y2="10.5"></line><line class="a" x1="14.25" y1="16.5" x2="14.25" y2="10.5"></line><line class="a" x1="2.25" y1="6" x2="21.75" y2="6"></line><path class="a" d="M14.25,3H9.75a1.5,1.5,0,0,0-1.5,1.5V6h7.5V4.5A1.5,1.5,0,0,0,14.25,3Z"></path></svg>`}
                                            />
                                          </Remove>
                                          <TextAreaInputTheme
                                            value={q.text}
                                            onChange={(e) =>
                                              updateQuote(i, e.target.value)
                                            }
                                          />
                                          <ParagraphQuote
                                            color={i % 2 ? '#536976' : null}
                                          >
                                            {q.tags.map((tag) => `#${tag} `)}
                                          </ParagraphQuote>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <ButtonHollow onClick={addQuote}>Add Quote</ButtonHollow>
                  </Padding>
                </ThemesGridUnit>
              </Grid>
            </Padding>
          </Box>
        </Padding>
      </Grid.Unit>
    </Grid>
  );
}

export function DraggableList(props) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd || null}>
      <Droppable droppableId={props.id}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {(props.items || []).map((q, i) => {
              return (
                <Draggable key={i} draggableId={`props.id-${i}`} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {props.renderItem(q, i)}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export const BRAND = gql`
  query($account: ID, $id: ID) {
    account(id: $account) {
      id
      name
    }
    brand(id: $id) {
      id
      name
      consumerFindings {
        id
        type
        contents
      }
    }
  }
`;

export function AdminConsumerFindingsLoader(props) {
  const { data, loading, error } = useQuery(BRAND, {
    variables: {
      account: props.match.params.account,
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  const findings = JSON.parse(
    JSON.stringify(data.brand.consumerFindings).split(' .').join('.')
  );

  /*const brand = {
    ...data.brand,
    consumerFindings: {
      ...data.brand.consumerFindings,
      contents: getConsumerFindings('a74d9fd8-4a80-11e9-8646-d663bd873d93')
    }
  };*/

  return (
    <ConsumerFindingsScreen
      findings={findings}
      brand={data.brand}
      account={data.account}
    />
  );
}

export default AdminConsumerFindingsLoader;
