import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  HeadlineFour,
  Paragraph,
  QuoteBlue
} from '../../../components/typography';
import { Divider } from '../../../components/decoration/divider';
import { LinkList, LinkListItem } from '../../../components/lists';
import SideBar from '../../../components/navigation/sidebar';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer
} from '../../../components/buttons';
import { getConsumerFindings } from '../../../data';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import { stringToParagraphs } from '../../../utils';

interface ConsumerFindingsScreenProps {
  brand: string;
  findings: any;
  downloads: any;
}

export const ThemesGridUnit = styled(Grid.Unit)`
  background-color: #f7fbfd;
`;

export const ThemeHeadline = styled(HeadlineTwo)`
  color: #536976;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0;
`;

export function ConsumerFindingsScreen(props: ConsumerFindingsScreenProps) {
  return (
    <Container>
      <SideBar active={'strategic'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
          <Padding all={2}>
            <HeadlineOne>CONSUMER FINDINGS</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
          <Padding all={2} style={{ height: '100%' }}>
            <DownloadButtonContainer>
              <DownloadButton>
                <a
                  href={`${process.env.REACT_APP_API ||
                    'http://localhost:3000'}/${
                    props.downloads.consumerFindings
                  }`}
                  target="_blank">
                  <SVGInline
                    svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                  />
                  DOWNLOAD CONSUMER FINDINGS
                </a>
              </DownloadButton>
            </DownloadButtonContainer>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>PURPOSE</HeadlineTwo>
                      <Divider />
                      <Paragraph>{props.findings.purpose}</Paragraph>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 8 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>METHODOLOGY</HeadlineTwo>
                      <Divider />
                      <Grid>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <HeadlineFour>DEMOGRAPHICS</HeadlineFour>
                          {stringToParagraphs(
                            props.findings.demographics,
                            (text, i) => (
                              <Paragraph key={i}>{text}</Paragraph>
                            )
                          )}
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <HeadlineFour>AGE</HeadlineFour>

                          {stringToParagraphs(props.findings.age, (text, i) => (
                            <Paragraph key={i}>{text}</Paragraph>
                          ))}
                          <HeadlineFour>OCCUPATION</HeadlineFour>

                          {stringToParagraphs(
                            props.findings.occupation,
                            (text, i) => (
                              <Paragraph key={i}>{text}</Paragraph>
                            )
                          )}
                          <HeadlineFour>GEOGRAPHY</HeadlineFour>

                          {stringToParagraphs(
                            props.findings.geography,
                            (text, i) => (
                              <Paragraph key={i}>{text}</Paragraph>
                            )
                          )}
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <Padding left={{ md: 3 }}>
                            <HeadlineFour>ACTIVITIES</HeadlineFour>

                            {stringToParagraphs(
                              props.findings.activities,
                              (text, i) => (
                                <Paragraph key={i}>{text}</Paragraph>
                              )
                            )}
                            <HeadlineFour>PRODUCTS</HeadlineFour>

                            {stringToParagraphs(
                              props.findings.products,
                              (text, i) => (
                                <Paragraph key={i}>{text}</Paragraph>
                              )
                            )}
                            <HeadlineFour>BELOVED BRANDS</HeadlineFour>

                            {stringToParagraphs(
                              props.findings.brands,
                              (text, i) => (
                                <Paragraph key={i}>{text}</Paragraph>
                              )
                            )}
                          </Padding>
                        </Grid.Unit>
                      </Grid>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Themes findings={props.findings} />
    </Container>
  );
}

export const ArrowContainer = styled.span`
  cursor: pointer;
  svg {
    fill: #536976;
  }
`;

export const ThemesHeadline = styled(HeadlineTwo)`
  span {
    margin-top: 2px;
  }

  ${breakpoint('md')`
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
  `}
`;

export const ParagraphQuote = styled(Paragraph)`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const ParagraphDescription = styled(Paragraph)`
  font-size: 16px;
  margin-bottom: 24px;
  color: #222222;
`;

export function Themes(props: any) {
  const themes = props.findings.themes || [];
  const [themeIndex, setThemeIndex] = React.useState(0);
  const theme = themes[themeIndex] || { quotes: [] };

  const nextTheme = () => {
    if (themeIndex + 1 === themes.length) {
      return setThemeIndex(0);
    }

    setThemeIndex(themeIndex + 1);
  };
  const prevTheme = () => {
    if (themeIndex === 0) {
      return setThemeIndex(themes.length - 1);
    }
    return setThemeIndex(themeIndex - 1);
  };

  const half = Math.floor(themes.length / 2);

  const left = themes.slice(0, half);
  const right = themes.slice(half, themes.length);

  return (
    <Grid>
      <Grid.Unit size={{ sm: 1 }}>
        <Padding all={2}>
          <Box>
            <Padding all={3}>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={2} right={2}>
                    <ThemesHeadline>
                      THEMES{' '}
                      <span>
                        {props.headline
                          ? props.headline
                          : `Themes include a statement summarizing the key takeaway
                        and representative commentary from customers.`}
                      </span>
                    </ThemesHeadline>
                    <Divider />
                  </Padding>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 2 / 12 }}>
                  <Padding all={2} left={2}>
                    <LinkList>
                      {left.map((_theme, i) => {
                        return (
                          <LinkListItem
                            key={`left-${i}`}
                            onClick={e => setThemeIndex(i)}
                            active={i === themeIndex}>
                            {_theme.name}
                          </LinkListItem>
                        );
                      })}
                    </LinkList>
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1, md: 3 / 12 }}>
                  <Padding all={2} left={2}>
                    <LinkList>
                      {right.map((_theme, i) => {
                        return (
                          <LinkListItem
                            key={`right-${i}`}
                            onClick={e => setThemeIndex(half + i)}
                            active={half + i === themeIndex}>
                            {_theme.name}
                          </LinkListItem>
                        );
                      })}
                    </LinkList>
                  </Padding>
                </Grid.Unit>
                <ThemesGridUnit size={{ sm: 1, md: 7 / 12 }}>
                  <Padding all={3} left={3} right={3}>
                    <Grid>
                      <Grid.Unit size={{ xs: 6 / 12, sm: 6 / 12 }}>
                        <ThemeHeadline>{theme.name}</ThemeHeadline>
                      </Grid.Unit>
                      <Grid.Unit
                        size={{ xs: 6 / 12, sm: 6 / 12 }}
                        style={{ textAlign: 'right' }}>
                        <ArrowContainer onClick={prevTheme}>
                          <SVGInline
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path><path d="M0-.5h24v24H0z" fill="none"></path></svg>`}
                          />
                        </ArrowContainer>
                        <ArrowContainer onClick={nextTheme}>
                          <SVGInline
                            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path><path d="M0-.25h24v24H0z" fill="none"></path></svg>`}
                          />
                        </ArrowContainer>
                      </Grid.Unit>
                    </Grid>
                    <ParagraphDescription>
                      {theme.description}
                    </ParagraphDescription>
                    {theme.quotes.map((q, i) => {
                      return (
                        <div key={i}>
                          <ParagraphQuote color={i % 2 ? '#536976' : null}>
                            {'"'}
                            {q.text}
                            {'"'}
                          </ParagraphQuote>
                          <ParagraphQuote color={i % 2 ? '#536976' : null}>
                            {q.tags.map(tag => `#${tag} `)}
                          </ParagraphQuote>
                        </div>
                      );
                    })}
                  </Padding>
                </ThemesGridUnit>
              </Grid>
            </Padding>
          </Box>
        </Padding>
      </Grid.Unit>
    </Grid>
  );
}

export const BRAND = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      downloads {
        consumerFindings
      }
      consumerFindings {
        id
        type
        contents
      }
    }
  }
`;

export function ConsumerFindingsScreenLoader(props: any) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: { id: props.match.params.brand }
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <ConsumerFindingsScreen
      findings={data.brand.consumerFindings.contents}
      downloads={data.brand.downloads}
      brand={props.match.params.brand}
    />
  );
}

export default ConsumerFindingsScreenLoader;
