import React, { Component } from 'react';
import './App.css';
import styled, { ThemeProvider } from 'styled-components';
import theme from './theme';
import Navigation from './components/navigation';
import Sidebar from './components/navigation/sidebar';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useLoadQuantData } from './metrics/api';

import Dashboard from './screens/brand/dashboard';
import Login from './screens/auth/login';
import Setup from './screens/auth/setup';
import Privacy, { TermsPage } from './screens/auth/privacy';
import Segment from './screens/brand/segment';
import SegmentCompare from './screens/brand/segment.compare';
import SegmentDescription from './screens/brand/segment.description';
import AffinityLadder from './screens/brand/ladders/affinity.screen';
import LoyalistLadder from './screens/brand/ladders/loyalist.screen';
import ConsumerFindings from './screens/brand/findings/consumer.screen';
import StakeholderFindings from './screens/brand/findings/stakeholder.screen';
import ChooseBrand from './screens/brand/choose';
import StoryUniverse from './screens/brand/story-universe/story.universe.screen';
import GlossaryOfTerms from './screens/brand/glossary';
import FiveBonds from './screens/brand/five-bonds';
import Account from './screens/account/account';

import useSession from './context/session';
import * as store from 'store';
import AdminAccounts from './screens/admin/accounts';
import AdminAccount from './screens/admin/account';
import AdminBrand from './screens/admin/brand';
import AdminAffinityLadder from './screens/admin/affinity.ladder';
import AdminLoyaltyLadder from './screens/admin/loyalty.ladder';
import AdminStakeholderFindings from './screens/admin/stakeholder.findings';
import AdminConsumerFindings from './screens/admin/consumer.findings';
import AdminCustomerActivationSegment from './screens/admin/customer.activation';
import AdminStoryUniverse from './screens/admin/story.universe';
import AdminUploadData from './screens/admin/upload.data';
import AdminImportData from './screens/admin/import-data';
import AdminDataConfig from './screens/admin/config.data';

import ApolloClient, { gql } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import {
  ApolloProvider as ApolloProviderHooks,
  useQuery,
} from 'react-apollo-hooks';
import AdminAddBrand from './screens/admin/brand.add';
import AdminBrandEdit from './screens/admin/brand.edit';
import AdminDownloads from './screens/admin/downloads';
import AdminAddAccount from './screens/admin/account.add';
import AdminEditAccount from './screens/admin/account.edit';
import AdminFiveBonds from './screens/admin/five-bonds';
import StrategicLibrary from './screens/brand/strategic.landing';
import AdminAddUser, {
  AdminEditUserLoader as AdminEditUser,
} from './screens/admin/user.add';
import Footer from './components/navigation/footer';
import { Notifications } from './components/admin/notifications';

import RequestPassword from './screens/auth/request.password';
import ResetPassword from './screens/auth/reset.password';

import { setGlobal } from 'reactn';
import moment from 'moment';
import NavigationAdmin from './components/navigation/admin';

// Set loading to true.
setGlobal({
  show: false,
  message: null,
  showing: false,
  type: null,
  term: '3-months',
  scoring: '1.1',
  term_one_base: null, // moment().format('YYYY-MM-DD'),
  term_one_compare: null,
  term_two_base: '',
  term_two_compare: '',
  term_three_base: '',
  term_three_compare: '',
  brand: null,
  loadingBrand: true,
  quantDataLoading: true,
});

// Pass your GraphQL endpoint to uri
const client = new ApolloClient({
  uri: process.env.REACT_APP_API
    ? `${process.env.REACT_APP_API}/gql`
    : 'http://localhost:3000/gql',
  request: async (operation) => {
    if (store.get('token')) {
      operation.setContext({
        headers: {
          authorization: store.get('token'),
        },
      });
    }
  },
});

const MinHeightWrapper = styled.div`
  min-height: calc(100vh - 212px);
`;

function renderLoggedInRoute(Component) {
  return (props) => {
    if (!store.get('session')) {
      return null;
    }

    const { data, brandLoading, loading } = useLoadQuantData({
      brandId: props.match.params.brand,
      prefix: 'term_one',
      comparePrefix: 'term_one',
    });

    // if (brandLoading) {
    //   return null;
    // }

    return (
      <div>
        <Navigation
          quantData={data}
          history={props.history}
          brand={props.match.params.brand}
        />
        <div
          data-aos="fade-in"
          data-aos-duration="300"
          data-aos-delay="300"
          data-aos-offset="100"
        >
          <MinHeightWrapper>
            <Component {...props} quantData={data} />
          </MinHeightWrapper>
          <Footer />
        </div>
      </div>
    );
  };
}

function renderNonScopedLoggedInRoute(Component) {
  return (props) => {
    if (!store.get('session')) {
      return null;
    }

    return (
      <div>
        <NavigationAdmin
          history={props.history}
          brand={props.match.params.brand}
        />
        <div
          data-aos="fade-in"
          data-aos-duration="300"
          data-aos-delay="300"
          data-aos-offset="100"
        >
          <MinHeightWrapper>
            <Component {...props} />
          </MinHeightWrapper>
          <Footer />
        </div>
      </div>
    );
  };
}

function renderAdminRoute(Component) {
  return (props) => {
    if (store.get('role') !== 'Admin') {
      return <div>Unauthroized</div>;
    }

    return (
      <div>
        <NavigationAdmin
          history={props.history}
          brand={props.match.params.brand}
        />
        <div
          data-aos="fade-in"
          data-aos-duration="300"
          data-aos-delay="300"
          data-aos-offset="100"
        >
          <Component {...props} />
        </div>
      </div>
    );
  };
}

const ME = gql`
  query ($id: ID!) {
    me {
      id
      email
    }
    brand(id: $id) {
      id
      canAdminBrand
      account {
        id
      }
    }
  }
`;

function AdminBrandGuard(props) {
  const { data, error, loading } = useQuery(ME, {
    variables: { id: props.match.params.brand },
    fetchPolicy: 'network-only',
  });

  if (error) return null;
  if (loading) return null;

  if (data.brand.canAdminBrand === true || store.get('role') === 'Admin') {
    return props.children;
  }

  return <div>Unauthorized</div>;
}

function renderAdminBrandRoute(Component) {
  return (props) => {
    return (
      <div>
        <Navigation history={props.history} brand={props.match.params.brand} />
        <div
          data-aos="fade-in"
          data-aos-duration="300"
          data-aos-delay="300"
          data-aos-offset="100"
        >
          <AdminBrandGuard {...props}>
            <Component {...props} />
          </AdminBrandGuard>
        </div>
      </div>
    );
  };
}

function renderLoggedOutRoute(Component, LoggedInComponent) {
  return (props) => {
    if (store.get('session')) {
      return renderLoggedInRoute(LoggedInComponent)(props);
    }

    return (
      <div>
        <Component {...props} />
      </div>
    );
  };
}

function AppRouter() {
  return (
    <Router>
      <div>
        <Notifications />
        {/*<Sidebar />*/}
        <Route
          exact
          path="/"
          component={renderLoggedOutRoute(Login, ChooseBrand)}
        />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={RequestPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/setup/:user/:token" component={Setup} />
        <Route
          exact
          path="/brands/:brand/account"
          component={renderLoggedInRoute(Account)}
        />
        <Route exact path="/account" component={renderLoggedInRoute(Account)} />
        <Route
          exact
          path="/brands/:brand"
          component={renderLoggedInRoute(Dashboard)}
        />

        <Route
          exact
          path="/brands/:brand/segment/:segment"
          component={renderLoggedInRoute(Segment)}
        />
        <Route
          exact
          path="/brands/:brand/segment/:segment/compare"
          component={renderLoggedInRoute(SegmentCompare)}
        />
        <Route
          exact
          path="/brands/:brand/segment/:segment/description"
          component={renderLoggedInRoute(SegmentDescription)}
        />
        <Route
          exact
          path="/brands/:brand/ladders/affinity"
          component={renderLoggedInRoute(AffinityLadder)}
        />
        <Route
          exact
          path="/brands/:brand/ladders/loyalty"
          component={renderLoggedInRoute(LoyalistLadder)}
        />
        <Route
          exact
          path="/brands/:brand/findings/consumer"
          component={renderLoggedInRoute(ConsumerFindings)}
        />
        <Route
          exact
          path="/brands/:brand/findings/stakeholder"
          component={renderLoggedInRoute(StakeholderFindings)}
        />
        <Route
          exact
          path="/brands/:brand/story-universe"
          component={renderLoggedInRoute(StoryUniverse)}
        />
        <Route
          exact
          path="/brands/:brand/glossary-of-terms"
          component={renderLoggedInRoute(GlossaryOfTerms)}
        />
        <Route
          exact
          path="/brands/:brand/bonds"
          component={renderLoggedInRoute(FiveBonds)}
        />
        <Route
          exact
          path="/brands/:brand/strategic-library"
          component={renderLoggedInRoute(StrategicLibrary)}
        />
        <Route
          exact
          path="/brands"
          component={renderNonScopedLoggedInRoute(ChooseBrand)}
        />

        <Route
          exact
          path="/admin"
          component={renderAdminRoute(AdminAccounts)}
        />
        <Route
          exact
          path="/admin/add-account"
          component={renderAdminRoute(AdminAddAccount)}
        />
        <Route
          exact
          path="/admin/account/:account"
          component={renderAdminRoute(AdminAccount)}
        />
        <Route
          exact
          path="/admin/account/:account/edit"
          component={renderAdminRoute(AdminEditAccount)}
        />
        <Route
          exact
          path="/admin/account/:account/add-brand"
          component={renderAdminRoute(AdminAddBrand)}
        />
        <Route
          exact
          path="/admin/account/:account/add-user"
          component={renderAdminRoute(AdminAddUser)}
        />
        <Route
          exact
          path="/admin/account/:account/user/:user"
          component={renderAdminRoute(AdminEditUser)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand"
          component={renderAdminBrandRoute(AdminBrand)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/edit"
          component={renderAdminBrandRoute(AdminBrandEdit)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/affinity"
          component={renderAdminBrandRoute(AdminAffinityLadder)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/loyalty"
          component={renderAdminBrandRoute(AdminLoyaltyLadder)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/stakeholder"
          component={renderAdminBrandRoute(AdminStakeholderFindings)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/consumer"
          component={renderAdminBrandRoute(AdminConsumerFindings)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/downloads"
          component={renderAdminBrandRoute(AdminDownloads)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/upload"
          component={renderAdminBrandRoute(AdminUploadData)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/import"
          component={renderAdminBrandRoute(AdminImportData)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/config"
          component={renderAdminBrandRoute(AdminDataConfig)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/segment/:segment"
          component={renderAdminBrandRoute(AdminCustomerActivationSegment)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/su"
          component={renderAdminBrandRoute(AdminStoryUniverse)}
        />
        <Route
          exact
          path="/admin/account/:account/brand/:brand/bonds"
          component={renderAdminBrandRoute(AdminFiveBonds)}
        />
      </div>
    </Router>
  );
}

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ApolloProviderHooks client={client}>
            <AppRouter />
          </ApolloProviderHooks>
        </ApolloProvider>
      </ThemeProvider>
    );
  }
}

export default App;
