import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadInput from '../../components/inputs/upload.transactions';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import SelectInput from '../../components/inputs/select';
import { useForm } from 'react-hook-form';
import * as store from 'store';

export const UPDATE_BRAND = gql`
  mutation ($id: ID, $input: BrandInput) {
    updateBrand(id: $id, input: $input) {
      id
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export function AdminAccount(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(UPDATE_BRAND);
  const [csvData, setCSVData] = React.useState(null);

  const { register, getValues, watch } = useForm({
    defaultValues: {
      dataFormat: props.brand.dataConfig.dataFormat,
      dataVersion: props.brand.dataConfig.dataVersion,
      reportingTerm: props.brand.dataConfig.reportingTerm,

      prospects: {
        users: props.brand.dataConfig.prospects.users,
        costPer: props.brand.dataConfig.prospects.costPer,
      },
      casuals: {
        minOrders: props.brand.dataConfig.casuals.minOrders,
        maxOrders: props.brand.dataConfig.casuals.maxOrders,
        marginPer: props.brand.dataConfig.casuals.marginPer,
        costPer: props.brand.dataConfig.casuals.costPer,
        targetAquisition: props.brand.dataConfig.casuals.targetAquisition,
      },
      loyalists: {
        minOrders: props.brand.dataConfig.loyalists.minOrders,
        maxOrders: props.brand.dataConfig.loyalists.maxOrders,
        marginPer: props.brand.dataConfig.loyalists.marginPer,
        costPer: props.brand.dataConfig.loyalists.costPer,
      },
      cheerleaders: {
        minOrders: props.brand.dataConfig.cheerleaders.minOrders,
        maxOrders: props.brand.dataConfig.cheerleaders.maxOrders,
        marginPer: props.brand.dataConfig.cheerleaders.marginPer,
        costPer: props.brand.dataConfig.cheerleaders.costPer,
      },
    },
  });

  const breadcrumbs =
    store.get('role') !== 'Admin'
      ? [
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
          { label: 'Downloads' },
        ]
      : [
          { label: 'Admin', link: `/admin` },
          {
            label: props.account.name,
            link: `/admin/account/${props.account.id}`,
          },
          { label: 'Brands' },
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
          { label: 'Downloads' },
        ];

  const buttons = [
    {
      label: 'Save Config',
      onClick: save,
    },
  ];

  function onUpload(value) {
    console.log(value);
  }

  async function save() {
    try {
      const values = getValues();
      const response: any = await mutation({
        variables: {
          id: props.brand.id,
          input: {
            dataConfig: {
              dataFormat: values.dataFormat,
              dataVersion: values.dataVersion,
              reportingTerm: values.reportingTerm,
              prospects: {
                users: parseFloat(values['prospects.users']),
                costPer: parseFloat(values['prospects.costPer']),
              },
              casuals: {
                minOrders: parseFloat(values['casuals.minOrders']),
                maxOrders: parseFloat(values['casuals.maxOrders']),
                costPer: parseFloat(values['casuals.costPer']),
                marginPer: parseFloat(values['casuals.marginPer']),
                targetAquisition: parseFloat(
                  values['casuals.targetAquisition']
                ),
              },
              loyalists: {
                minOrders: parseFloat(values['loyalists.minOrders']),
                maxOrders: parseFloat(values['loyalists.maxOrders']),
                costPer: parseFloat(values['loyalists.costPer']),
                marginPer: parseFloat(values['loyalists.marginPer']),
              },
              cheerleaders: {
                minOrders: parseFloat(values['cheerleaders.minOrders']),
                maxOrders: parseFloat(values['cheerleaders.maxOrders']),
                costPer: parseFloat(values['cheerleaders.costPer']),
                marginPer: parseFloat(values['cheerleaders.marginPer']),
              },
            },
          },
        },
      });

      notify(setGlobal, 'Saved Data Config');
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AdminScreen
      headline={'Data Config'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <SelectInput
                        label="Data Format"
                        options={[
                          { label: 'Shopify', value: 'shopify' },
                          { label: 'SK1', value: 'sitka' },
                          { label: 'GP1', value: 'goop' },
                        ]}
                        inputProps={{
                          ref: register({}),
                          name: 'dataFormat',
                        }}
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <SelectInput
                        label="Data Version"
                        options={[
                          { label: '1.0', value: '1.0' },
                          { label: '2.0', value: '2.0' },
                        ]}
                        inputProps={{
                          ref: register({}),
                          name: 'dataVersion',
                        }}
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <SelectInput
                        label="Term Period"
                        options={[
                          { label: '1 Year', value: '1-year' },
                          { label: '2 Years', value: '2-year' },
                        ]}
                        inputProps={{
                          ref: register({}),
                          name: 'reportingTerm',
                        }}
                      />
                    </Padding>
                  </Grid.Unit>
                  {/* PROSPECTS */}
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <HeadlineTwo>PROSPECTS</HeadlineTwo>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'prospects.users',
                        }}
                        label="Prospect total users"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'prospects.costPer',
                        }}
                        label="Cost per"
                      />
                    </Padding>
                  </Grid.Unit>
                  {/* CASUALS */}
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <HeadlineTwo>CASUALS</HeadlineTwo>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'casuals.minOrders',
                        }}
                        label="Min Orders in a term period"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'casuals.maxOrders',
                        }}
                        label="Max Orders in term period"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'casuals.marginPer',
                        }}
                        label="Margin per"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'casuals.costPer',
                        }}
                        label="Cost per"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'casuals.targetAquisition',
                        }}
                        label="Target Aquisition"
                      />
                    </Padding>
                  </Grid.Unit>
                  {/* Loyalists */}
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <HeadlineTwo>Loyalists</HeadlineTwo>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'loyalists.minOrders',
                        }}
                        label="Min Orders in a term period"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'loyalists.maxOrders',
                        }}
                        label="Max Orders in term period"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'loyalists.marginPer',
                        }}
                        label="Margin per"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'loyalists.costPer',
                        }}
                        label="Cost per"
                      />
                    </Padding>
                  </Grid.Unit>
                  {/* Cheerleaders */}
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <HeadlineTwo>Cheerleaders</HeadlineTwo>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'cheerleaders.minOrders',
                        }}
                        label="Min Orders in a term period"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'cheerleaders.maxOrders',
                        }}
                        label="Max Orders in term period"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'cheerleaders.marginPer',
                        }}
                        label="Margin per"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 / 4 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          type: 'number',
                          ref: register({}),
                          name: 'cheerleaders.costPer',
                        }}
                        label="Cost per"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export const ACCOUNT = gql`
  query ($id: ID, $brand: ID) {
    brand(id: $brand) {
      id
      name
      dataConfig {
        dataFormat
        dataVersion
        reportingTerm
        prospects {
          costPer
          users
        }
        casuals {
          minOrders
          maxOrders
          marginPer
          costPer
          targetAquisition
        }
        loyalists {
          minOrders
          maxOrders
          marginPer
          costPer
        }
        cheerleaders {
          minOrders
          maxOrders
          marginPer
          costPer
        }
      }
    }
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT, {
    variables: {
      id: props.match.params.account,
      brand: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminAccount
      history={props.history}
      brand={data.brand}
      account={data.account}
    />
  );
}

export default AdminAccountLoader;
