import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadPhoto from '../../components/inputs/upload.photo';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';

export const ADD_BRAND = gql`
  mutation($input: AccountInput) {
    addAccount(input: $input) {
      id
      name
    }
  }
`;

export function AdminAccount(props) {
  const mutation = useMutation(ADD_BRAND);
  const [name, setName] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const [global, setGlobal] = useGlobal();
  const breadcrumbs = [
    { label: 'Admin', link: `/admin` },

    {
      label: 'Add Account',
    },
  ];

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          input: { name: name },
        },
      });

      notify(setGlobal, 'Added Account', 'success');

      props.history.push(`/admin/account/${response.data.addAccount.id}`);
    } catch (e) {
      console.log(e);
      notify(setGlobal, 'There was an error adding the Account!', 'error');
    }
  }

  const buttons = [{ label: 'Add Account', onClick: save }];

  return (
    <AdminScreen
      headline={'Add Account'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Account Name"
                        placeholder="Account Name"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAccountLoader(props) {
  return <AdminAccount history={props.history} />;
}

export default AdminAccountLoader;
