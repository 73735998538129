import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineThree } from '../../components/typography';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import * as store from 'store';

export function AdminBrand(props) {
  const breadcrumbs =
    store.get('role') !== 'Admin'
      ? [
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
        ]
      : [
          { label: 'Admin', link: `/admin` },
          {
            label: props.account.name,
            link: `/admin/account/${props.account.id}`,
          },
          { label: 'Brands' },
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
        ];

  const buttons =
    store.get('role') !== 'Admin'
      ? []
      : [
          {
            label: 'Edit Brand',
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}/edit`,
          },
        ];

  return (
    <AdminScreen
      headline={props.brand.name}
      buttons={buttons}
      breadcrumbs={breadcrumbs}>
      <HeadlineTwo>Customer Segments</HeadlineTwo>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/segment/prospect`}>
          Prospects
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/segment/casual`}>
          Casuals
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/segment/loyalist`}>
          Loyalists
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/segment/cheerleaders`}>
          Cheerleaders
        </Link>
      </Row>
      <HeadlineTwo>Strategic Library</HeadlineTwo>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/su`}>
          Story Universe
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/bonds`}>
          Five Bonds of Loyalty
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/affinity`}>
          Affinity Ladder
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/loyalty`}>
          Loyalty Ladder
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/consumer`}>
          Consumer Findings
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/stakeholder`}>
          Stakeholder Findings
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/downloads`}>
          Downloads
        </Link>
      </Row>
      <HeadlineTwo>Data</HeadlineTwo>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/import`}>
          Import Data
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/upload`}>
          Upload Data
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/account/${props.match.params.account}/brand/${props.match.params.brand}/config`}>
          Configure Data
        </Link>
      </Row>
    </AdminScreen>
  );
}

export const BRAND = gql`
  query($account: ID, $id: ID) {
    account(id: $account) {
      id
      name
    }
    brand(id: $id) {
      id
      name
    }
  }
`;

export function AdminBrandLoader(props) {
  const { data, loading, error } = useQuery(BRAND, {
    variables: {
      account: props.match.params.account,
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return <AdminBrand {...props} brand={data.brand} account={data.account} />;
}

export default AdminBrandLoader;
