import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../../components/containers/box';

import { HeadlineOne } from '../../../components/typography';
import { Divider } from '../../../components/decoration/divider';
import { AlignCenter } from '../../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../../components/lists';
import SideBar from '../../../components/navigation/sidebar';
import {
  drawStoryUniverse,
  handleMouseOut,
  handleMouseOver,
} from './renderer/render';
import nodes, { wanderlustNodes, sitkaNodes } from './story.universe.nodes';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import storyUniverseIcon from "../../../components/typography/icons/storyUniverseIcon";
import SVGInline from 'react-svg-inline';

interface StoryUniverseScreenProps {
  brand: any;
  nodes: any;
}

export const StoryUniverseContainer = styled.div`
  position: relative;
  overflow: hidden;

  circle {
    stroke: #000;
    fill: white;
    opacity: 1;
    stroke-width: 2px;
    cursor: pointer;

    :hover {
      // fill: #509bb0;
      // stroke: #509bb0;
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 11px;
    text-align: center;
  }

  .depth-1 p {
    font-size: 10px;
    pointer-events: none;
  }

  .depth-0 {
    stroke-width: 4px;
    fill: #fff;
  }

  .depth-1 {
    font-size: 10px;
    p {
      font-size: 11px;
      text-align: center;
      pointer-events: none;
    }
  }

  p {
    &.depth-1 {
      pointer-events: none;
    }
  }

  .depth-1 {
    stroke-width: 2px;
    stroke: #eee;
    stoke-dasharray: 3 3 3;

    fill: #fff;
  }

  .depth-2 {
    fill: #fff;
    stroke-width: 4px;
  }

  .depth-3 {
    stroke-width: 3px;
    stroke: #000;
    fill: #fff;
  }

  .depth-path-2 {
    // stroke: #3dd87b;
  }

  .diag {
    fill: none;
    stroke: #eee;
    opacity: 0.4;
  }

  .depth-4 {
    fill: #000;
  }

  .filtered {
    &.depth-3 {
      stroke: #ccc;
    }

    &.depth-4 {
      fill: #ccc;
      stroke: #ccc;
    }
  }

  .has-children {
    &.depth-4 {
      fill: #fff;
      stroke: #000;
    }

    &.filtered {
      stroke: #ccc;
    }
  }

  text {
    color: #4e4e4e;
    font-family: proxima-nova, sans-serif;
    font-weight: 300;
    letter-spacing: 1.1px;
    -webkit-font-smoothing: auto;
  }

  text {
    cursor: pointer;
  }

  path {
    fill: none;
    stroke: #777;
    opacity: 0.6;
    stroke-width: 1px;
    stroke-dasharray: 4 4 4;
  }

  svg {
    rect {
      &.depth-1 {
        stroke: #ff1717;
        fill: none;
      }
    }
  }

  .depth-0,
  .depth-1,
  .depth-2,
  .depth-3,
  .depth-4 {
    transition: all 0.5s;
    &.highlight {
      stroke: #509bb0;
    }
  }

  path {
    transition: all 0.5s;
    &.highlight {
      stroke: #509bb0;
    }
  }

  .depth-4 {
    &.highlight {
      fill: #509bb0;
    }
  }

  text {
    &.highlight {
      color: #509bb0;
    }
  }

  .highlight {
    transition: all 0.5s;
    // stroke: #509bb0;
  }

  .multitext-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;

    p {
      padding: 8px;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  height: 980px;
  width: 100%;
  top: 0;
  overflow: hidden;
  display: none;

  &.show {
    display: block;
  }

  .bg {
    position: absolute;
    height: 1180px;
    width: 100%;
    top: 0;
    background: rgba(255, 255, 255, 0.4);
    z-index: 888;
  }

  .info {
    position: absolute;
    background: rgb(247, 251, 253);
    right: 0;
    height: 1180px;
    width: 400px;
    top: 0;
    z-index: 999;
    padding: 32px 16px;

    h2 {
      color: #536976;
      font-family: proxima-nova, sans-serif;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 17px;
    }

    h3 {
      color: #536976;
      font-family: proxima-nova, sans-serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 17px;
    }

    p {
      color: #000000;
      font-family: 'Proxima Nova';
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      text-align: left;
    }

    .breadcrumbs {
      list-style: none;
      margin: 12px 0;
      padding: 0;

      li {
        display: inline-block;
        color: #000000;
        font-family: 'Proxima Nova';
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
        color: #536976;
        font-family: proxima-nova, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 17px;

        span {
          color: #536976;
          font-family: proxima-nova, sans-serif;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 17px;
        }
      }

      span {
        display: inline-block;
        margin: 0 4px;
      }
    }

    .node-children {
      list-style: disc;
      li {
        color: #000000;
        font-family: 'Proxima Nova';
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
      }
    }
  }
`;

export function makeNodeBreadcrumbs(node, crumbs = []) {
  if (!node || node.depth < 2) return [];
  crumbs.push(node);
  if (node.parent && node.parent.depth > 1) {
    makeNodeBreadcrumbs(node.parent, crumbs);
  }
  return crumbs;
}

export function NodeInfo({ node, setNode }) {
  const breadcrumbs = makeNodeBreadcrumbs(node);

  breadcrumbs.reverse();

  function setTheNode(node) {
    setNode((n) => {
      if (n) n.active = false;
      handleMouseOut(n);
      node.active = true;
      handleMouseOver(node);
      return node;
    });
  }

  return (
    <div>
      {node.depth < 2 ? <h2>{node.name}</h2> : null}
      <ul className="breadcrumbs">
        {breadcrumbs.map((node, i) => {
          return (
            <li onClick={(e) => setTheNode(node)} key={i}>
              {i > 0 ? <span>{'>'}</span> : null} {node.name}
            </li>
          );
        })}
      </ul>
      <p>{node.description}</p>
      {(node.children || []).length > 0 && <h3>More Stories</h3>}
      {(node.children || []).length > 0 && (
        <ul className="node-children">
          {(node.children || []).map((node, i) => {
            return (
              <li key={i} onClick={(e) => setTheNode(node)}>
                {node.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export function StoryUniverseScreen(props: StoryUniverseScreenProps) {
  const [node, setNode] = React.useState(null);
  const canvas = React.useRef(null);
  const wanderlust = React.useRef(null);
  const sitka = React.useRef(null);

  React.useLayoutEffect(() => {
    drawStoryUniverse(canvas.current, props.nodes || {}, setNode);
    // drawStoryUniverse(wanderlust.current, wanderlustNodes, setNode);
    // drawStoryUniverse(sitka.current, sitkaNodes, setNode);
    // drawStoryUniverse(wanderlust.current.getContext('2d'), wanderlustNodes);
    // drawStoryUniverse(sitka.current.getContext('2d'), sitkaNodes);
  }, []);

  function clearTheNode() {
    if (node) node.active = false;
    if (node) handleMouseOut(node);
    setNode(null);
  }

  return (
    <Container>
      <SideBar active={'story'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <SVGInline
                className="title-icon"
                style={{ marginRight: '15px', marginTop: '15px', height: '45px', width: '45px' }}
                svg={ storyUniverseIcon }/>
            <HeadlineOne>Story Universe</HeadlineOne>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit>
          <Box>
            <Padding all={2}>
              <StoryUniverseContainer>
                <Overlay className={node ? 'show' : 'hide'}>
                  <div className="bg" onClick={(e) => clearTheNode()} />
                  <div className="info">
                    {node && <NodeInfo setNode={setNode} node={node} />}
                  </div>
                </Overlay>
                <svg ref={canvas}>
                  <g />
                </svg>
              </StoryUniverseContainer>
            </Padding>
          </Box>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export const BRAND = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      name
      storyUniverse {
        id
        type
        contents
      }
    }
  }
`;

export function StoryUniverseScreenLoader(props: any) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (error) return null;
  if (!data || loading) return null;

  return (
    <StoryUniverseScreen
      brand={props.match.params.brand}
      nodes={data.brand.storyUniverse.contents}
    />
  );
}

export default StoryUniverseScreenLoader;
