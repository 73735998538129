import React, { Component, useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { getColorForScore } from '../../metrics/scoring';
import { Loader } from '../loader';
import moment from 'moment';
import { Link } from 'react-router-dom';

const formatterScores = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

const ProspectCircle = styled(Link)`
  width: 30px;
  height: 30px;
  display: inline-flex;
  border: 2px solid ${(props: any) => '#fff'};
  border-radius: 9999%;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    font-size: 12px;
  }
` as any;

const CasualCircle = styled(Link)`
  width: 28px;
  height: 28px;
  display: inline-flex;
  border: 3px solid ${(props: any) => '#fff'};
  border-radius: 9999%;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    font-size: 12px;
  }
` as any;

const LoyalistCircle = styled(Link)`
  width: 22px;
  height: 22px;
  display: inline-flex;
  border: 6px solid ${(props: any) => '#fff'};
  border-radius: 9999%;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    font-size: 12px;
  }
` as any;

const CheerleaderCircle = styled(Link)`
  width: 30px;
  height: 30px;
  display: inline-flex;
  border: 2px solid ${(props: any) => '#fff'};
  border-radius: 9999%;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: ${(props: any) => props.bordercolor || '#fff'};

  span {
    font-size: 12px;
  }
` as any;

const BACScore = styled.div`
  height: 30px;
  width: 30px;
  display: inline-flex;
  border: 1px solid ${(props: any) => '#fff'};
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  margin-right: 0px;

  border-radius: 9999px;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    color: #2d3c47;
    margin: 1px;
    background-color: #fff;
    width: 28px;
    height: 28px;
    border-radius: 9999px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
` as any;

export const ScoreContainer = styled.div`
  display: inline-flex;
  background: ${(props: any) => getColorForScore(props.score)};
  width: 60px;
  height: 100%;
  align-items: center;
  justify-content: center;

  .cac-label {
    color: #fff;
    transform: rotate(-90deg);
  }
` as any;

export const ScoreLabelContainer = styled.div`
  display: inline-flex;
  background: ${(props: any) => getColorForScore(props.score)};
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -1px;

  .cac-label {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    transform: rotate(-90deg);
  }
` as any;

function getOptions(hasRelativeOptions, base, today, options) {
  return [
    {
      value: moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      label: `${today}`,
      subLabel: null,
    },
    {
      value: moment(base).subtract(1, 'month').format('YYYY-MM-DD'),
      label: '1M CAS',
      subLabel: null,
    },
    {
      value: moment(base).subtract(3, 'months').format('YYYY-MM-DD'),
      label: '3M CAS',
      subLabel: null,
    },
    {
      value: moment(base).subtract(6, 'months').format('YYYY-MM-DD'),
      label: '6M CAS',
      subLabel: null,
    },
    {
      value: moment(base).subtract(9, 'months').format('YYYY-MM-DD'),
      label: '9M CAS',
      subLabel: null,
    },
    {
      value: moment(base).subtract(12, 'months').format('YYYY-MM-DD'),
      label: '1Y CAS',
      subLabel: null,
    },
    {
      value: moment(base).subtract(24, 'months').format('YYYY-MM-DD'),
      label: '2Y CAS',
      subLabel: null,
    },
  ];
}

function getDisplayLabel(date, base, today) {
  if (!date) return 'Select Date';

  const opt = getOptions(true, base, today, null).find((o) => o.value === date);

  if (opt) {
    return opt.label;
  }

  return date;
}

export function NavigationCAC() {
  const [brand]: [any, any] = useGlobal('brand');
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData, setQuantData] = useGlobal('quantData') as [any, any];
  const [baseDate] = useGlobal('term_one_base');
  const [compareDate] = useGlobal('term_one_compare');

  const label = getDisplayLabel(compareDate, baseDate, moment().toDate());

  if (!brand) {
    return null;
  }

  if (!brand.firstDataRecordDate || !brand.lastDataRecordDate) {
    return null;
  }

  if (quantDataLoading) {
    return <Loader />;
  }

  if (!quantData) {
    return <Loader />;
  }

  const data = quantData;

  return (
    <div
      style={{
        textAlign: 'right',
        height: 'calc(90px)',
        marginTop: '-26px',
        marginBottom: '-27px',
      }}
    >
      <ScoreLabelContainer
        style={{ marginRight: '2px' }}
        score={data.scores.total}
      >
        <span className="cac-label">{label}</span>
      </ScoreLabelContainer>
      <ScoreContainer score={data.SEGMENT_PROSPECTS.scores.total}>
        <ProspectCircle
          to={`/brands/${brand.id}/segment/prospect`}
          bordercolor={getColorForScore(data.SEGMENT_PROSPECTS.scores.total)}
        >
          <span>P</span>
        </ProspectCircle>
      </ScoreContainer>
      <ScoreContainer score={data.SEGMENT_CASUAL.scores.total}>
        <CasualCircle
          to={`/brands/${brand.id}/segment/casual`}
          bordercolor={getColorForScore(data.SEGMENT_CASUAL.scores.total)}
        >
          <span>C</span>
        </CasualCircle>
      </ScoreContainer>
      <ScoreContainer score={data.SEGMENT_LOYALIST.scores.total}>
        <LoyalistCircle
          to={`/brands/${brand.id}/segment/loyalist`}
          bordercolor={getColorForScore(data.SEGMENT_LOYALIST.scores.total)}
        >
          <span>L</span>
        </LoyalistCircle>
      </ScoreContainer>
      <ScoreContainer score={data.SEGMENT_CHEERLEADER.scores.total}>
        <CheerleaderCircle
          to={`/brands/${brand.id}/segment/cheerleader`}
          bordercolor={getColorForScore(data.SEGMENT_CHEERLEADER.scores.total)}
        >
          <span>CH</span>
        </CheerleaderCircle>
      </ScoreContainer>
      <ScoreContainer style={{ marginLeft: '1px' }} score={data.scores.total}>
        <BACScore backgroundColor={getColorForScore(data.scores.total)}>
          <span>{formatterScores.format(data.scores.total)}</span>
        </BACScore>
      </ScoreContainer>
    </div>
  );
}
