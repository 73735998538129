import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import SideBar from '../../components/navigation/sidebar';
import TextInput from '../../components/inputs/text';
import { ButtonHollow } from '../../components/buttons';
import useSession from '../../context/session';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import * as store from 'store';
import { UPDATE_ME } from '../account/account';
import { TermsAndConditions } from './terms';

export const LOGIN = gql`
  mutation ($input: UserInput) {
    login(input: $input) {
      id
      token
      role
      firstName
      lastName
      tc
    }
  }
`;

export const UPDATE_TERMS = gql`
  mutation {
    updateMe(input: { tc: 1 }) {
      id
    }
  }
`;

export const LoginButton = styled(ButtonHollow)`
  width: 100%;
  text-align: center;
  margin: 12px 0;
  padding: 16px;
  font-weight: 700;
`;

interface StakeHolderFindingsScreenProps {
  history?: any;
}

export const Logo = styled.div`
  text-align: center;
`;

export const FormError = styled.div`
  padding: 0.825rem;
  text-align: center;
  background: #de5f5f;
  margin: 0.5rem 0;
  border-radius: 4px;
  color: #fff;
`;

const TCScroller = styled.div`
  height: 500px;
  overflow-y: scroll;
`;

const termsprops: any = { name: 'termly-embed' };

/* tslint:disable */
export const terms: any = (<TermsAndConditions />) as any; /* tslint:disable */

export function StakeHolderFindingsScreen(
  props: StakeHolderFindingsScreenProps
) {
  const mutation = useMutation(LOGIN);
  const updateTerms = useMutation(UPDATE_TERMS);
  const [error, setError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { updateSession } = useSession();
  const [showTerms, setShowTerms] = React.useState(false);
  const [session, setSession]: [any, any] = React.useState({});

  const login = async () => {
    try {
      const response: any = await mutation({
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      if (response.data.login.token && !response.data.login.tc) {
        setSession(response);
        setShowTerms(true);
        return;
      }

      if (response.data.login.token) {
        store.set('token', response.data.login.token);
        store.set('firstName', response.data.login.firstName);
        store.set('lastName', response.data.login.lastName);
        store.set('role', response.data.login.role);
        updateSession(response.data.login.token);
        return props.history.push('/brands', true);
      }
    } catch (e) {
      setError(true);
    }
  };

  async function acceptTerms() {
    store.set('token', session.data.login.token);
    store.set('firstName', session.data.login.firstName);
    store.set('lastName', session.data.login.lastName);
    store.set('role', session.data.login.role);

    updateSession(session.data.login.token);
    await updateTerms({});
    return props.history.push('/brands', true);
  }

  if (showTerms) {
    return (
      <Container>
        <Grid>
          <Grid.Unit size={{ sm: 1 }}>
            <Padding all={2} />
          </Grid.Unit>
        </Grid>
        <Grid>
          <Grid.Unit size={{ sm: 1 / 12 }} />
          <Grid.Unit size={{ sm: 10 / 12 }}>
            <Padding all={2}>
              <Padding all={3}>
                <Logo>
                  <img src="/static/logo.png" />
                </Logo>
              </Padding>
              <Box>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>Terms and Conditions</HeadlineTwo>
                      <Divider />
                      <TCScroller>{terms}</TCScroller>
                      <LoginButton onClick={acceptTerms}>ACCEPT</LoginButton>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Box>
            </Padding>
          </Grid.Unit>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2} />
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 3 / 12 }} />
        <Grid.Unit size={{ sm: 6 / 12 }}>
          <Padding all={2}>
            <Padding all={3}>
              <Logo>
                <img src="/static/logo.png" />
              </Logo>
            </Padding>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={3}>
                    <HeadlineTwo>LOGIN</HeadlineTwo>
                    <Divider />
                    {error ? (
                      <FormError>Wrong username or password.</FormError>
                    ) : null}
                    <TextInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="email"
                      label="Email"
                    />
                    <TextInput
                      type="password"
                      placeholder="Password"
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div style={{ textAlign: 'right', marginBottom: 12 }}>
                      <a
                        style={{ color: ' #509bb0', fontSize: 12 }}
                        href="/reset-password"
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <LoginButton onClick={login}>LOGIN</LoginButton>
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export function StakeHolderFindingsScreenLoader(props: any) {
  return <StakeHolderFindingsScreen />;
}

export default StakeHolderFindingsScreen;
