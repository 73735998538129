import React from 'react';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Divider } from '../../../../components/decoration/divider';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import { Loader } from '../../../../components/loader';
import { ISegmentData, SEGMENTS } from '../../../../metrics/enum';
import { getColorForScore } from '../../../../metrics/scoring';

export const SegmentBACPanelContainer = styled.div`
  h2 {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 500;
    color: #657c89;
    margin: 16px 0;
  }

  .label {
    display: inline-flex;
    width: 18px;
    height: 18px;
    background: #b2d4b1;
    border-radius: 9999%;
    color: #333333;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    i {
      font-size: 12px;
      color: #fff;
    }
  }

  .label-warn {
    background: #b18e94;
  }

  .optimization {
    margin-bottom: 16px;

    div {
      display: flex;
    }

    .text {
      font-size: 14px;
      color: #333333;
      display: inline-flex;
      width: calc(100% - 26px);
    }

    i {
      font-size: 10px;
    }
  }
`;

export const ScoreCircleContainer = styled.div`
  margin-right: 12px;
`;

export const ScoreCircle = styled.div`
  width: 100px;
  height: 100px;
  background: ${(props: any) => props.backgroundColor};
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-size: 43px;
  }
` as any;

export const ScoreCircleText = styled.div`
  color: rgb(155, 155, 155);
  width: 100%;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
` as any;

const formatterScore = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

export function SegmentBACPanel({ segment }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (quantDataLoading) {
    return (
      <SegmentBACPanelContainer>
        <Loader />
      </SegmentBACPanelContainer>
    );
  }

  const data: ISegmentData = quantData[segment.type];

  if (!data) {
    return null;
  }

  if (segment.type === SEGMENTS.LOSTSOULS) {
    return null;
  }

  function renderOptimization(optimization, i) {
    return (
      <Grid.Unit key={i} size={{ sm: 1 }}>
        <div className="optimization">
          <div className="">
            <span
              className={`label ${optimization.negative ? 'label-warn' : ''}`}
            >
              <i
                className={`fa ${
                  optimization.negative ? 'fa-exclamation' : 'fa-check'
                }`}
              ></i>
            </span>
            <span className="text">{optimization.text}</span>
          </div>
        </div>
      </Grid.Unit>
    );
  }

  return (
    <SegmentBACPanelContainer>
        <h2>CUSTOMER ACTIVITAION SCORE (CAS)</h2>
      <Divider />
      <Padding top={3} bottom={3}>
        <div style={{ display: 'flex' }}>
          <div>
            <ScoreCircleContainer>
              <ScoreCircle
                backgroundColor={getColorForScore(data.scores.total)}
              >
                <span>{formatterScore.format(data.scores.total)}</span>
              </ScoreCircle>
              <ScoreCircleText>out of 5</ScoreCircleText>
            </ScoreCircleContainer>
          </div>
          <div>
            <Grid>{data.scores.optimizations.map(renderOptimization)}</Grid>
          </div>
        </div>
      </Padding>
    </SegmentBACPanelContainer>
  );
}
