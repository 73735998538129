import * as React from 'react';

export default function (setGlobal, message, type = 'success') {
  try {
    setGlobal({ show: true, showing: true, message, type });
    setTimeout(
      () => setGlobal({ show: false, showing: true, message: null, type }),
      2000
    );
  } catch (e) {
    //
  }
}
