import * as React from 'react';
import {
  debugCheerleadersCountAsPercentage,
  debugCheerleadersIndValue,
  debugCheerleadersMigrationForward,
  debugCheerleadersRevenueAsPercentage,
} from '../debug/cheerleaders';

import { IQuantData, ISegmentData, SEGMENTS } from '../enum';
import formatters, { formatter } from '../formatters';
import {
  insightsCheerleadersIndValue,
  insightsCheerleadersCountAsPercentage,
  insightsCheerleadersMigrationForward,
  insightsCheerleadersRevenueAsPercentage,
} from '../insights/cheerleaders';
import {
  optimizationsCheerleadersIndValue,
  optimizationsCheerleadersCountAsPercentage,
  optimizationsCheerleadersMigrationForward,
  optimizationsCheerleadersRevenueAsPercentage,
} from '../optimizations/cheerleaders';

import { WeightedScaleValue, WeightedValue, calcWeighted } from './common';

export interface BenchmarkCheerleaders {
  MIGRATION_FORWARD: number;
  COUNT_AS_PERCENTAGE: number;
  REVENUE_AS_PERCENTAGE: number;
  IND_VALUE: number;
}

export function formulasCheerleadersCountAsPercentage(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.current.countPercentOfTotal;
  console.log('RAW', raw);
  const weighted = calcWeighted(
    segment.current.countPercentOfTotal,
    benchmark,
    scale
  );

  return {
    raw: raw,
    weighted,
    name: debugCheerleadersCountAsPercentage(benchmark, raw, weighted),
    benchmark: benchmark,
    insights: insightsCheerleadersCountAsPercentage(benchmark, raw, weighted),
    optimizations: optimizationsCheerleadersCountAsPercentage(
      benchmark,
      raw,
      weighted
    ),
  };
}

export function formulasCheerleadersRevenueAsPercentage(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.current.revenueAsPercentageOfTotal;
  const weighted = calcWeighted(
    segment.current.revenueAsPercentageOfTotal,
    benchmark,
    scale
  );

  return {
    raw,
    weighted,
    name: debugCheerleadersRevenueAsPercentage(benchmark, raw, weighted),
    insights: insightsCheerleadersRevenueAsPercentage(benchmark, raw, weighted),
    optimizations: optimizationsCheerleadersRevenueAsPercentage(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function formulasCheerleadersMigrationForward(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.migration.stayed.cheerleaders / segment.previous.count;
  const weighted = calcWeighted(
    segment.migration.stayed.cheerleaders / segment.previous.count,
    benchmark,
    scale
  );

  return {
    raw,
    weighted,
    name: debugCheerleadersMigrationForward(benchmark, raw, weighted),
    insights: insightsCheerleadersMigrationForward(benchmark, raw, weighted),
    optimizations: optimizationsCheerleadersMigrationForward(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function formulasCheerleadersIndValue(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const casuals: ISegmentData = data[SEGMENTS.CASUAL];
  const indValue = casuals.current.netRevenuePerUser;
  const raw = segment.current.netRevenuePerUser / indValue;
  const weighted = calcWeighted(raw, benchmark, scale);
  return {
    raw,
    weighted,
    name: debugCheerleadersIndValue(
      benchmark,
      raw,
      weighted,
      indValue,
      segment.current.netRevenuePerUserFormatted
    ),
    insights: insightsCheerleadersIndValue(benchmark, raw, weighted),
    optimizations: optimizationsCheerleadersIndValue(benchmark, raw, weighted),
    benchmark: benchmark,
  };
}

export function scoreCheerleaders(
  segment: ISegmentData,
  data: IQuantData,
  brand: any,
  scale: WeightedScaleValue[],
  benchmarks: BenchmarkCheerleaders
) {
  const hasTargetCost =
    brand.dataConfig.prospects.users &&
    brand.dataConfig.prospects.costPer &&
    brand.dataConfig.casuals.targetAquisition;

  const scores = {
    use: true,
    grades: [],
    total: 0,
    totalFormatted: '0',
    insights: [],
    optimizations: [],
  };

  scores.grades = [
    formulasCheerleadersMigrationForward(
      segment,
      data,
      benchmarks.MIGRATION_FORWARD,
      scale
    ),
    formulasCheerleadersCountAsPercentage(
      segment,
      data,
      benchmarks.COUNT_AS_PERCENTAGE,
      scale
    ),
    formulasCheerleadersRevenueAsPercentage(
      segment,
      data,
      benchmarks.REVENUE_AS_PERCENTAGE,
      scale
    ),
    formulasCheerleadersIndValue(segment, data, benchmarks.IND_VALUE, scale),
  ].filter((v) => v !== null);

  const total = scores.grades.reduce(
    (total, score) => total + score.weighted,
    0
  );

  scores.total = total / scores.grades.length;
  scores.totalFormatted = formatters.count.format(scores.total);

  scores.insights = scores.grades.reduce(
    (insights, grade) => [...insights, ...grade.insights],
    []
  );

  scores.optimizations = scores.grades.reduce(
    (optimizations, grade) => [...optimizations, ...grade.optimizations],
    []
  );

  return scores;
}
