import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import SideBar from '../../components/navigation/sidebar';
import TextInput from '../../components/inputs/text';
import { ButtonHollow } from '../../components/buttons';
import useSession from '../../context/session';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import * as store from 'store';

export const LOGIN = gql`
  mutation($input: SetupInput) {
    setup(input: $input)
  }
`;

export const LoginButton = styled(ButtonHollow)`
  width: 100%;
  text-align: center;
  margin: 12px 0;
  padding: 16px;
  font-weight: 700;
`;

interface StakeHolderFindingsScreenProps {
  history?: any;
  token: any;
  user: any;
}

export const Logo = styled.div`
  text-align: center;
`;

export const FormError = styled.div`
  padding: 0.825rem;
  text-align: center;
  background: #de5f5f;
  margin: 0.5rem 0;
  border-radius: 4px;
  color: #fff;
`;

export function StakeHolderFindingsScreen(props: any) {
  const mutation = useMutation(LOGIN);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const { updateSession } = useSession();

  const login = async () => {
    console.log(
      new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ).test(password)
    );
    if (
      !new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ).test(password)
    ) {
      setError(
        'Password must be at least 8 characters, one lowercase letter, one uppercase letter, one number and one special character'
      );
      return;
    } else {
      setError(null);
    }

    if (password !== confirmPassword) {
      setError('Password do not match.');
      return;
    } else {
      setError(null);
    }

    try {
      const response: any = await mutation({
        variables: {
          input: {
            password,
            user: props.match.params.user,
            token: props.match.params.token,
          },
        },
      });

      if (response.data.setup === true) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (e) {
      setError('Could not find user or token is expired.');
    }
  };

  if (success) {
    return (
      <Container>
        <Grid>
          <Grid.Unit size={{ sm: 1 }}>
            <Padding all={2} />
          </Grid.Unit>
        </Grid>
        <Grid>
          <Grid.Unit size={{ sm: 3 / 12 }} />
          <Grid.Unit size={{ sm: 6 / 12 }}>
            <Padding all={2}>
              <Padding all={3}>
                <Logo>
                  <img src="/static/logo.png" />
                </Logo>
              </Padding>
              <Box>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>SETUP</HeadlineTwo>
                      <Divider />

                      <Paragraph>
                        Your account is ready! Login into your account below.
                      </Paragraph>

                      <LoginButton
                        onClick={(e) => props.history.push('/login')}
                      >
                        LOGIN
                      </LoginButton>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Box>
            </Padding>
          </Grid.Unit>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2} />
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 3 / 12 }} />
        <Grid.Unit size={{ sm: 6 / 12 }}>
          <Padding all={2}>
            <Padding all={3}>
              <Logo>
                <img src="/static/logo.png" />
              </Logo>
            </Padding>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={3}>
                    <HeadlineTwo>SETUP</HeadlineTwo>
                    <Divider />
                    {error ? <FormError>{error}</FormError> : null}
                    <Paragraph>Choose a password to get started.</Paragraph>
                    <TextInput
                      type="password"
                      placeholder="Password"
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextInput
                      type="password"
                      placeholder="Confirm Password"
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <LoginButton onClick={login}>SUBMIT</LoginButton>
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export default StakeHolderFindingsScreen;
