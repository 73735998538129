import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadPhoto from '../../components/inputs/upload.photo';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';

export const ADD_BRAND = gql`
  mutation($input: BrandInput) {
    addBrand(input: $input) {
      id
      name
      logo
    }
  }
`;

export function AdminAccount(props) {
  const mutation = useMutation(ADD_BRAND);
  const [name, setName] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const [global, setGlobal] = useGlobal();
  const breadcrumbs = [
    { label: 'Admin', link: `/admin` },
    { label: props.account.name, link: `/admin/account/${props.account.id}` },
    {
      label: 'Add Brand',
    },
  ];

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          input: { name: name, logo, account: props.account.id },
        },
      });

      notify(setGlobal, 'Added Brand', 'success');

      props.history.push(
        `/admin/account/${props.account.id}/brand/${response.data.addBrand.id}`
      );
    } catch (e) {
      console.log(e);
    }
  }

  const buttons = [{ label: 'Save Brand', onClick: save }];

  return (
    <AdminScreen
      headline={'Add Brand'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Brand Name"
                        placeholder="Brand Name"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadPhoto
                        value={logo}
                        onChange={setLogo}
                        label="Brand Logo"
                        placeholder="Brand Name"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export const ACCOUNT = gql`
  query($id: ID) {
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT, {
    variables: { id: props.match.params.account },
  });

  if (loading || !data) return null;
  if (error) return null;

  return <AdminAccount history={props.history} account={data.account} />;
}

export default AdminAccountLoader;
