import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import { getSegment } from '../../data';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { stringToOrderedList } from '../../utils';

import { LinkList, LinkListItem } from '../../components/lists';
import SVGInline from 'react-svg-inline';

export const SegmentTitle = styled(HeadlineThree)``;
export const SegmentText = styled(Paragraph)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  brand: any;
  downloads: any;
}

export function StrategicLibrary(props: DashboardProps) {
  return (
    <Container>
      <SideBar active={'strategic'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <HeadlineOne>DOWNLOADS</HeadlineOne>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={3}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>STAKEHOLDER FINDINGS</HeadlineTwo>
                      <Divider />
                      <Paragraph>
                        The internal point of view regarding the brand, its core
                        customer, marketing and operational functionality, and
                        the perceived stress or opportunity facing the
                        organization holistically.
                      </Paragraph>
                      <LinkList>
                        <LinkListItem>
                          <a
                            target="_blank"
                            style={{ display: 'flex', alignItems: 'center' }}
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.stakeholderFindings}`}>
                            {' '}
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Download
                          </a>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>CONSUMER FINDINGS</HeadlineTwo>
                      <Divider />
                      <Paragraph>
                        The external point of view about why the brand is a
                        meaningful company in the eyes of customers. Key
                        triggers to adoption of branded product or service use
                        as a go to resource.
                      </Paragraph>
                      <LinkList>
                        <LinkListItem>
                          <a
                            style={{ display: 'flex', alignItems: 'center' }}
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.consumerFindings}`}
                            target="_blank">
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Download
                          </a>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>CUSTOMER ACTIVATION CYCLE</HeadlineTwo>
                      <Divider />
                      <Paragraph>
                        Identifies the milestones that lead to long-term loyalty
                        of customers. This is the underpinning of the eventual
                        strategic approach to customer acquisition and
                        retention.
                      </Paragraph>
                      <LinkList>
                        <LinkListItem>
                          <a
                            style={{ display: 'flex', alignItems: 'center' }}
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.customerActivationCycle}`}
                            target="_blank">
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Download
                          </a>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>LOYALTY ARCHITECTURE</HeadlineTwo>
                      <Divider />
                      <Paragraph>
                        The blueprint for communicating the unique value
                        proposition beginning with identifying the core reason
                        for being and linking it to operational opportunity.
                      </Paragraph>
                      <LinkList>
                        <LinkListItem>
                          <a
                            style={{ display: 'flex', alignItems: 'center' }}
                            target="_blank"
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.loyaltyArchitecture}`}>
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Download
                          </a>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>LOYALTY BUSINESS PLAN</HeadlineTwo>
                      <Divider />
                      <Paragraph>
                        A comprehensive, operational and tactical plan for
                        implementation, includes organizational design to go to
                        market— addressing process, systems, people, messaging,
                        and finances.
                      </Paragraph>
                      <LinkList>
                        <LinkListItem>
                          <a
                            style={{ display: 'flex', alignItems: 'center' }}
                            target="_blank"
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.loyaltyBusinessPlan}`}>
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Download
                          </a>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>BRAND PROPAGATION TOOLKIT</HeadlineTwo>
                      <Divider />
                      <Paragraph>
                        The consumer facing visual and emotive representation of
                        the brand’s reason for being and why it matters to its
                        stakeholders: ownership, employees, and most importantly
                        the brand’s constituency—its customers
                      </Paragraph>
                      <LinkList>
                        <LinkListItem>
                          <a
                            style={{ display: 'flex', alignItems: 'center' }}
                            target="_blank"
                            href={`${
                              process.env.REACT_APP_API ||
                              'http://localhost:3000'
                            }/${props.downloads.brandBook}`}>
                            <SVGInline
                              style={{ marginRight: '2px', height: '13px' }}
                              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                            />
                            Download
                          </a>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export const BRAND = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export function StrategicLibraryLoader(props: any) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (error) return null;
  if (!data || loading) return null;

  return (
    <StrategicLibrary
      brand={props.match.params.brand}
      downloads={data.brand.downloads}
    />
  );
}

export default StrategicLibraryLoader;
