import React, { useState } from 'react';
import styled from 'styled-components';
import { Divider } from '../../../../components/decoration/divider';
import { setGlobal, useGlobal } from 'reactn';
import { Loader } from '../../../../components/loader';
import { IQuantData, ISegmentData, SEGMENTS } from '../../../../metrics/enum';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import DateInput from '../../../../components/inputs/date';
import moment from 'moment';
import { useAdhocQuantData } from '../../../../metrics/api';
import { HeadlineTwo } from '../../../../components/typography';
import {
  MigrationOvalLeftKey,
  MigrationOvalLostKey,
  MigrationOvalRetainKey,
  MigrationOvalRightKey,
  MigrationOvalRightSmall,
} from '../migration';
import { getColorForScore } from '../../../../metrics/scoring';

export const SegmentDataArrow = styled.i`
  color: #545454;

  font-size: 12px;
`;

export const SegmentDataArrowContainer = styled.div`
  width: 20px;
  display: inline-block;
`;

export const SegmentDataRow: any = styled.div`
  background-color: ${(props: any) =>
    props.background ? '#f4fafd' : 'transparent'};
` as any;

export const SegmentDataRowLabel = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowLabelBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #666;
  font-weight: 500;
  font-size: 18px;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};

  display: inline-flex;
  align-items: center;
` as any;

export const SegmentDataRowStatContainer = styled.span`
  display: block;

  span {
    margin: 4px 0 4px 8px;
    font-family: proxima-nova, sans-serif;
    color: #545454;
    font-weight: 500;
    font-size: 12px;
    opacity: ${(props: any) => (props.light ? 0.7 : 1)};
    display: inline-block;
  }
` as any;

export const SegmentDataRowValue = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #666;
  font-size: 18px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataRowValueBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9ebf4;
`;

export const PeriodText = styled.span`
  font-size: 12px;
  color: #999999;
`;

export const SegmentValuePanelContainer = styled.div`
  h2 {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 500;
    color: #657c89;
    margin: 16px 0;
  }

  .date-input {
    display: inline-block;
    margin-left: 8px;
  }

  .date-inputs {
    text-align: right;
  }

  .lostsouls {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    background: #000;
    border-radius: 9999%;

    margin-right: 8px;
    color: #fff;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      display: flex;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      align-items: center;
      justify-content: center;
    }
  }

  .prospects {
    width: 22px;
    height: 22px;
    border: 1px solid #38627c;
    display: inline-block;
    border-radius: 9999%;
    margin-right: 8px;
    color: #fff;
    position: relative;
    background: #fff;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .casuals {
    width: 20px;
    height: 20px;
    border: 2px solid #38627c;
    display: inline-flex;
    border-radius: 9999%;
    margin-right: 8px;
    color: #fff;
    position: relative;
    display: inline-block;
    background: #fff;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .stayed {
    width: 20px;
    height: 20px;
    border: 2px solid #71b9ca;
    display: inline-flex;
    border-radius: 9999%;
    margin-right: 8px;
    color: #fff;
    position: relative;
    display: inline-block;
    background: #fff;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .loyalists {
    width: 16px;
    height: 16px;
    border: 4px solid #38627c;
    display: inline-block;
    border-radius: 9999%;

    margin-right: 8px;
    color: #fff;
    position: relative;
    background: #fff;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      display: flex;
      color: #38627c;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .cheerleaders {
    width: 24px;
    height: 24px;
    background: #38627c;
    border-radius: 9999%;

    margin-right: 8px;
    color: #fff;
    position: relative;
    display: inline-block;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      display: flex;
      color: #fff;
      font-size: 10px;
      line-height: 10px;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD'
});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

export const SegmentBACPanelContainer = styled.div`
  h2 {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 500;
    color: #657c89;
    margin: 16px 0;
  }

  .label {
    display: inline-flex;
    width: 12px;
    height: 12px;
    background: #b2d4b1;
    padding: 3px;
    border-radius: 9999%;
    color: #333333;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    i {
      font-size: 12px;
      color: #fff;
    }
  }

  .label-warn {
    background: #b18e94;
  }

  .optimization {
    padding: 8px;

    div {
      display: flex;
    }

    .text {
      font-size: 14px;
      color: #333333;
      display: inline-flex;
    }

    i {
      font-size: 10px;
    }
  }
`;

export const ScoreCircleContainer = styled.div`
  margin-right: 12px;
`;

export const ScoreCircle = styled.div`
  width: 60px;
  height: 60px;
  background: ${(props: any) => props.backgroundColor};
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-size: 20px;
  }
` as any;

export const ScoreCircleText = styled.div`
  color: rgb(155, 155, 155);
  width: 100%;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
` as any;

const formatterScore = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

interface IProps {
  segment: any;
  quantData: IQuantData;
  leader: any;
  term;
}

export function SegmentCompareValueLoaded({
  segment,
  quantData,
  leader,
  term
}: IProps) {

  const [global, setGlobal] = useGlobal();

  const { data: periodData, loading } = useAdhocQuantData({
    base: global[`term_${term}_base`],
    compare: global[`term_${term}_compare`],
  })

  React.useEffect(() => {
    if (!global[`term_${term}_base`]) {
      setGlobal(state => ({
        ...state,
        [`term_${term}_base`]: quantData.lastDataRecordDate
      }))
    }
    if (!global[`term_${term}_compare`]) {
      setGlobal(state => ({
        ...state,
        [`term_${term}_compare`]: moment(quantData.lastDataRecordDate).subtract(3, 'months').format('YYYY-MM-DD')
      }))
    }
  }, [])

  const onChangeDate = (value, type) => {
    setGlobal(state => ({
      ...state,
      [`term_${term}_${type}`]: value
    }))
  }

  if (!periodData || loading) {
    return (
      <SegmentValuePanelContainer>
        <Grid>
          <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
            <Loader />
          </Grid.Unit>
        </Grid>
      </SegmentValuePanelContainer>
    );
  }

  const data = periodData[segment.type];

  const migration = data.migration;
  const movement = data.movement;

  const segmentTotal = data.current.count;
  const segmentTotalLastPeriod = data.previous.count;
  const segmentTotalDifference = segmentTotal - segmentTotalLastPeriod;

  const segmentAdditionIsPositive = segmentTotalDifference > 0;
  const segmentTotalIsPositive = segmentTotalDifference > 0;
  const segmentTotalDifferencePercent =
    segmentTotalDifference / segmentTotalLastPeriod;

  function renderOptimization(optimization, i) {
    return (
      <Grid.Unit key={i} size={{ sm: 1 }}>
        <SegmentDataRow background={i % 2 === 0}>
          <div className="optimization">
            <div className="">
              <span
                className={`label ${optimization.negative ? 'label-warn' : ''}`}
              >
                <i
                  className={`fa ${
                    optimization.negative ? 'fa-exclamation' : 'fa-check'
                  }`}
                ></i>
              </span>
              <span className="text">
                {leader ? optimization.text : optimization.short}
              </span>
            </div>
          </div>
        </SegmentDataRow>
      </Grid.Unit>
    );
  }

  const names = {
    prospects: 'Prospects',
    lostsouls: 'Lost Souls',
    casuals: 'Casuals',
    loyalists: 'Loyalists',
    cheerleaders: 'Cheerleaders',
  };

  const abv = {
    prospects: 'P',
    lostsouls: 'x',
    casuals: 'C',
    loyalists: 'L',
    cheerleaders: 'C',
  };

  function getMigrationOut() {
    switch (segment.type) {
      case SEGMENTS.PROSPECTS: {
        return [
          migration.to.cheerleaders > 0 ? 'cheerleaders' : null,
          migration.to.loyalists > 0 ? 'loyalists' : null,
          migration.to.casuals > 0 ? 'casuals' : null,
          migration.to.prospects > 0 ? 'prospects' : null,
          migration.to.lostsouls > 0 ? 'lostsouls' : null,
        ];
      }

      case SEGMENTS.CASUAL: {
        return [
          migration.to.cheerleaders > 0 ? 'cheerleaders' : null,
          migration.to.loyalists > 0 ? 'loyalists' : null,
          migration.to.casuals > 0 ? 'casuals' : null,
          migration.to.prospects > 0 ? 'prospects' : null,
          migration.to.lostsouls > 0 ? 'lostsouls' : null,
        ];
      }

      case SEGMENTS.LOYALIST: {
        return [
          migration.to.cheerleaders > 0 ? 'cheerleaders' : null,
          migration.to.loyalists > 0 ? 'loyalists' : null,
          migration.to.casuals > 0 ? 'casuals' : null,
          migration.to.prospects > 0 ? 'prospects' : null,
          migration.to.lostsouls > 0 ? 'lostsouls' : null,
        ];
      }

      case SEGMENTS.CHEERLEADER: {
        return [
          migration.to.cheerleaders > 0 ? 'cheerleaders' : null,
          migration.to.loyalists > 0 ? 'loyalists' : null,
          migration.to.casuals > 0 ? 'casuals' : null,
          migration.to.prospects > 0 ? 'prospects' : null,
          migration.to.lostsouls > 0 ? 'lostsouls' : null,
        ];
      }

      case SEGMENTS.LOSTSOULS: {
        return [
          migration.to.cheerleaders > 0 ? 'cheerleaders' : null,
          migration.to.loyalists > 0 ? 'loyalists' : null,
          migration.to.casuals > 0 ? 'casuals' : null,
          migration.to.prospects > 0 ? 'prospects' : null,
          migration.to.lostsouls > 0 ? 'lostsouls' : null,
        ];
      }
    }
  }

  const migrationIn = [
    migration.from.prospects > 0 ? 'prospects' : null,
    migration.from.lostsouls > 0 ? 'lostsouls' : null,
    migration.from.casuals > 0 ? 'casuals' : null,
    migration.from.loyalists > 0 ? 'loyalists' : null,
    migration.from.cheerleaders > 0 ? 'cheerleaders' : null,
  ];

  const migrationOut = getMigrationOut();

  const migratedIn = migrationIn
    .filter((val) => val !== null)
    .map((val, i) => {
      return (
        <SegmentDataRow background={i % 2 === 0}>
          <Grid>
            {leader ? (
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold>
                  <span className={val}>
                    <span>{abv[val]}</span>
                  </span>{' '}
                  <MigrationOvalRightKey />
                  {names[val]}
                </SegmentDataRowLabelBold>
              </Grid.Unit>
            ) : (
              <Grid.Unit style={{ visibility: 'hidden' }} size={{ sm: 1 / 12 }}>
                <SegmentDataRowLabelBold>
                  <span className={val}>
                    <span>{abv[val]}</span>
                  </span>{' '}
                </SegmentDataRowLabelBold>
              </Grid.Unit>
            )}
            <Grid.Unit
              style={{ textAlign: 'right' }}
              size={{ sm: leader ? 6 / 12 : 11 / 12 }}
            >
              <SegmentDataRowValueBold border={i % 2 === 0}>
                {formatterCounts.format(migration.from[val])}
              </SegmentDataRowValueBold>
            </Grid.Unit>
          </Grid>
        </SegmentDataRow>
      );
    });

  function getMigratedOutKey(type) {
    if (type === 'lostsouls') {
      return <MigrationOvalLeftKey />;
    }

    if (movement.migrationBackwardKey === type) {
      return <MigrationOvalLeftKey />;
    }

    if (movement.migrationForwardKey === type) {
      return <MigrationOvalRightKey />;
    }

    return <MigrationOvalRightKey />;
  }

  const migratedOut = migrationOut
    .filter((val) => val !== null)
    .map((val, i) => {
      return (
        <SegmentDataRow background={i % 2 === 0}>
          <Grid>
            {leader ? (
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold>
                  <span className={val}>
                    <span>{abv[val]}</span>
                  </span>{' '}
                  {getMigratedOutKey(val)}
                  {names[val]}
                </SegmentDataRowLabelBold>
              </Grid.Unit>
            ) : (
              <Grid.Unit style={{ visibility: 'hidden' }} size={{ sm: 1 / 12 }}>
                <SegmentDataRowLabelBold>
                  <span className={val}>
                    <span>{abv[val]}</span>
                  </span>{' '}
                </SegmentDataRowLabelBold>
              </Grid.Unit>
            )}
            <Grid.Unit
              style={{ textAlign: 'right' }}
              size={{ sm: leader ? 6 / 12 : 11 / 12 }}
            >
              <SegmentDataRowValueBold border={i % 2 === 0}>
                {formatterCounts.format(migration.to[val])}
              </SegmentDataRowValueBold>
            </Grid.Unit>
          </Grid>
        </SegmentDataRow>
      );
    });

  return (
    <SegmentValuePanelContainer>
      <Grid>
        {leader ? (
          <Grid.Unit size={{ sm: 1, md: 3 / 12 }}>
            {leader ? (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <HeadlineTwo style={{ margin: 0 }}>CAS</HeadlineTwo>
              </div>
            ) : (
              <HeadlineTwo style={{ visibility: 'hidden' }}>CAS</HeadlineTwo>
            )}
          </Grid.Unit>
        ) : null}
        <Grid.Unit size={{ sm: 1, md: leader ? 9 / 12 : 1 }}>
          <div className="date-inputs">
            <div className="date-input">
              <DateInput
                disabled={leader ? true : false}
                hasRelativeOptions={true}
                today={quantData.lastDataRecordDate}
                base={global[`term_${term}_base`]}
                min={quantData.firstDataRecordDate}
                max={quantData.lastDataRecordDate}
                label={'Select Base'}
                inputProps={{
                  value: global[`term_${term}_base`],
                  onChange: (value) => onChangeDate(value, 'base'),
                }}
                options={[]}
                style={{ margin: 0, marginTop: 40 }}
              />
            </div>
            <div className="date-input">
              <DateInput
                hasRelativeOptions={true}
                today={quantData.lastDataRecordDate}
                base={global[`term_${term}_base`]}
                min={quantData.firstDataRecordDate}
                max={quantData.lastDataRecordDate}
                label={'Select Base'}
                inputProps={{
                  value: global[`term_${term}_compare`],
                  onChange: (value) => onChangeDate(value, 'compare'),
                }}
                style={{ margin: 0, marginTop: 40 }}
              />
            </div>
          </div>
        </Grid.Unit>
        <Grid.Unit size={1}>
          <Divider style={{ marginBottom: 0 }} />
        </Grid.Unit>
        <Grid.Unit size={1}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: 12 }}>
              <ScoreCircleContainer>
                <ScoreCircle
                  backgroundColor={getColorForScore(data.scores.total)}
                >
                  <span>{formatterScore.format(data.scores.total)}</span>
                </ScoreCircle>
                <ScoreCircleText>out of 5</ScoreCircleText>
              </ScoreCircleContainer>
            </div>
            <div style={{ minHeight: `${data.scores.insights.length * 36}px` }}>
              <SegmentBACPanelContainer>
                <Grid>{data.scores.insights.map(renderOptimization)}</Grid>
              </SegmentBACPanelContainer>
            </div>
          </div>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          {leader ? (
            <HeadlineTwo>VALUE</HeadlineTwo>
          ) : (
            <HeadlineTwo style={{ visibility: 'hidden' }}>VALUE</HeadlineTwo>
          )}
          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Segment Count
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}

              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {formatterCounts.format(data.current.users)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.userPercentChangeFormatted}</span>
                    <span>{data.growth.userTotalChangeFormatted}</span>
                    <span>
                      from {formatterCounts.format(data.previous.users)}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel light={true}>
                    % of Total Count
                  </SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue border={true} light={true}>
                  {data.current.usersAsPercentageOfTotalFormatted}
                  <SegmentDataRowStatContainer>
                    <span>
                      from {data.previous.usersAsPercentageOfTotalFormatted}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel>2 Year Revenue</SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue border={true}>
                  {formatter.format(data.current.revenue)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.revenuePercentChangeFormatted}</span>
                    <span>{data.growth.revenueTotalChangeFormatted}</span>
                    <span>from {data.previous.revenueFormatted}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel light={true}>
                    % of Total Revenue
                  </SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue light={true} border={true}>
                  {data.current.revenueAsPercentageOfTotalFormatted}

                  <SegmentDataRowStatContainer>
                    <span>
                      from {data.previous.revenueAsPercentageOfTotalFormatted}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel>Marketing Spend</SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue border={true}>
                  {data.current.marketingSpendFormatted}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel light={true}>
                    % of Total Spend
                  </SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue light={true} border={true}>
                  {data.current.marketingSpendPercentOfTotalFormatted}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataDivider />
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Individual Value
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {data.current.averageIndividualValueFormatted}
                  <SegmentDataRowStatContainer>
                    <span>
                      {data.growth.averageIndividualValuePercentChangeFormatted}
                    </span>
                    <span>
                      {data.growth.averageIndividualValueTotalChangeFormatted}
                    </span>
                    <span>
                      from {data.previous.averageIndividualValueFormatted}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>

          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Cost of Goods per
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {data.current.cogsPerUserFormatted}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.cogsPerUserPercentChangeFormatted}</span>
                    <span>{data.growth.cogsPerUserTotalChangeFormatted}</span>
                    <span>from {data.previous.cogsPerUserFormatted}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel>Margin per</SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue border={true}>
                  {data.current.marginPerUserFormatted}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabel>
                    Cost of Marketing per
                  </SegmentDataRowLabel>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValue border={true}>
                  {formatter.format(data.current.costPerUser)}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>

          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Net Individual Value
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {data.current.netRevenuePerUserFormatted}
                  <SegmentDataRowStatContainer>
                    <span>
                      {data.growth.netRevenuePerUserPercentChangeFormatted}
                    </span>
                    <span>
                      {data.growth.netRevenuePerUserTotalChangeFormatted}
                    </span>
                    <span>from {data.previous.netRevenuePerUserFormatted}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataDivider />
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold style={{ fontWeight: 'bold' }}>
                    Net Segment Value
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold
                  style={{ fontWeight: 'bold' }}
                  border={true}
                >
                  {formatter.format(data.current.netRevenue)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.netRevenuePercentChangeFormatted}</span>
                    <span>{data.growth.netRevenueTotalChangeFormatted}</span>
                    <span>from {new Intl.NumberFormat('en-US', {
                      maximumFractionDigits: 2,
                    }).format(data.previous.netRevenue)}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          {leader ? (
            <HeadlineTwo>Migration</HeadlineTwo>
          ) : (
            <HeadlineTwo style={{ visibility: 'hidden' }}>
              Migration
            </HeadlineTwo>
          )}
          <SegmentDataDivider />
          <SegmentDataRow background={true}>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    Segment Count
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}

              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {formatterCounts.format(data.current.users)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.userPercentChangeFormatted}</span>
                    <span>{data.growth.userTotalChangeFormatted}</span>
                    <span>
                      from {formatterCounts.format(data.previous.users)}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataDivider />
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    <MigrationOvalRightKey /> Movement From
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {formatterCounts.format(data.movement.migrationIn)}
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          {migratedIn}
          <SegmentDataDivider />
          <SegmentDataRow background={true}>
            <Grid>
              {leader ? (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    <span className={'stayed'}></span> Retained
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              ) : (
                <Grid.Unit
                  style={{ visibility: 'hidden' }}
                  size={{ sm: 1 / 12 }}
                >
                  <SegmentDataRowLabelBold>
                    <span className={'stayed'}></span>{' '}
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 11 / 12 }}
              >
                <SegmentDataRowValueBold border={false}>
                  {formatterCounts.format(data.movement.stayed)}
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataDivider />
          <SegmentDataRow>
            <Grid>
              {leader && (
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <SegmentDataRowLabelBold>
                    <MigrationOvalRightKey /> Movement To
                  </SegmentDataRowLabelBold>
                </Grid.Unit>
              )}
              <Grid.Unit
                style={{ textAlign: 'right' }}
                size={{ sm: leader ? 6 / 12 : 1 }}
              >
                <SegmentDataRowValueBold border={true}>
                  {formatterCounts.format(data.movement.migrationOut)}
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          {migratedOut}
        </Grid.Unit>
      </Grid>
    </SegmentValuePanelContainer>
  );
}

export function SegmentCompareValuePanel({ segment, leader, term }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (!quantData) {
    return (
      <SegmentValuePanelContainer>
        <Loader />
      </SegmentValuePanelContainer>
    );
  }

  return (
    <SegmentCompareValueLoaded
      quantData={quantData as IQuantData}
      segment={segment}
      leader={leader}
      term={term}
    />
  );
}
