import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import { getSegment } from '../../data';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { stringToOrderedList } from '../../utils';
import {
  drawStoryUniverse,
  handleMouseOut,
  handleMouseOver,
} from './story-universe/renderer/render';
import { LinkList, LinkListItem, LinkListHeader } from '../../components/lists';
import {
  StoryUniverseContainer,
  Overlay,
  NodeInfo,
} from './story-universe/story.universe.screen';
import moment from 'moment';
import { Loader } from '../../components/loader';
import SelectInput from '../../components/inputs/select';
import DateInput from '../../components/inputs/date';
import { HeadlineMigrationPercent } from './dashboard.data';

import { useGlobal } from 'reactn';
import { SegmentMenu, SegmentMenuItem } from './components/segment.menu';
import { SegmentMigrationCountPanel } from './components/panels/segment.migration.panel';
import { SegmentValuePanel } from './components/panels/segment.value.panel';
import { SegmentCompareValuePanel } from './components/panels/segment.compare.panel';
import { SegmentBACPanel } from './components/panels/segment.bac.panel';
import { DataDateInput } from './components/inputs/date';
import { SegmentCAC } from './components/segment.cac';
import { SegmentHealthBar } from './components/segment.health';

export const SegmentTitle = styled(HeadlineThree as any)``;
export const SegmentText = styled(Paragraph as any)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  segment: any;
  brand: any;
  name: any;
  nodes: any;
  tag: any;
}

export const NextSegment = styled.div`
  width: 100%;
  text-align: right;
`;

export const MIGRATION = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
        metricDataCompare(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
      }
      segmentMigrationData(
        type: $type
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

const types = {
  prospect: {
    type: 'SEGMENT_PROSPECTS',
    name: 'PROSPECTS',
    tag: 'prospects',
    abv: 'P',
  },
  casual: {
    type: 'SEGMENT_CASUAL',
    name: 'CASUALS',
    tag: 'casuals',
    abv: 'CA',
  },
  cheerleader: {
    type: 'SEGMENT_CHEERLEADER',
    name: 'CHEERLEADERS',
    tag: 'cheerleaders',
    abv: 'CH',
  },
  cheerleaders: {
    type: 'SEGMENT_CHEERLEADER',
    name: 'CHEERLEADERS',
    tag: 'cheerleaders',
    abv: 'CH',
  },
  loyalist: {
    type: 'SEGMENT_LOYALIST',
    name: 'LOYALISTS',
    tag: 'loyalists',
    abv: 'L',
  },
  lostsouls: {
    type: 'SEGMENT_LOSTSOULS',
    name: 'LOST SOULS',
    tag: 'lostsouls',
    abv: 'X',
  },
};

export function SegmentWithData(props) {
  return (
    <Container>
      <SideBar active={'segments'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <div style={{ marginTop: '2.5rem' }}></div>
        </Grid.Unit>

        <Grid.Unit size={{ sm: 1, md: 1 / 2 }}>
          <Padding all={2}>
            <HeadlineOne dataSection={true}>{props.name}</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 1 / 2 }}>
          <SegmentCAC segment={props.segment} />
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1 }}>
          <SegmentHealthBar segment={props.segment} />
        </Grid.Unit>
      </Grid>

      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <Padding top={3} left={3} right={3}>
                    <SegmentMenu>
                      <SegmentMenuItem
                        href={`/brands/${props.brand}/segment/${props.segmentId}`}
                        active={false}
                        label={'SEGMENT ACTIVATION'}
                      />
                      <SegmentMenuItem
                        href={`/brands/${props.brand}/segment/${props.segmentId}/compare`}
                        active={true}
                        label={'COMPARISONS'}
                      />
                      <SegmentMenuItem
                        href={`/brands/${props.brand}/segment/${props.segmentId}/description`}
                        active={false}
                        label={'DESCRIPTION'}
                      />
                    </SegmentMenu>
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 / 2 }}>
                  <Padding top={3} left={3} right={3}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          visibility: 'hidden',
                        }}
                      >
                        <DataDateInput />
                      </div>
                    </div>
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1 }}>
                  <Divider style={{ marginTop: 8, marginBottom: 4 }} />
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={{ sm: 6 / 12 }}>
                  <Padding all={3}>
                    <SegmentCompareValuePanel
                      leader={true}
                      segment={props.segment}
                      term="one"
                    />
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 3 / 12 }}>
                  <Padding all={3}>
                    <SegmentCompareValuePanel
                      leader={false}
                      segment={props.segment}
                      term="two"
                    />
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 3 / 12 }}>
                  <Padding all={3}>
                    <SegmentCompareValuePanel
                      leader={false}
                      segment={props.segment}
                      term="three"
                    />
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD'
});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

export const SegmentDataArrow = styled.i`
  color: #545454;

  font-size: 12px;
`;

export const SegmentDataArrowContainer = styled.div`
  width: 20px;
  display: inline-block;
`;

export const SegmentDataRow: any = styled.div`
  background-color: ${(props: any) =>
    props.background ? '#f4fafd' : 'transparent'};
` as any;

export const SegmentDataRowLabel = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowLabelBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-weight: 500;
  font-size: 16px;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowValue = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
  border-right: ${(props: any) =>
    props.border ? '1px solid #d9ebf4' : '1px solid transparent'};

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataRowValueBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
  border-right: ${(props: any) =>
    props.border ? '1px solid #d9ebf4' : '1px solid transparent'};

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9ebf4;
`;

export const PeriodText = styled.span`
  font-size: 12px;
  color: #999999;
`;

export const METRICS = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
        metricDataCompare(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
      }
    }
  }
`;

export function makePeriods(term, start = '3-months') {
  const terms = {
    '1-year': 1,
    '2-year': 2,
  };

  const starts = {
    '1-month': [1, 'month'],
    '3-months': [3, 'months'],
    '6-months': [6, 'months'],
    '9-months': [9, 'months'],
    '1-year': [1, 'year'],
  };

  const a = starts[start];

  const periodOneStart = moment()
    .subtract(terms[term], 'years')
    .format('YYYY-MM-DD');

  const periodOneEnd = moment().format('YYYY-MM-DD');

  const periodTwoEnd = moment().subtract(a[0], a[1]).format('YYYY-MM-DD');

  const periodTwoStart = moment()
    .subtract(terms[term], 'years')
    .subtract(a[0], a[1])
    .format('YYYY-MM-DD');

  const periodThreeStart = moment()
    .subtract(terms[term] * 3, 'years')
    .format('YYYY-MM-DD');

  const periodThreeEnd = moment()
    .subtract(terms[term] * 2, 'years')
    .format('YYYY-MM-DD');

  /*const periodTwoStart = '2017-01-01';
  const periodTwoEnd = '2018-12-31';

  const periodOneStart = '2017-04-01';
  const periodOneEnd = '2019-03-01';

  const periodThreeStart = '';
  const periodThreeEnd = '';*/

  return {
    periodOneStart,
    periodOneEnd,
    periodTwoStart,
    periodTwoEnd,
    periodThreeStart,
    periodThreeEnd,
  };
}

export const SegmentMigrationRowLabel = styled.span`
  color: #545454;
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: inline-block;
`;

export const SegmentMigrationRow = styled.div`
  padding: 8px 4px;
  margin-left: 4px;
  color: #545454;
  font-size: 12px;
  line-height: 13px;
  width: 70%;
  text-align: right;
  display: inline-block;
`;

export const SegmentMigrationRowTo = styled(SegmentMigrationRow as any)`
  text-align: left;
  position: relative;
`;

export const SegmentMigrationBadge = styled.span`
  background-color: black;
  border-radius: 9999px;
  font-size: 10px;
  color: #fff;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-left: 8px;
  margin-right: 8px;
`;

export const SegmentMigrationLostSouls = styled(SegmentMigrationBadge as any)`
  width: 24px;
  height: 24px;
`;

export const SegmentMigrationProspects = styled(SegmentMigrationBadge as any)`
  border: 1px solid #509bb0;
  color: #509bb0;
  background: white;
  width: 23px;
  height: 23px;
`;

export const SegmentMigrationCasuals = styled(SegmentMigrationBadge as any)`
  border: 2px solid #509bb0;
  color: #509bb0;
  background: white;
`;

export const SegmentMigrationLoyalists = styled(SegmentMigrationBadge as any)`
  border: 3px solid #509bb0;
  color: #509bb0;
  background: white;
  width: 18px;
  height: 18px;
`;

export const SegmentMigrationCheerleaders = styled(
  SegmentMigrationBadge as any
)`
  background: #509bb0;
  color: #fff;
  width: 24px;
  height: 24px;
`;

export const SegmentCircle = styled.div`
  width: 80px;
  height: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #509bb0;
  color: #509bb0;
  font-size: 24px;
  border-radius: 50%;
`;

export const SegmentCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SegmentMigrationImage = styled.img`
  position: absolute;
  left: -20px;
`;

const MovementFromArrow = styled.img`
  position: absolute;
  right: 16px;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
`;

export const BRAND = gql`
  query ($id: ID, $type: String) {
    brand(id: $id) {
      id
      name
      firstDataRecordDate
      lastDataRecordDate
      segment(type: $type) {
        id
        type
        contents
      }
      dataConfig {
        reportingTerm
      }
      storyUniverse {
        id
        type
        contents
      }
    }
  }
`;

export function SegmentLoader(props: any) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: {
      id: props.match.params.brand,
      type: types[props.match.params.segment].type,
    },
  });

  if (error) return null;
  if (!data || loading) return null;

  /*if (
    props.match.params.segment === 'prospects' ||
    props.match.params.segment === 'prospect'
  ) {
    return (
      <Segment
        brand={props.match.params.brand}
        name={types[props.match.params.segment].name}
        tag={types[props.match.params.segment].tag}
        segment={data.brand.segment}
        nodes={data.brand.storyUniverse.contents}
      />
    );
  }*/

  return (
    <SegmentWithData
      firstDataRecordDate={data.brand.firstDataRecordDate}
      lastDataRecordDate={data.brand.lastDataRecordDate}
      brand={props.match.params.brand}
      brandId={props.match.params.brand}
      segmentId={props.match.params.segment}
      name={types[props.match.params.segment].name}
      tag={types[props.match.params.segment].tag}
      segment={data.brand.segment}
      segmentData={data.brand.segmentData}
      brandData={data.brand}
      nodes={data.brand.storyUniverse.contents}
    />
  );
}

export default SegmentLoader;
