import * as React from 'react';
import { useGlobal } from 'reactn';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import * as store from 'store';
import moment from 'moment';

import AdminScreen from '../../components/admin/containers/screen';
import { HeadlineTwo } from '../../components/typography';
import { Box } from '../../components/containers/box';
import { Divider } from '../../components/decoration/divider';
import notify from '../../utils/notify';

export const StyledInput = styled.select`
  min-width: 150px;
  padding: 12px 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  text-align: right;
  margin-bottom: 10px;

  :focus {
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

function AdminImportData(props: any) {

    // Page header.

    let breadcrumbs = [];

    if (store.get('role') === 'Admin') {
        breadcrumbs = [
            { 
                label: 'Admin', 
                link: `/admin` 
            },
            {
                label: props.account.name,
                link: `/admin/account/${props.account.id}`,
            },
            {   label: 'Brands'   },
            {
                label: props.brand.name,
                link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
            },
            {   label: 'Import Data'   },
        ]
    } else {
        breadcrumbs = [
            {
                label: props.brand.name,
                link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
            },
            {   label: 'Import Data'   },
        ]
    }

    const buttons = [{
        label: 'SAVE',
        onClick: save,
    }]

    // Save.

    const [global, setGlobal] = useGlobal();

    async function save() {
        if (!csv.valid) return notify(setGlobal, 'Sorry, your file is invalid. Please contact us for assistance.', 'error');
        
        const form = new FormData();
        form.append('brand', props.brand.id);
        form.append('file', csv.file);
        form.append('mappings', JSON.stringify(csv.mappings.reduce((map, e) => (map[e.mapping.toLowerCase()] = e.field, map), {})));

        fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://localhost:3000'}/import`, {
            method: 'POST',
            headers: {'Authorization': store.get('token')},
            body: form
        })
        notify(setGlobal, 'Uploading... this may take a few minutes.', 'success');
    }

    // File select and parse.

    const [csv, setCSV] = React.useState({
        file: null,
        fields: [],
        mappings: [
            {mapping: 'Order', field: ''},
            {mapping: 'Customer', field: ''},
            {mapping: 'Date', field: ''},
            {mapping: 'Total', field: ''}
        ],
        valid: false
    })

    React.useEffect(() => {
        const date = csv.fields.find(e => e.header === csv.mappings.find(e => e.mapping === 'Date').field);
        const total = csv.fields.find(e => e.header === csv.mappings.find(e => e.mapping === 'Total').field);
        
        const validations = csv.mappings.every(m => !!m.field)
            && (moment(date.value).isValid() || Date.parse(date.value))
            && !isNaN(total.replace(/[$,a-zA-Z\s]/g, ''))

        if (validations) {
            setCSV(state => ({
                ...state,
                valid: true
            }))
        } else {
            setCSV(state => ({
                ...state,
                valid: false
            }))
        }
    }, [csv.mappings])

    const onChangeFile = (event) => {
        const file = event.target.files[0];
        setCSV(state => ({
            ...state,
            file: file
        }))
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = reader.result as string;
            const rows = data.split('\n', 2);
            const headers = rows[0].split(',');
            const values = rows[1].split(',');
            if (headers.length && values.length) {
                setCSV(state => ({
                    ...state,
                    fields: headers.map((e, i) => ({
                        header: e,
                        value: values[i]
                    }))
                }))
            }
        }
        reader.readAsText(file);
    }

    const onChangeMapping = (event) => {
        const {name, value} = event.target;
        setCSV(state => ({
            ...state,
            mappings: state.mappings.map(e => {
                if (e.mapping === name) {
                    return ({
                        ...e,
                        field: value
                    })
                }
                if (e.mapping !== name && e.field === value) {
                    return ({
                        ...e,
                        field: ''
                    })
                }
                return e;
            })
        }))
    }

    return (
        <AdminScreen
            headline={'IMPORT DATA'}
            breadcrumbs={breadcrumbs}
            buttons={buttons}>
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <Box>
                            <Grid>
                                <Grid.Unit size={{ sm: 1, md: 1/3 }}>
                                    <Padding all={2}>

                                        <Padding all={3}>
                                            <HeadlineTwo>SELECT FILE</HeadlineTwo>
                                            <Divider />
                                            <input 
                                                type="file" 
                                                accept=".csv" 
                                                onChange={event => onChangeFile(event)} />
                                        </Padding>

                                        <Padding all={3}>
                                            <HeadlineTwo>MAP FIELDS</HeadlineTwo>
                                            <Divider />

                                            {csv.mappings.map(m => (
                                                <div>
                                                    <StyledLabel>{m.mapping}</StyledLabel>
                                                    <StyledInput 
                                                        name={m.mapping}
                                                        value={m.field}
                                                        onChange={event => onChangeMapping(event)}>
                                                        <option value="">Select a column</option>
                                                        {csv.fields.map(f => (
                                                            <option value={f.header}>{f.header}</option>
                                                        ))}
                                                    </StyledInput>
                                                </div>
                                            ))}
                                        </Padding>

                                    </Padding>
                                </Grid.Unit>
                            </Grid>
                        </Box>
                    </Padding>
                </Grid.Unit>
            </Grid>
        </AdminScreen>
    )
}

function AdminImportDataLoader(props) {
  const QUERY = gql`
    query($id: ID, $brand: ID) {
      brand(id: $brand) {
        id
        name
      }
      account(id: $id) {
        id
        name
      }
    }`;

  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      id: props.match.params.account,
      brand: props.match.params.brand,
    }
  })

  if (loading || !data || error) return null;

  return (
    <AdminImportData
      history={props.history}
      brand={data.brand}
      account={data.account}/>
  )
}

export default AdminImportDataLoader;