import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import styled from 'styled-components';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';

export const ADD_BRAND = gql`
  mutation($input: BrandInput) {
    addBrand(input: $input) {
      id
    }
  }
`;

interface LadderHeaderProps {}

export const ContainerBlue = styled.div`
  background: #f7fbfd;
`;

export const BlueGridUnit = styled(Grid.Unit)`
  background: #d9ebf4;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

export const LadderHeaderGrid = styled(Grid)`
  margin-bottom: 4px;
`;

export const Heading = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-align: left;
`;

export function LadderHeader(props: LadderHeaderProps) {
  return (
    <LadderHeaderGrid>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={2}>
          <Heading>BRAND</Heading>
        </Padding>
      </BlueGridUnit>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={2}>
          <Heading>BRAND VIEWER</Heading>
        </Padding>
      </BlueGridUnit>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={2}>
          <Heading>BRAND ADMIN</Heading>
        </Padding>
      </BlueGridUnit>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={2}>
          <Heading>BRAND DATA VIEWER</Heading>
        </Padding>
      </BlueGridUnit>
    </LadderHeaderGrid>
  );
}

export const ADD_USER = gql`
  mutation($input: UserInput) {
    addUser(input: $input) {
      id
      permissions {
        brand
        roles
        features
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($input: UserInput, $id: ID) {
    updateUser(id: $id, input: $input) {
      id
      type
      permissions {
        brand
        roles
        features
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation($id: ID) {
    removeUser(id: $id) {
      id
    }
  }
`;

export function AdminAddUser(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(!props.user ? ADD_USER : UPDATE_USER);
  const removeUser = useMutation(REMOVE_USER);
  const [firstName, setFirstName] = React.useState(
    props.user ? props.user.firstName : null
  );
  const [lastName, setLastName] = React.useState(
    props.user ? props.user.lastName : null
  );
  const [password, setPassword] = React.useState(null);
  const [email, setEmail] = React.useState(
    props.user ? props.user.email : null
  );
  const [permissions, setPermissions] = React.useState(
    props.user ? props.user.permissions || [] : []
  );

  const alias = props.user
    ? props.user.type === 'ALIAS'
      ? true
      : false
    : false;
  const isAdmin = store.get('role') === 'Admin';

  const breadcrumbs = [
    { label: 'Admin', link: `/admin` },
    { label: props.account.name, link: `/admin/account/${props.account.id}` },
    {
      label: props.user ? 'User' : 'Add User',
    },
  ];

  if (props.user) {
    breadcrumbs.push({
      label: `${props.user.firstName} ${props.user.lastName}`,
    });
  }

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          id: props.user ? props.user.id : null,
          input: {
            firstName,
            lastName,
            email,
            password,
            permissions: permissions.map((p) => {
              return {
                brand: p.brand,
                roles: p.roles,
                features: p.features,
              };
            }),
            account: props.account.id,
          },
        },
      });

      notify(setGlobal, props.user ? 'Saved User' : 'Added User', 'success');

      if (!props.user) {
        props.history.push(`/admin/account/${props.account.id}`);
      }
    } catch (e) {
      console.log(e);
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  async function remove() {
    try {
      const response: any = await removeUser({
        variables: {
          id: props.user ? props.user.id : null,
        },
      });

      notify(setGlobal, 'Removed User', 'success');

      if (props.user) {
        props.history.push(`/admin/account/${props.account.id}`);
      }
    } catch (e) {
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  const buttons = props.user
    ? [
        { label: 'Delete User', type: 'delete', onClick: remove },
        { label: 'Save User', onClick: save },
      ]
    : [{ label: 'Add User', onClick: save }];

  function setRole(brand, role) {
    console.log('set role');
    setPermissions((permissions) => {
      const index = permissions.findIndex((p) => p.brand === brand);
      if (!permissions[index]) {
        permissions.push({
          brand,
          roles: [role],
          features: [],
        });
      } else {
        if (permissions[index].roles.includes(role)) {
          const bIndex = permissions[index].roles.findIndex((r) => r === role);
          permissions[index].roles = permissions[index].roles.filter(
            (r) => r !== role
          );
        } else {
          permissions[index].roles.push(role);
        }
      }

      return [...permissions];
    });
  }

  function hasRole(brand, role) {
    const index = permissions.findIndex((p) => p.brand === brand);
    if (!permissions[index]) {
      return false;
    } else {
      if (permissions[index].roles.includes(role)) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <AdminScreen
      headline={props.user ? 'Edit User' : 'Add User'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    {isAdmin && alias === true ? (
                      <Padding all={2}>
                        <div style={{ background: '#d9ebf4', padding: 16 }}>
                          <p
                            style={{
                              color: '#657c89',
                              fontWeight: 500,
                              lineHeight: 1,
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            This User has cross account access. The original
                            account can be edited{' '}
                            <a
                              style={{ color: '#657c89', fontWeight: 'bold' }}
                              href={`/admin/account/${props.user.original.account}/user/${props.user.original.id}`}
                            >
                              here
                            </a>
                          </p>
                        </div>
                      </Padding>
                    ) : null}
                    <Padding all={2}>
                      <TextInput
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        label="First Name"
                        placeholder="First Name"
                      />
                    </Padding>
                    <Padding all={2}>
                      <TextInput
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        label="Last Name"
                        placeholder="Last Name"
                      />
                    </Padding>

                    <Padding all={2}>
                      {alias === false ? (
                        <TextInput
                          type={alias ? 'hidden' : 'text'}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          label="Email"
                          placeholder="Email"
                        />
                      ) : null}
                    </Padding>
                    <Padding all={2}>
                      {alias === false ? (
                        <TextInput
                          type={alias ? 'hidden' : 'password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          label="Password"
                          placeholder="Password"
                        />
                      ) : null}
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
          <Padding all={2}>
            <HeadlineTwo>Brand Access</HeadlineTwo>
            <LadderHeader />
            {props.account.brands.map((brand) => {
              return (
                <div key={brand.id}>
                  <Padding top={2} bottom={2}>
                    <Box>
                      <Grid>
                        <Grid.Unit size={{ sm: 3 / 12 }}>
                          <Padding all={3}>{brand.name}</Padding>
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 3 / 12 }}>
                          <Padding all={3}>
                            <input
                              checked={hasRole(brand.id, 'BrandViewer')}
                              onChange={(e) => setRole(brand.id, 'BrandViewer')}
                              type="checkbox"
                            />
                          </Padding>
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 3 / 12 }}>
                          <Padding all={3}>
                            <input
                              type="checkbox"
                              checked={hasRole(brand.id, 'BrandAdmin')}
                              onChange={(e) => setRole(brand.id, 'BrandAdmin')}
                            />
                          </Padding>
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 3 / 12 }}>
                          <Padding all={3}>
                            <input
                              type="checkbox"
                              checked={hasRole(brand.id, 'BrandDataViewer')}
                              onChange={(e) =>
                                setRole(brand.id, 'BrandDataViewer')
                              }
                            />
                          </Padding>
                        </Grid.Unit>
                      </Grid>
                    </Box>
                  </Padding>
                </div>
              );
            })}
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export const ACCOUNT = gql`
  query($id: ID) {
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
      users {
        id
        type
        firstName
        lastName
      }
    }
  }
`;

export const ACCOUNT_AND_USER = gql`
  query($id: ID, $user: ID) {
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
    }

    user(id: $user) {
      id
      firstName
      lastName
      email
      type
      original {
        id
        account
      }
      permissions {
        brand
        roles
        features
      }
    }
  }
`;

export function AdminAddUserLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT, {
    variables: { id: props.match.params.account },
  });

  if (loading || !data) return null;
  if (error) return null;

  return <AdminAddUser history={props.history} account={data.account} />;
}

export function AdminEditUserLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT_AND_USER, {
    variables: {
      id: props.match.params.account,
      user: props.match.params.user,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminAddUser
      history={props.history}
      user={data.user}
      account={data.account}
    />
  );
}

export default AdminAddUserLoader;
