import * as store from 'store';
import { request } from 'graphql-request';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { makePeriodsCompare } from './dates';
import { useGlobal } from 'reactn';

import { GraphQLClient } from 'graphql-request';
import moment from 'moment';
import { SEGMENTS } from './enum';
import {
  scoreProspectSegment,
  scoreCasualSegment,
  scoreLoyalistSegment,
  scoreCheerleaderSegment,
} from './scoring';

import {
  scoreProspectSegment as scoreProspectSegmentTwo,
  scoreCasualSegment as scoreCasualSegmentTwo,
  scoreLoyalistSegment as scoreLoyalistSegmentTwo,
  scoreCheerleaderSegment as scoreCheerleaderSegmentTwo,
} from './scoring/scoring.beta.two';

export const ALL_SEGMENTS = [
  SEGMENTS.PROSPECTS,
  SEGMENTS.CASUAL,
  SEGMENTS.LOYALIST,
  SEGMENTS.CHEERLEADER,
  SEGMENTS.LOSTSOULS,
];

export const SEGMENT_MIGRATION_FORWARD_MAP = {
  [SEGMENTS.PROSPECTS]: 'casuals',
  [SEGMENTS.CASUAL]: 'loyalists',
  [SEGMENTS.LOYALIST]: 'cheerleaders',
  [SEGMENTS.CHEERLEADER]: null,
  [SEGMENTS.LOSTSOULS]: null,
};

export const SEGMENT_MIGRATION_BACKWARD_MAP = {
  [SEGMENTS.PROSPECTS]: null,
  [SEGMENTS.CASUAL]: null,
  [SEGMENTS.LOYALIST]: 'casuals',
  [SEGMENTS.CHEERLEADER]: 'loyalists',
  [SEGMENTS.LOSTSOULS]: null,
};

export const SEGMENT_NAME_MAP = {
  [SEGMENTS.PROSPECTS]: 'prospects',
  [SEGMENTS.CASUAL]: 'casuals',
  [SEGMENTS.LOYALIST]: 'loyalists',
  [SEGMENTS.CHEERLEADER]: 'cheerleaders',
  [SEGMENTS.LOSTSOULS]: 'lostsouls',
};

export const SEGMENT_ABREVIATION_MAP = {
  [SEGMENTS.PROSPECTS]: 'P',
  [SEGMENTS.CASUAL]: 'C',
  [SEGMENTS.LOYALIST]: 'L',
  [SEGMENTS.CHEERLEADER]: 'CH',
  [SEGMENTS.LOSTSOULS]: 'X',
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD'
});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

const formatterScore = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

const API_URL = process.env.REACT_APP_API
  ? `${process.env.REACT_APP_API}/gql`
  : 'http://localhost:3000/gql';

const client = new GraphQLClient(API_URL);

const PROSPECTS = gql`
  query (
    $id: ID
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      loyalist {
        id
        contents
      }
      prospect {
        id
        contents
      }
      cheerleader {
        id
        contents
      }
      casual {
        id
        contents
      }
      dataConfig {
        prospects {
          users
          costPer
        }
      }
      prospectData(
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        enabled
        currentPeriod
        previousPeriod
        change
      }
      segmentMigrationData(
        type: "SEGMENT_PROSPECTS"
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

export const DATA = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
        metricDataCompare(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
        }
      }
      segmentMigrationData(
        type: $type
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

export const DATA_BRAND = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      firstDataRecordDate
      lastDataRecordDate
      dataConfig {
        reportingTerm
        prospects {
          users
          costPer
        }
        casuals {
          targetAquisition
        }
      }
    }
  }
`;

export async function getSegmentData(
  id,
  type,
  periodOneStart,
  periodOneEnd,
  periodTwoStart,
  periodTwoEnd
) {
  const data = await client.request(
    DATA,
    {
      id,
      type,
      periodOneStart,
      periodOneEnd,
      periodTwoStart,
      periodTwoEnd,
    },
    {
      authorization: store.get('token'),
    }
  );

  return data;
}

export async function getProspectData(
  id,
  type,
  periodOneStart,
  periodOneEnd,
  periodTwoStart,
  periodTwoEnd
) {
  const data = await client.request(
    PROSPECTS,
    {
      id,
      periodOneStart,
      periodOneEnd,
      periodTwoStart,
      periodTwoEnd,
    },
    {
      authorization: store.get('token'),
    }
  );

  return data;
}

export async function getBrandData(id: string) {
  const data = await client.request(
    DATA_BRAND,
    {
      id,
    },
    {
      authorization: store.get('token'),
    }
  );

  return data;
}

export function useGetSegmentData(variables) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    getSegmentData(
      variables.brand,
      variables.type,
      variables.periodOneStart,
      variables.periodOneEnd,
      variables.periodTwoStart,
      variables.periodTwoEnd
    ).then((data) => {
      setData(data);
    });
  }, [
    variables.brand,
    variables.type,
    variables.periodOneStart,
    variables.periodOneEnd,
    variables.periodTwoStart,
    variables.periodTwoEnd,
  ]);

  return { loading, data };
}

export function useGetMultiplSegmentsData(variables, segments) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    Promise.all(
      segments.map((segment) =>
        getSegmentData(
          variables.brand,
          segment,
          variables.periodOneStart,
          variables.periodOneEnd,
          variables.periodTwoStart,
          variables.periodTwoEnd
        )
      )
    ).then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [
    variables.brand,
    variables.periodOneStart,
    variables.periodOneEnd,
    variables.periodTwoStart,
    variables.periodTwoEnd,
  ]);

  return { loading, data };
}

export function formatChangePercent(value: number, change: number) {
  return change > 0
    ? `+${formatterPercent.format(value)}`
    : `${formatterPercent.format(value)}`;
}

export function formatPercent(value) {
  return formatterPercent.format(value);
}

export function formatChangeCount(value: number) {
  return value > 0
    ? `+${formatterCounts.format(value)}`
    : `${formatterCounts.format(value)}`;
}

export function formatCount(value) {
  return formatterCounts.format(value);
}

export function formatChangeMoney(value: number) {
  return value > 0
    ? `+${formatter.format(value)}`
    : `${formatter.format(value)}`;
}

export function formatMoney(value) {
  return formatter.format(value);
}

export function makeQuantDataFromSegment(brand, segment, segments, segmentId) {
  const { metricData: currentPeriod, metricDataCompare: previousPeriod } =
    segment.brand.segmentData || { metricData: {}, metricDataCompare: {} };

  const { segmentMigrationData: migrationData } = segment.brand;

  const totalCurrentMarketingSpend = segments.reduce((v, s) => {
    const { metricData: currentPeriod, metricDataCompare: previousPeriod } = s
      .brand.segmentData || { metricData: {}, metricDataCompare: {} };

    return v + (currentPeriod.users || 1) * (currentPeriod.costPerUser || 0);
  }, 0);

  if (segmentId === SEGMENTS.PROSPECTS) {
    currentPeriod.users = segment.brand.dataConfig.prospects.users;
    previousPeriod.users = segment.brand.dataConfig.prospects.users;
    currentPeriod.revenue = 0;

    migrationData.to.casuals =
      segments[1].brand.segmentMigrationData.from.prospects;

    console.log('PROSPECT DATA', segment.brand.prospectData);

    if (segment.brand.prospectData && segment.brand.prospectData.enabled) {
      currentPeriod.users = segment.brand.prospectData.currentPeriod;
      previousPeriod.users = segment.brand.prospectData.previousPeriod;
    }
  }

  const usersPercentChange = currentPeriod.users / previousPeriod.users - 1;
  const usersTotalChange = currentPeriod.users - previousPeriod.users;

  const revenuePercentChange =
    currentPeriod.revenue / previousPeriod.revenue - 1;
  const revenueTotalChange = currentPeriod.revenue - previousPeriod.revenue;

  const averageIndividualValuePercentChange =
    currentPeriod.averageIndividualValue /
      previousPeriod.averageIndividualValue -
    1;

  const averageIndividualValueTotalChange =
    currentPeriod.averageIndividualValue -
    previousPeriod.averageIndividualValue;

  const costPerUserPercentChange =
    currentPeriod.costPerUser / previousPeriod.costPerUser - 1;

  const costPerUserTotalChange =
    currentPeriod.costPerUser - previousPeriod.costPerUser;

  const cogsPerUserPercentChange =
    currentPeriod.cogsPerUser / previousPeriod.cogsPerUser - 1;

  const cogsPerUserTotalChange =
    currentPeriod.cogsPerUser - previousPeriod.cogsPerUser;

  const netRevenuePerUserPercentChange =
    currentPeriod.netRevenuePerUser / previousPeriod.netRevenuePerUser - 1;

  const netRevenuePerUserTotalChange =
    currentPeriod.netRevenuePerUser - previousPeriod.netRevenuePerUser;

  const netRevenuePercentChange =
    currentPeriod.netRevenue / previousPeriod.netRevenue - 1;

  const netRevenueTotalChange =
    currentPeriod.netRevenue - previousPeriod.netRevenue;

  let regressionId = SEGMENT_MIGRATION_BACKWARD_MAP[segmentId];
  let migrationId = SEGMENT_MIGRATION_FORWARD_MAP[segmentId];

  const migrationForward = migrationData.to[migrationId]
    ? migrationData.to[migrationId]
    : null;

  const migrationBackward = regressionId
    ? migrationData.to[regressionId]
    : null;

  const migrationIn = Object.keys(migrationData.from).reduce((val, key) => {
    return val + (migrationData.from[key] || 0);
  }, 0);

  const migrationOut = Object.keys(migrationData.to).reduce((val, key) => {
    return val + (migrationData.to[key] || 0);
  }, 0);

  const stayed = migrationData.stayed[SEGMENT_NAME_MAP[segmentId]]; // currentPeriod.users - (migrationIn + migrationOut);

  return {
    abreviation: SEGMENT_ABREVIATION_MAP[segmentId],
    segment: {
      id: SEGMENT_NAME_MAP[segmentId],
    },
    movement: {
      stayed: stayed,
      migrationForward,
      migrationBackward,
      migrationForwardKey: migrationId,
      migrationBackwardKey: regressionId,
      migrationIn,
      migrationOut,
    },
    growth: {
      usersPercentChange,
      userPercentChangeFormatted: formatChangePercent(
        usersPercentChange,
        usersTotalChange
      ),
      usersTotalChange,
      userTotalChangeFormatted: formatChangeCount(usersTotalChange),

      revenuePercentChange,
      revenuePercentChangeFormatted: formatChangePercent(
        revenuePercentChange,
        revenueTotalChange
      ),
      revenueTotalChange,
      revenueTotalChangeFormatted: formatChangeCount(revenueTotalChange),

      averageIndividualValuePercentChange,
      averageIndividualValuePercentChangeFormatted: formatChangePercent(
        averageIndividualValuePercentChange,
        averageIndividualValueTotalChange
      ),
      averageIndividualValueTotalChange,
      averageIndividualValueTotalChangeFormatted: formatChangeCount(
        averageIndividualValueTotalChange
      ),

      costPerUserPercentChange,
      costPerUserPercentChangeFormatted: formatChangePercent(
        costPerUserPercentChange,
        costPerUserTotalChange
      ),
      costPerUserTotalChange,
      costPerUserTotalChangeFormatted: formatChangeCount(
        costPerUserTotalChange
      ),

      cogsPerUserPercentChange,
      cogsPerUserPercentChangeFormatted: formatChangePercent(
        cogsPerUserPercentChange,
        cogsPerUserTotalChange
      ),
      cogsPerUserTotalChange,
      cogsPerUserTotalChangeFormatted: formatChangeCount(
        cogsPerUserTotalChange
      ),

      netRevenuePerUserPercentChange,
      netRevenuePerUserPercentChangeFormatted: formatChangePercent(
        netRevenuePerUserPercentChange,
        netRevenuePerUserTotalChange
      ),
      netRevenuePerUserTotalChange,
      netRevenuePerUserTotalChangeFormatted: formatChangeCount(
        netRevenuePerUserTotalChange
      ),

      netRevenuePercentChange,
      netRevenuePercentChangeFormatted: formatChangePercent(
        netRevenuePercentChange,
        netRevenueTotalChange
      ),
      netRevenueTotalChange,
      netRevenueTotalChangeFormatted: formatChangeCount(netRevenueTotalChange),
    },
    current: {
      count: currentPeriod.users,
      countFormatted: formatCount(currentPeriod.users),
      countPercentOfTotal: currentPeriod.usersAsPercentageOfTotal,
      countPercentOfTotalFormatted: formatPercent(
        currentPeriod.usersAsPercentageOfTotal
      ),
      users: currentPeriod.users,
      usersFormatted: formatCount(currentPeriod.users),
      usersAsPercentageOfTotal: currentPeriod.usersAsPercentageOfTotal,
      usersAsPercentageOfTotalFormatted: formatPercent(
        currentPeriod.usersAsPercentageOfTotal
      ),
      averageIndividualValue: currentPeriod.averageIndividualValue,
      averageIndividualValueFormatted: formatMoney(
        currentPeriod.averageIndividualValue
      ),
      cogsPerUser: currentPeriod.cogsPerUser,
      cogsPerUserFormatted: formatMoney(currentPeriod.cogsPerUser),
      costPerUser: currentPeriod.costPerUser,
      costPerUserFormatted: formatMoney(currentPeriod.costPerUser),
      marketingSpend: currentPeriod.costPerUser * currentPeriod.users,
      marketingSpendFormatted: formatMoney(
        currentPeriod.costPerUser * currentPeriod.users
      ),

      marketingSpendPercentOfTotal:
        (currentPeriod.costPerUser * currentPeriod.users) /
        totalCurrentMarketingSpend,
      marketingSpendPercentOfTotalFormatted: formatPercent(
        (currentPeriod.costPerUser * currentPeriod.users) /
          totalCurrentMarketingSpend
      ),

      marginPerUser: currentPeriod.marginPerUser,
      marginPerUserFormatted: formatPercent(currentPeriod.marginPerUser),
      netRevenue: currentPeriod.netRevenue,
      netRevenueFormatted: formatMoney(currentPeriod.netRevenue),
      netRevenuePerUser: currentPeriod.netRevenuePerUser,
      netRevenuePerUserFormatted: formatMoney(currentPeriod.netRevenuePerUser),
      revenue: currentPeriod.revenue,
      revenueFormatted: formatMoney(currentPeriod.revenue),
      revenueAsPercentageOfTotal: currentPeriod.revenueAsPercentageOfTotal,
      revenueAsPercentageOfTotalFormatted: formatPercent(
        currentPeriod.revenueAsPercentageOfTotal
      ),
    },
    previous: {
      count: previousPeriod.users,
      countFormatted: formatCount(previousPeriod.users),
      countPercentOfTotal: previousPeriod.usersAsPercentageOfTotal,
      countPercentOfTotalFormatted: formatPercent(
        previousPeriod.usersAsPercentageOfTotal
      ),
      users: previousPeriod.users,
      usersFormatted: formatCount(previousPeriod.users),
      usersAsPercentageOfTotal: previousPeriod.usersAsPercentageOfTotal,
      usersAsPercentageOfTotalFormatted: formatPercent(
        previousPeriod.usersAsPercentageOfTotal
      ),
      averageIndividualValue: previousPeriod.usersAsPercentageOfTotal,
      averageIndividualValueFormatted: formatMoney(
        previousPeriod.averageIndividualValue
      ),
      cogsPerUser: previousPeriod.cogsPerUser,
      cogsPerUserFormatted: formatMoney(previousPeriod.cogsPerUser),
      costPerUser: previousPeriod.costPerUser,
      costPerUserFormatted: formatMoney(previousPeriod.costPerUser),
      marginPerUser: previousPeriod.marginPerUser,
      marginPerUserFormatted: formatPercent(
        currentPeriod.marginPerUserFormatted
      ),
      netRevenue: previousPeriod.netRevenue,
      netRevenueFormatted: formatMoney(previousPeriod.netRevenue),
      netRevenuePerUser: previousPeriod.netRevenuePerUser,
      netRevenuePerUserFormatted: formatMoney(previousPeriod.netRevenuePerUser),
      revenue: previousPeriod.revenue,
      revenueFormatted: formatMoney(previousPeriod.revenue),
      revenueAsPercentageOfTotal: previousPeriod.revenueAsPercentageOfTotal,
      revenueAsPercentageOfTotalFormatted: formatPercent(
        previousPeriod.revenueAsPercentageOfTotal
      ),
    },
    migration: {
      ...migrationData,
    },
  };
}

export function makeQuantDataFromSegments(brand, segments, scoringAlgo) {
  const segmentData = segments.map((segment, i) => {
    return makeQuantDataFromSegment(brand, segment, segments, ALL_SEGMENTS[i]);
  });

  const data = ALL_SEGMENTS.map((name, i) => {
    return {
      name,
      ...(segmentData[i] || {}),
    };
  }).reduce((hash, val) => {
    hash[val.name] = val;
    return hash;
  }, {});

  switch (scoringAlgo) {
    case '1.0': {
      data[SEGMENTS.PROSPECTS].scores = scoreProspectSegment(
        data[SEGMENTS.PROSPECTS],
        data,
        brand
      );

      data[SEGMENTS.CASUAL].scores = scoreCasualSegment(
        data[SEGMENTS.CASUAL],
        data,
        brand
      );

      data[SEGMENTS.LOYALIST].scores = scoreLoyalistSegment(
        data[SEGMENTS.LOYALIST],
        data,
        brand
      );

      data[SEGMENTS.CHEERLEADER].scores = scoreCheerleaderSegment(
        data[SEGMENTS.CHEERLEADER],
        data,
        brand
      );

      break;
    }

    case '1.1': {
      data[SEGMENTS.PROSPECTS].scores = scoreProspectSegmentTwo(
        data[SEGMENTS.PROSPECTS],
        data,
        brand
      );

      data[SEGMENTS.CASUAL].scores = scoreCasualSegmentTwo(
        data[SEGMENTS.CASUAL],
        data,
        brand
      );

      data[SEGMENTS.LOYALIST].scores = scoreLoyalistSegmentTwo(
        data[SEGMENTS.LOYALIST],
        data,
        brand
      );

      data[SEGMENTS.CHEERLEADER].scores = scoreCheerleaderSegmentTwo(
        data[SEGMENTS.CHEERLEADER],
        data,
        brand
      );

      break;
    }
  }

  data[SEGMENTS.LOSTSOULS].scores = {
    insights: [],
    optimizations: [],
    total: 0,
    totalFormatted: 0,
  };

  const allScores =
    data[SEGMENTS.PROSPECTS].scores.total +
    data[SEGMENTS.CASUAL].scores.total +
    data[SEGMENTS.LOYALIST].scores.total +
    data[SEGMENTS.CHEERLEADER].scores.total;

  const divider = data[SEGMENTS.PROSPECTS].scores.use ? 4 : 3;

  const total = allScores / divider;

  return {
    raw: segments,
    scores: { total: total, totalFormatted: formatterScore.format(total) },
    lastDataRecordDate: brand.lastDataRecordDate,
    firstDataRecordDate: brand.firstDataRecordDate,
    ...data,
  };
}

export function useAdhocQuantData(
  { base, compare } = {
    base: null,
    compare: null,
  }
) {
  const [scoringAlgo, setScoringAlgo]: [any, any] = useGlobal('scoring');
  const [loadingData, setLoadingData] = useState(false);
  const [quantData, setQuantData] = useState(null);
  const [brand, setBrand]: [any, any] = useGlobal('brand');

  const dates = brand
    ? makePeriodsCompare(brand.dataConfig.reportingTerm, base, compare)
    : null;

  useEffect(() => {
    if (!brand) return () => {};
    if (!base) return () => {};
    if (!compare) return () => {};

    (async function () {
      const dates = makePeriodsCompare(
        brand.dataConfig.reportingTerm,
        base,
        compare
      );

      setLoadingData(true);
      const data = await Promise.all(
        ALL_SEGMENTS.map((segment) =>
          segment === SEGMENTS.PROSPECTS
            ? getProspectData(
                brand.id,
                segment,
                dates.periodOneStart,
                dates.periodOneEnd,
                dates.periodTwoStart,
                dates.periodTwoEnd
              )
            : getSegmentData(
                brand.id,
                segment,
                dates.periodOneStart,
                dates.periodOneEnd,
                dates.periodTwoStart,
                dates.periodTwoEnd
              )
        )
      );
      setQuantData(makeQuantDataFromSegments(brand, data, scoringAlgo));
      setLoadingData(false);
    })();
  }, [brand, base, compare, scoringAlgo]);

  return { dates, data: quantData, loading: loadingData };
}

export function useLoadQuantData(
  { prefix, comparePrefix, brandId } = {
    prefix: 'term_one',
    comparePrefix: 'term_one',
    brandId: null,
  }
) {
  const [scoringAlgo, setScoringAlgo]: [any, any] = useGlobal('scoring');
  const [brand, setBrand]: [any, any] = useGlobal('brand');
  const [base, setBase] = useGlobal(`${prefix}_base`);
  const [compare, setCompare] = useGlobal(`${prefix}_compare`);
  const [loadingBrand, setLoadingBrand] = useGlobal('loadingBrand');
  const [loadingData, setLoadingData] = useGlobal('quantDataLoading');
  const [quantData, setQuantData] = useGlobal('quantData');

  function setBaseValue(value) {
    setBase(value);
    setCompare(value);
  }

  function setCompareValue(value) {
    setCompare(value);
  }

  const dates = brand
    ? makePeriodsCompare(brand.dataConfig.reportingTerm, base, compare)
    : null;

  useEffect(
    function loadBrand() {
      if (!brand || brand.id !== brandId) {
        setBrand(null);
        setLoadingBrand(true);
        setLoadingData(true);

        (async function () {
          const data = await getBrandData(brandId);

          if (
            !data.brand.firstDataRecordDate ||
            !data.brand.lastDataRecordDate
          ) {
            setBrand(data.brand);
            //  setQuantData(null);
            setLoadingBrand(false);
            setLoadingData(false);
            return;
          }

          setBase(data.brand.lastDataRecordDate);
          setCompareValue(
            moment(data.brand.lastDataRecordDate, 'YYYY-MM-DD')
              .subtract(1, 'year')
              .format('YYYY-MM-DD')
          );
          setBrand(data.brand);
          setLoadingBrand(false);
        })();
      }
    },
    [brandId]
  );

  useEffect(() => {
    if (!brand) return () => {};
    if (!base) return () => {};
    if (!compare) return () => {};

    (async function () {
      const dates = makePeriodsCompare(
        brand.dataConfig.reportingTerm,
        base,
        compare
      );

      setLoadingData(true);
      const data = await Promise.all(
        ALL_SEGMENTS.map((segment) =>
          segment === SEGMENTS.PROSPECTS
            ? getProspectData(
                brandId,
                segment,
                dates.periodOneStart,
                dates.periodOneEnd,
                dates.periodTwoStart,
                dates.periodTwoEnd
              )
            : getSegmentData(
                brandId,
                segment,
                dates.periodOneStart,
                dates.periodOneEnd,
                dates.periodTwoStart,
                dates.periodTwoEnd
              )
        )
      );
      setQuantData(makeQuantDataFromSegments(brand, data, scoringAlgo));
      setLoadingData(false);
    })();
  }, [brand, base, compare, scoringAlgo]);

  return {
    dates,
    data: quantData,
    brandLoading: loadingBrand,
    loading: loadingData || loadingBrand,
  };
}
