import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadPhoto from '../../components/inputs/upload.photo';
import loader from '../../components/loader';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';

export const ADD_BRAND = gql`
  mutation($id: ID, $input: AccountInput) {
    updateAccount(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_ACCOUNT = gql`
  mutation($id: ID) {
    removeAccount(id: $id) {
      id
    }
  }
`;

export const GET_ACCOUNT = gql`
  query($id: ID) {
    account(id: $id) {
      id
      name
    }
  }
`;

export function AdminAccount(props) {
  const mutation = useMutation(ADD_BRAND);
  const removeAccount = useMutation(REMOVE_ACCOUNT);
  const [name, setName] = React.useState(props.account.name);
  const [logo, setLogo] = React.useState(null);
  const [global, setGlobal] = useGlobal();
  const breadcrumbs = [
    { label: 'Admin', link: `/admin` },

    {
      label: 'Edit Account',
    },
  ];

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          id: props.account.id,
          input: { name: name },
        },
      });

      notify(setGlobal, 'Saved Account', 'success');

      //  props.history.push(`/admin/account/${response.data.updateAccount.id}`);
    } catch (e) {
      console.log(e);
    }
  }

  async function remove() {
    try {
      const response: any = await removeAccount({
        variables: {
          id: props.account.id,
        },
      });

      notify(setGlobal, 'Removed Account');

      props.history.push(`/admin`);
    } catch (e) {
      console.log(e);
    }
  }

  const buttons = [
    { label: 'Remove Account', type: 'delete', onClick: remove },
    { label: 'Save Account', onClick: save },
  ];

  return (
    <AdminScreen
      headline={'Edit Account'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Account Name"
                        placeholder="Account Name"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAccountLoader(props) {
  const { data, error, loading } = useQuery(GET_ACCOUNT, {
    variables: { id: props.match.params.account },
  });

  if (!data || loading) return loader;
  if (error) return null;

  return <AdminAccount account={data.account} history={props.history} />;
}

export default AdminAccountLoader;
