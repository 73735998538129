import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadInput from '../../components/inputs/upload.transactions';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';

export const ADD_BRAND = gql`
  mutation($id: ID, $input: BrandInput) {
    updateBrand(id: $id, input: $input) {
      id
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export function AdminAccount(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(ADD_BRAND);
  const [csvData, setCSVData] = React.useState(null);

  const breadcrumbs =
    store.get('role') !== 'Admin'
      ? [
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
          { label: 'Upload Data' },
        ]
      : [
          { label: 'Admin', link: `/admin` },
          {
            label: props.account.name,
            link: `/admin/account/${props.account.id}`,
          },
          { label: 'Brands' },
          {
            label: props.brand.name,
            link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
          },
          { label: 'Upload Data' },
        ];

  const buttons = [];

  function onUpload(value) {
    notify(
      setGlobal,
      'Your Data is Processing. You will recieve an email notification when processing is complete.'
    );
    console.log(value);
  }

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          id: props.brand.id,
          input: {
            downloads: {},
          },
        },
      });

      notify(setGlobal, 'Saved Downloads');
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AdminScreen
      headline={'Downloads'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <UploadInput
                        brand={props.brand.id}
                        onChange={onUpload}
                        label="Transaction Data"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export const ACCOUNT = gql`
  query($id: ID, $brand: ID) {
    brand(id: $brand) {
      id
      name
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT, {
    variables: {
      id: props.match.params.account,
      brand: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminAccount
      history={props.history}
      brand={data.brand}
      account={data.account}
    />
  );
}

export default AdminAccountLoader;
