import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadPhoto from '../../components/inputs/upload.photo';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';

export const ADD_BRAND = gql`
  mutation($id: ID, $input: BrandInput) {
    updateBrand(id: $id, input: $input) {
      id
      name
      logo
    }
  }
`;

export const REMOVE_BRAND = gql`
  mutation($id: ID) {
    removeBrand(id: $id) {
      id
    }
  }
`;

export function AdminAccount(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(ADD_BRAND);
  const removeMutation = useMutation(REMOVE_BRAND);
  const [name, setName] = React.useState(props.brand.name);
  const [logo, setLogo] = React.useState(props.brand.logo);
  const breadcrumbs = [
    { label: 'Admin', link: `/admin` },
    { label: props.account.name, link: `/admin/account/${props.account.id}` },
    { label: 'Brands' },
    {
      label: props.brand.name,
      link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
    },
    { label: 'Edit' },
  ];

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          id: props.brand.id,
          input: { name: name, logo },
        },
      });

      notify(setGlobal, 'Saved Brand', 'success');
    } catch (e) {
      console.log(e);
    }
  }

  async function remove() {
    try {
      const response: any = await removeMutation({
        variables: {
          id: props.brand.id,
        },
      });

      notify(setGlobal, 'Removed Brand', 'success');

      props.history.push(`/admin/account/${props.account.id}`);
    } catch (e) {
      console.log(e);
    }
  }

  const buttons = [
    { label: 'Delete Brand', type: 'delete', onClick: remove },
    { label: 'Save Brand', onClick: save },
  ];

  return (
    <AdminScreen
      headline={'Edit Brand'}
      buttons={buttons}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Brand Name"
                        placeholder="Brand Name"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadPhoto
                        value={logo}
                        onChange={setLogo}
                        label="Brand Logo"
                        placeholder="Brand Name"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export const ACCOUNT = gql`
  query($id: ID, $brand: ID) {
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
    brand(id: $brand) {
      id
      name
      logo
    }
  }
`;

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT, {
    variables: {
      id: props.match.params.account,
      brand: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminAccount
      history={props.history}
      brand={data.brand}
      account={data.account}
    />
  );
}

export default AdminAccountLoader;
