import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';

import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

export const ACCOUNTS = gql`
  query {
    accounts {
      id
      name
    }
  }
`;

export function AdminAccounts(props) {
  const buttons = [{ label: 'Add Account', link: '/admin/add-account' }];

  return (
    <AdminScreen buttons={buttons} headline="Accounts">
      {props.accounts.map((account, index) => {
        return (
          <Row>
            <Link to={`/admin/account/${account.id}`}>{account.name}</Link>
          </Row>
        );
      })}
    </AdminScreen>
  );
}

export function AdminAccountsLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNTS, {
    fetchPolicy: 'network-only'
  });

  if (loading || !data) return null;
  if (error) return null;

  return <AdminAccounts accounts={data.accounts || []} />;
}

export default AdminAccountsLoader;
