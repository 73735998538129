import React, { Component } from 'react';
import styled from 'styled-components';

import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Container } from '../../components/containers/container';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';
import { getBrand } from '../../data';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import * as store from 'store';
import { NavigationCAC } from './cac';

export const NavigationContainer = styled.div`
  padding: 18px 0;
  background: #334450;
  width: 100%;

  h1 {
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }

  a {
    text-decoration: none;
    h1 {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      color: #fff;
      display: inline-block;
      margin-right: 12px;
      font-family: proxima-nova, sans-serif;
      font-weight: 500;
      margin-left: 20px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
`;

export const NavigationRight = styled.div`
  text-align: right;

  ${breakpoint('xs', 'xl')`
    display: none;
  `}
`;

export const Avatar = styled.span`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #509bb0;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`;

export const MobileNavigation = styled.div`
  text-align: right;

  svg {
    margin-top: 8px;
    width: 24px;
    height: 24px;
    fill: #fff;
    stroke: #fff;
    display: inline-block;
  }

  .a {
    stroke: #fff;
  }

  ${breakpoint('xl')`
    display: none;
  `}
`;

export const MobileNavigationOpen = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #334450;
  top: 0;
  left: 0;
  z-index: 9999;
  text-align: left;

  svg {
    width: 18px;
  }

  ul {
    display: block;
    li {
      display: block;
      margin-bottom: 12px;
      margin-left: 0;
      font-family: proxima-nova, sans-serif;
      font-weight: 600;
      font-size: 22px;
      width: 100%;
    }
    ul {
      margin-left: 16px;

      li {
        opacity: 0.8
        font-size: 18px;
      }
    }
  }
`;

export const BRAND = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      name
    }
  }
`;

export function BrandHeadline(props) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: { id: props.brand },
  });
  // const brand = getBrand(props.brand);

  if (error) return null;
  if (loading || !data) return null;

  return <h1>{data.brand ? data.brand.name : null}</h1>;
}

export function NavigationAdmin(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <NavigationContainer>
      <Container>
        <Grid>
          <Grid.Unit size={{ xs: 6 / 12, sm: 6 / 12 }}>
            <Padding all={{ xs: 2 }}>
              {props.brand ? (
                <Link to={`/brands/${props.brand}`}>
                  <BrandHeadline brand={props.brand} />
                </Link>
              ) : null}
            </Padding>
          </Grid.Unit>
          <Grid.Unit size={{ xs: 6 / 12, sm: 6 / 12 }}>
            <NavigationRight>
              <Padding all={2}>
                <ul>
                  <li>
                    <Avatar>
                      <Link
                        to={
                          props.brand
                            ? `/brands/${props.brand}/account`
                            : '/account'
                        }
                      >
                        <span className="initials">
                          {(store.get('firstName') || '').charAt(0)}
                          {(store.get('lastName') || '').charAt(0)}
                        </span>
                      </Link>
                    </Avatar>
                  </li>
                </ul>
              </Padding>
            </NavigationRight>
            <MobileNavigation>
              <Padding all={2}>
                <SVGInline
                  onClick={(e) => setOpen(true)}
                  svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>navigation-menu</title><line class="a" x1="2.25" y1="18.003" x2="21.75" y2="18.003"></line><line class="a" x1="2.25" y1="12.003" x2="21.75" y2="12.003"></line><line class="a" x1="2.25" y1="6.003" x2="21.75" y2="6.003"></line></svg>`}
                />
              </Padding>
              {open ? (
                <MobileNavigationOpen>
                  <Padding all={2}>
                    <div style={{ textAlign: 'right' }}>
                      <Padding all={2}>
                        <SVGInline
                          onClick={(e) => setOpen(false)}
                          svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>close</title><line class="a" x1="0.75" y1="23.249" x2="23.25" y2="0.749"></line><line class="a" x1="23.25" y1="23.249" x2="0.75" y2="0.749"></line></svg>`}
                        />
                      </Padding>
                    </div>
                    <ul>
                      <li>
                        <Link to="/brands">Home</Link>
                      </li>
                      {props.brand ? (
                        <li>
                          <Link to={`/brands/${props.brand}`}>
                            Customer Segments
                          </Link>
                        </li>
                      ) : null}
                      {props.brand ? (
                        <ul>
                          <li>
                            <Link to={`/brands/${props.brand}/`}>
                              Customer Activation Cycle
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/brands/${props.brand}/segment/prospect`}
                            >
                              Prospects
                            </Link>
                          </li>
                          <li>
                            <Link to={`/brands/${props.brand}/segment/casual`}>
                              Casuals
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/brands/${props.brand}/segment/loyalist`}
                            >
                              Loyalists
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/brands/${props.brand}/segment/cheerleader`}
                            >
                              Cheerleaders
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                      {props.brand ? (
                        <li>
                          <Link to={`/brands/${props.brand}/story-universe`}>
                            Story Universe
                          </Link>
                        </li>
                      ) : null}
                      {props.brand ? <li>Strategic Library</li> : null}
                      {props.brand ? (
                        <ul>
                          <li>
                            <Link
                              to={`/brands/${props.brand}/ladders/affinity`}
                            >
                              Affinity Ladder
                            </Link>
                          </li>
                          <li>
                            <Link to={`/brands/${props.brand}/ladders/loyalty`}>
                              Loyalty Ladder
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/brands/${props.brand}/findings/consumer`}
                            >
                              Consumer Findings
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/brands/${props.brand}/findings/stakeholder`}
                            >
                              Stakeholder Findings
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                      <li>
                        <Link
                          to={
                            props.brand
                              ? `/brands/${props.brand}/account`
                              : '/account'
                          }
                        >
                          My Account
                        </Link>
                      </li>
                    </ul>
                  </Padding>
                </MobileNavigationOpen>
              ) : null}
            </MobileNavigation>
          </Grid.Unit>
        </Grid>
      </Container>
    </NavigationContainer>
  );
}

export default NavigationAdmin;
