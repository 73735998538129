import * as React from 'react';
import { Paragraph } from '../../components/typography';
import styled from 'styled-components';

const TCWrapper = styled.div`
  ul {
    li {
      color: #545454;
      font-family: proxima-nova, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }
  }

  ol {
    li {
      color: #545454;
      font-family: proxima-nova, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }
  }
`;

export function TermsAndConditions() {
  return (
    <TCWrapper>
      <Paragraph className="p4">
        <span className="s1">
          <strong>TERMS OF USE</strong>
        </span>
      </Paragraph>
      <Paragraph className="p5">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>Last updated July 29th, 2019</strong>
        </span>
      </Paragraph>
      <Paragraph className="p7">&nbsp;</Paragraph>
      <Paragraph className="p7">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>AGREEMENT TO TERMS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (&ldquo;you&rdquo;)
          and Compass and Nail, Inc. ("<strong>Company</strong>", &ldquo;
          <strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, or
          &ldquo;<strong>our</strong>&rdquo;), concerning your access to and use
          of the https://theplatform.compassandnail.com website as well as any
          other media form, media channel, mobile website or mobile application
          related, linked, or otherwise connected thereto (collectively, the
          &ldquo;Site&rdquo;). You agree that by accessing the Site, you have
          read, understood, and agree to be bound by all of these Terms of Use.
          IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.
        </span>
      </Paragraph>
      <Paragraph className="p12">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          Supplemental terms and conditions or documents that may be posted on
          the Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use at any time and for any reason. We will alert you about any
          changes by updating the &ldquo;Last updated&rdquo; date of these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use, and you waive any right to receive specific notice of each
          such change. It is your responsibility to periodically review these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">of Use to stay informed of updates. </span>
        <span className="s3">You </span>
        <span className="s1">
          will be subject to, and will be deemed to have been made aware of and
          to have accepted, the changes in any revised{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use by your continued use of the Site after the{' '}
        </span>
        <span className="s4">date </span>
        <span className="s1">such revised </span>
        <span className="s2">Terms </span>
        <span className="s1">of Use are posted.</span>
      </Paragraph>
      <Paragraph className="p12">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
        </span>
      </Paragraph>
      <Paragraph className="p14">&nbsp;</Paragraph>
      <Paragraph className="p15">
        <span className="s1">
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or register for the Site.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, data, databases, functionality, methodology,
          processes, software, website designs, audio, video, text, photographs,
          and graphics on the Site (collectively, the &ldquo;Content&rdquo;) and
          the trademarks, service marks, and logos contained therein (the
          &ldquo;Marks&rdquo;)
        </span>{' '}
        <span className="s1">
          are owned or controlled by us or licensed to us, and are protected by
          patent, trade secret, copyright and trademark laws and various other
          intellectual property rights and unfair competition laws of the United
          States (both state and federal laws), foreign jurisdictions, and
          international conventions. The Content and the Marks are provided on
          the Site &ldquo;AS IS&rdquo; for your information and personal use
          only. Except as expressly provided in these Terms of Use, no part of
          the Site and no Content or Marks or processes may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </span>
      </Paragraph>
      <Paragraph className="p18">&nbsp;</Paragraph>
      <Paragraph className="p19">
        <span className="s1">
          The innovative business methods and the data processing and analysis
          methods disclosed on the Site are protected in the pending patent
          application No. 62/850,862.
        </span>
      </Paragraph>
      <Paragraph className="p20">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>USER REPRESENTATIONS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          By using the Site, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to{' '}
        </span>
        <span className="s4">comply </span>
        <span className="s1">with these </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Site through automated or
          non-human means, whether through a bot, script or otherwise; (6) you
          will not use the Site for any illegal or unauthorized purpose; and (7)
          your use of the Site will not violate any applicable law or
          regulation.
        </span>
      </Paragraph>
      <Paragraph className="p12">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </span>
      </Paragraph>
      <Paragraph className="p23">&nbsp;</Paragraph>
      <Paragraph className="p24">
        <span className="s1">
          <strong>USER REGISTRATION</strong>
        </span>
      </Paragraph>
      <Paragraph className="p25">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          You may be required to register with the Site. You agree to keep your
          password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </span>
      </Paragraph>
      <Paragraph className="p23">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>FEES AND PAYMENT</strong>
        </span>
      </Paragraph>
      <Paragraph className="p26">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">We accept the following forms of payment:</span>
      </Paragraph>
      <Paragraph className="p27">&nbsp;</Paragraph>
      <Paragraph className="p28">
        <span className="s1">- By Invoice</span>
      </Paragraph>
      <Paragraph className="p27">&nbsp;</Paragraph>
      <Paragraph className="p29">
        <span className="s3">You </span>
        <span className="s1">
          may be required to purchase or pay a fee to access some of our
          services.{' '}
        </span>
        <span className="s3">You </span>
        <span className="s1">
          agree to provide current, complete, and accurate purchase and account
          information for all purchases made via the Site.{' '}
        </span>
        <span className="s6">You </span>
        <span className="s1">
          further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you{' '}
        </span>
        <span className="s3">as </span>
        <span className="s1">needed.</span> <span className="s1">We</span>{' '}
        <span className="s1">bill</span> <span className="s1">you</span>{' '}
        <span className="s1">through</span> <span className="s1">an</span>{' '}
        <span className="s1">online</span> <span className="s1">billing</span>{' '}
        <span className="s1">account</span> <span className="s1">for</span>{' '}
        <span className="s1">purchases</span> <span className="s1">made</span>{' '}
        <span className="s1">via</span> <span className="s1">the</span>{' '}
        <span className="s1">Site.</span> <span className="s1">Sales</span>{' '}
        <span className="s1">tax</span> <span className="s1">will</span>{' '}
        <span className="s1">be</span>
      </Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          added to the price of purchases as deemed required by us. We may
          change prices at any time. All payments shall be in U.S. dollars.
        </span>
      </Paragraph>
      <Paragraph className="p7">&nbsp;</Paragraph>
      <Paragraph className="p30">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s3">You </span>
        <span className="s1">
          agree to pay all charges or fees at the prices then in effect for your
          purchases, and you authorize us to charge your chosen payment provider
          for any such amounts upon making your purchase. If your purchase is
          subject to recurring charges, then you consent to our charging your
          payment method on a recurring basis without requiring your prior
          approval for each recurring charge, until you notify us of your
          cancellation.
        </span>
      </Paragraph>
      <Paragraph className="p31">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          We reserve the right to correct any errors or mistakes in pricing,
          even if we have already requested or received payment. We also reserve
          the right to refuse any order placed through the Site.
        </span>
      </Paragraph>
      <Paragraph className="p7">&nbsp;</Paragraph>
      <Paragraph className="p32">&nbsp;</Paragraph>
      <Paragraph className="p33">
        <span className="s1">
          <strong>FREE TRIAL</strong>
        </span>
      </Paragraph>
      <Paragraph className="p34">&nbsp;</Paragraph>
      <Paragraph className="p35">
        <span className="s1">
          We offer a 30-day free trial to new users who register with the Site.
          The account will be charged according to the user&rsquo;s chosen
          subscription at the end of the free trial.
        </span>
      </Paragraph>
      <Paragraph className="p23">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>CANCELLATION</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p35">
        <span className="s1">
          All purchases are non-refundable. You can cancel your subscription at
          any time by contacting us using the contact information provided
          below. Your cancellation will take effect at the end of the current
          paid term.
        </span>
      </Paragraph>
      <Paragraph className="p36">&nbsp;</Paragraph>
      <Paragraph className="p37">
        <span className="s1">
          If you<span className="Apple-converted-space">&nbsp; </span>are
          <span className="Apple-converted-space">&nbsp; </span>unsatisfied
          <span className="Apple-converted-space">&nbsp; </span>with
          <span className="Apple-converted-space">&nbsp; </span>our
          <span className="Apple-converted-space">&nbsp; </span>services,
          <span className="Apple-converted-space">&nbsp; </span>please
          <span className="Apple-converted-space">&nbsp; </span>email
          <span className="Apple-converted-space">&nbsp; </span>us
          <span className="Apple-converted-space">&nbsp; </span>at
          <span className="Apple-converted-space">&nbsp; </span>
          <a href="mailto:hello@compassandnail.com">
            <span className="s10">hello@compassandnail.com</span>
          </a>
          <span className="Apple-converted-space">&nbsp; </span>or
          <span className="Apple-converted-space">&nbsp; </span>call
          <span className="Apple-converted-space">&nbsp; </span>us
          <span className="Apple-converted-space">&nbsp; </span>at
        </span>{' '}
        <span className="s1">.</span>
      </Paragraph>
      <Paragraph className="p23">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>PROHIBITED ACTIVITIES</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </span>
      </Paragraph>
      <Paragraph className="p34">&nbsp;</Paragraph>
      <Paragraph className="p38">
        <span className="s1">As a user of the Site, you agree NOT to:</span>
      </Paragraph>
      <ol className="ol1">
        <li className="li39">
          <span className="s1">
            Systematically retrieve data or other content from the Site to
            create or compile, directly or{' '}
          </span>
          <span className="s2">indirectly, </span>
          <span className="s1">
            a collection, compilation, database, or directory without written
            permission from us.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Make any unauthorized use of the Site, including collecting
            usernames and/or email addresses{' '}
          </span>
          <span className="s12">of </span>
          <span className="s1">
            users by electronic or other means for the purpose of sending
            unsolicited email, or creating user accounts by automated means or
            under false pretenses.
          </span>
        </li>
        <li className="li40">
          <span className="s1">
            Use a buying agent or purchasing agent to make purchases on the
            Site.
          </span>
        </li>
        <li className="li41">
          <span className="s1">
            Use the Site to advertise or offer to sell goods and
          </span>{' '}
          <span className="s1">services.</span>
        </li>
        <li className="li39">
          <span className="s1">
            Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use{' '}
          </span>
          <span className="s13">of </span>
          <span className="s1">
            the Site and/or the Content contained therein.
          </span>
        </li>
        <li className="li42">
          <span className="s1">
            Engage in unauthorized framing of or linking to the Site.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive{' '}
          </span>
          <span className="s4">account </span>
          <span className="s1">information such as user passwords.</span>
        </li>
        <li className="li40">
          <span className="s1">
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </span>
        </li>
        <li className="li41">
          <span className="s1">
            Attempt to impersonate another user or person or use the username of
            another
          </span>{' '}
          <span className="s4">user.</span>
        </li>
        <li className="li40">
          <span className="s1">Sell or otherwise transfer your profile.</span>
        </li>
        <li className="li41">
          <span className="s1">
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Use the Site as part of any effort to compete with us or otherwise
            use the Site and/or the{' '}
          </span>
          <span className="s4">Content </span>
          <span className="s1">
            for any revenue-generating endeavor or commercial enterprise.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in{' '}
          </span>
          <span className="s2">any </span>
          <span className="s1">way making up a part of the Site.</span>
        </li>
        <li className="li39">
          <span className="s1">
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site,{' '}
          </span>
          <span className="s12">or </span>
          <span className="s1">any portion of the Site.</span>
        </li>
        <li className="li39">
          <span className="s1">Harass, </span>
          <span className="s4">annoy, </span>
          <span className="s1">
            intimidate, or threaten any of our employees or agents engaged in
            providing{' '}
          </span>
          <span className="s2">any </span>
          <span className="s1">portion of the Site to you.</span>
        </li>
        <li className="li40">
          <span className="s1">
            Delete the copyright or other proprietary rights notice from any
            Content.
          </span>
        </li>
        <li className="li39">
          <span className="s1">
            Copy or adapt the Site&rsquo;s software, including but not limited
            to Flash,{' '}
          </span>
          <span className="s3">PHP, </span>
          <span className="s1">HTML, JavaScript, </span>
          <span className="s3">or </span>
          <span className="s1">other code.</span>
        </li>
        <li className="li43">
          <span className="s1">
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other
          </span>{' '}
          <span className="s1">
            material, including excessive use of capital letters and spamming
            (continuous posting of repetitive text), that interferes with any
            party&rsquo;s uninterrupted use and enjoyment of the Site or
            modifies, impairs,
          </span>{' '}
          <span className="s1">
            disrupts, alters, or interferes with the use, features, functions,
            operation, or maintenance of the Site.
          </span>
        </li>
        <li className="li43">
          <span className="s1">
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web
            bugs, cookies, or other similar devices (sometimes{' '}
          </span>
          <span className="s4">referred </span>
          <span className="s1">
            to as &ldquo;spyware&rdquo; or &ldquo;passive collection
            mechanisms&rdquo; or &ldquo;pcms&rdquo;).
          </span>
        </li>
        <li className="li43">
          <span className="s1">
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat{' '}
          </span>
          <span className="s2">utility, </span>
          <span className="s1">
            scraper, or offline reader that accesses the Site, or using or
            launching any unauthorized script or other software.
          </span>
        </li>
        <li className="li41">
          <span className="s1">
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </span>
        </li>
        <li className="li44">
          <span className="s1">
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </span>
        </li>
      </ol>
      <Paragraph className="p7">&nbsp;</Paragraph>
      <Paragraph className="p45">&nbsp;</Paragraph>
      <Paragraph className="p33">
        <span className="s1">
          <strong>USER GENERATED MATERIALS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p34">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Site and through third-party websites. As such, any Contributions you
          transmit may be treated as non-confidential and non-proprietary. When
          you create or make available any Contributions, you thereby represent
          and warrant that:
        </span>
      </Paragraph>
      <Paragraph className="p36">&nbsp;</Paragraph>
      <ol className="ol1">
        <li className="li43">
          <span className="s1">
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any{' '}
          </span>
          <span className="s2">third </span>
          <span className="s4">party.</span>
        </li>
        <li className="li39">
          <span className="s3">You </span>
          <span className="s1">
            are the creator and owner of or have the necessary licenses, rights,
            consents, releases, and permissions to use and to authorize us, the
            Site, and other users of the Site to use your Contributions{' '}
          </span>
          <span className="s13">in </span>
          <span className="s1">
            any manner contemplated by the Site and these{' '}
          </span>
          <span className="s2">Terms </span>
          <span className="s1">of</span> <span className="s1">Use.</span>
        </li>
        <li className="li43">
          <span className="s3">You </span>
          <span className="s1">
            have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these{' '}
          </span>
          <span className="s2">Terms </span>
          <span className="s1">of</span> <span className="s1">Use.</span>
        </li>
        <li className="li40">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions are not false, inaccurate, or
          </span>{' '}
          <span className="s1">misleading.</span>
        </li>
        <li className="li39">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions are not unsolicited or unauthorized advertising,
            promotional materials,{' '}
          </span>
          <span className="s4">pyramid </span>
          <span className="s1">
            schemes, chain letters, spam, mass mailings, or other forms of
            solicitation.
          </span>
        </li>
        <li className="li39">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions are not obscene, lewd, lascivious,{' '}
          </span>
          <span className="s4">filthy, </span>
          <span className="s1">
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </span>
        </li>
        <li className="li40">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not ridicule, mock, disparage, intimidate, or abuse
          </span>{' '}
          <span className="s1">anyone.</span>
        </li>
        <li className="li39">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not advocate the violent overthrow of any
            government or incite, encourage,{' '}
          </span>
          <span className="s12">or </span>
          <span className="s1">threaten physical harm against</span>{' '}
          <span className="s1">another.</span>
        </li>
        <li className="li41">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not violate any applicable{' '}
          </span>
          <span className="s4">law, </span>
          <span className="s1">regulation, or</span>{' '}
          <span className="s1">rule.</span>
        </li>
        <li className="li40">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not violate the privacy or publicity rights of any
            third
          </span>{' '}
          <span className="s4">party.</span>
        </li>
        <li className="li39">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not contain any material that solicits personal
            information from anyone{' '}
          </span>
          <span className="s4">under </span>
          <span className="s1">
            the age of 18 or exploits people under the age of 18 in a sexual or
            violent
          </span>{' '}
          <span className="s1">manner.</span>
        </li>
        <li className="li39">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not violate any federal or state law concerning
            child pornography,{' '}
          </span>
          <span className="s12">or </span>
          <span className="s1">
            otherwise intended to protect the health or well-being of minors;
          </span>
        </li>
        <li className="li39">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical
          </span>{' '}
          <span className="s1">handicap.</span>
        </li>
        <li className="li46">
          <span className="s2">Your </span>
          <span className="s1">
            Contributions do not otherwise violate, or link to material that
            violates, any provision of{' '}
          </span>
          <span className="s2">these Terms </span>
          <span className="s1">of Use, or any applicable law or</span>{' '}
          <span className="s1">regulation.</span>
        </li>
      </ol>
      <Paragraph className="p14">&nbsp;</Paragraph>
      <Paragraph className="p15">
        <span className="s1">
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </span>
      </Paragraph>
      <Paragraph className="p47">&nbsp;</Paragraph>
      <Paragraph className="p33">
        <span className="s1">
          <strong>CONTRIBUTIONS </strong>
        </span>
      </Paragraph>
      <Paragraph className="p34">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          By posting your Contributions to any part of the Site, your
          Contributions shall become our sole property, subject to the existing
          intellectual property rights and other proprietary rights you already
          own before the your Contributions were created and the rights of any
          third party. We shall own exclusive rights, including all intellectual
          property rights, and shall be entitled to the unrestricted use and
          dissemination of the Contributions that become our sole property for
          any lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you.{' '}
        </span>
      </Paragraph>
      <Paragraph className="p48">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          To the extent that any part of or any right to your Contributions is
          not assignable or assigned to us, you automatically grant, and you
          represent and warrant that you have the right to grant, an
          unrestricted, unlimited, irrevocable, perpetual, non- exclusive,
          transferable, royalty-free, fully-paid, worldwide right, and license
          to host, use, copy, reproduce, disclose, sell, resell, publish,
          broadcast, retitle, archive, store, cache, publicly perform, publicly
          display, reformat, translate, transmit, excerpt (in whole or in part),
          and distribute such Contributions (including, without limitation, your
          image and voice) for any purpose, commercial, advertising, or
          otherwise, and to prepare derivative works of, or incorporate into
          other works, such Contributions, and grant and authorize
        </span>{' '}
        <span className="s1">
          sublicenses of the foregoing. The use and distribution may occur in
          any media formats and through any media channels
        </span>{' '}
        <span className="s1">
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </span>
      </Paragraph>
      <Paragraph className="p31">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          We are not liable for any statements or representations in your
          Contributions provided by you in any area on the Site.{' '}
        </span>
        <span className="s3">You </span>
        <span className="s1">
          are solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </span>
      </Paragraph>
      <Paragraph className="p23">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions.
        </span>
      </Paragraph>
      <Paragraph className="p47">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>SUBMISSIONS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </span>
      </Paragraph>
      <Paragraph className="p49">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>U.S. GOVERNMENT RIGHTS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          Our services are &ldquo;commercial items&rdquo; as defined in Federal
          Acquisition Regulation (&ldquo;FAR&rdquo;) 2.101. If our services are
          acquired by or on behalf of any agency not within the Department of
          Defense (&ldquo;DOD&rdquo;), our services are subject to the terms of
          these Terms of Use in accordance with FAR 12.212 (for computer
          software) and FAR 12.211 (for technical data). If our services are
          acquired by or on behalf of any agency within the Department of
          Defense, our services are subject to the terms of these Terms of Use
          in accordance with Defense Federal Acquisition Regulation
          (&ldquo;DFARS&rdquo;) 227.7202
        </span>
        <span className="s18">‑</span>
        <span className="s1">3. In addition, DFARS 252.227</span>
        <span className="s18">‑</span>
        <span className="s1">
          7015 applies to technical data acquired by the DOD. This U.S.
          Government Rights clause is in lieu of, and supersedes, any other FAR,
          DFARS, or other clause or provision that addresses government rights
          in computer software or technical data under these Terms of Use.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>SITE MANAGEMENT</strong>
        </span>
      </Paragraph>
      <Paragraph className="p50">&nbsp;</Paragraph>
      <Paragraph className="p51">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use; (2) take appropriate legal action against anyone who, in our
          sole discretion, violates the law or these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use, including without limitation, reporting such user to law
          enforcement authorities; (3) in our sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the
          Site in a manner designed to protect our rights and property and to
          facilitate the proper functioning of the Site.
        </span>
      </Paragraph>
      <Paragraph className="p49">&nbsp;</Paragraph>
      <Paragraph className="p24">
        <span className="s1">
          <strong>PRIVACY POLICY</strong>
        </span>
      </Paragraph>
      <Paragraph className="p34">&nbsp;</Paragraph>
      <Paragraph className="p52">
        <span className="s1">
          We care about data privacy and security. Please review our Privacy
          Policy:{' '}
          <a href="https://theplatform.compassandnail.com/privacy">
            <span className="s19">
              https://theplatform.compassandnail.com/privacy
            </span>
          </a>
          . By using the Site, you agree to be bound by our Privacy{' '}
        </span>
        <span className="s4">Policy, </span>
        <span className="s1">which is incorporated into these </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use. Please<span className="Apple-converted-space">&nbsp; </span>be
          advised the Site is hosted in the United States. If you access the
          Site from the European Union, Asia, or any other region of the world
          with laws or other requirements governing personal data collection,
          use, or disclosure that differ from applicable laws in the United
          States, then through your continued use of the
          <span className="Apple-converted-space">&nbsp; </span>Site, you are
          transferring your data to the United States, and you expressly consent
          to have your data transferred to and processed in the United States.
          Further, we do not knowingly accept, request, or solicit information
          from children or knowingly market to children. Therefore, in
          accordance with the U.S. Children&rsquo;s Online Privacy Protection
          Act, if we receive actual knowledge that anyone under the age of 13{' '}
        </span>
        <span className="s2">has </span>
        <span className="s1">
          provided personal information to us without the requisite and
          verifiable parental consent, we will delete{' '}
        </span>
        <span className="s2">that </span>
        <span className="s1">
          information from the Site as quickly as is reasonably
        </span>{' '}
        <span className="s1">practical.</span>
      </Paragraph>
      <Paragraph className="p49">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>TERM AND TERMINATION</strong>
        </span>
      </Paragraph>
      <Paragraph className="p26">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">These </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use shall remain in full force and effect while you use the Site.
          WITHOUT LIMITING{' '}
        </span>
        <span className="s2">ANY </span>
        <span className="s1">
          OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR{' '}
        </span>
        <span className="s4">SOLE</span>{' '}
        <span className="s1">DISCRETION AND WITHOUT NOTICE OR </span>
        <span className="s4">LIABILITY, </span>
        <span className="s1">
          DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING{' '}
        </span>
        <span className="s4">CERTAIN </span>
        <span className="s1">
          IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT{' '}
        </span>
        <span className="s2">LIMITATION</span>{' '}
        <span className="s1">FOR BREACH OF ANY </span>
        <span className="s4">REPRESENTATION, </span>
        <span className="s2">WARRANTY, </span>
        <span className="s1">
          OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE{' '}
        </span>
        <span className="s12">LAW </span>
        <span className="s1">OR REGULATION. WE </span>
        <span className="s3">MAY </span>
        <span className="s1">TERMINATE YOUR USE OR </span>
        <span className="s2">PARTICIPATION </span>
        <span className="s1">
          IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION{' '}
        </span>
        <span className="s2">THAT</span> <span className="s1">YOU POSTED </span>
        <span className="s12">AT </span>
        <span className="s1">
          ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
        </span>
      </Paragraph>
      <Paragraph className="p12">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third{' '}
        </span>
        <span className="s4">party, </span>
        <span className="s1">
          even if you may be acting on behalf of the third{' '}
        </span>
        <span className="s4">party. </span>
        <span className="s1">
          In addition to terminating or suspending your account, we reserve{' '}
        </span>
        <span className="s2">the </span>
        <span className="s1">
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p53">
        <span className="s1">
          <strong>MODIFICATIONS AND INTERRUPTIONS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p48">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time. We will not be liable to
          you or any third party for any modification, price change, suspension,
          or discontinuance of the Site.
        </span>
      </Paragraph>
      <Paragraph className="p14">&nbsp;</Paragraph>
      <Paragraph className="p54">
        <span className="s1">
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,{' '}
        </span>
        <span className="s13">or </span>
        <span className="s1">
          errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you.{' '}
        </span>
        <span className="s3">You </span>
        <span className="s1">
          agree that we have no liability whatsoever for any loss, damage, or
          inconvenience caused by your inability to access or use the Site
          during any downtime or discontinuance of the Site. Nothing in these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use will be construed to obligate us to maintain and support the
          Site or to supply any corrections, updates, or releases in connection
          therewith.
        </span>
      </Paragraph>
      <Paragraph className="p55">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>GOVERNING LAW</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p15">
        <span className="s1">These </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use and your use of the Site are governed by and construed in
          accordance with the{' '}
        </span>
        <span className="s2">laws</span>{' '}
        <span className="s1">
          of the State of California applicable to agreements made and to be
          entirely performed within the
          <span className="Apple-converted-space">&nbsp; </span>
        </span>
        <span className="s4">
          State <span className="Apple-converted-space">&nbsp; &nbsp;</span>
        </span>{' '}
        <span className="s1">
          of California, without regard to its conflict of law
        </span>{' '}
        <span className="s1">principles.</span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p56">
        <span className="s1">
          <strong>DISPUTE RESOLUTION</strong>
        </span>
      </Paragraph>
      <Paragraph className="p34">&nbsp;</Paragraph>
      <Paragraph className="p57">
        <span className="s1">
          <strong>Informal Negotiations</strong>
        </span>
      </Paragraph>
      <Paragraph className="p58">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Terms of Use (each a "Dispute"
          and collectively, the &ldquo;Disputes&rdquo;) brought by either you or
          us (individually, a &ldquo;Party&rdquo; and collectively, the
          &ldquo;Parties&rdquo;), the Parties agree to first attempt to
          negotiate any Dispute (except those Disputes expressly provided below)
          informally for at least thirty (30) days before initiating
          arbitration. Such informal negotiations commence upon written notice
          from one Party to the other Party.
        </span>
      </Paragraph>
      <Paragraph className="p23">&nbsp;</Paragraph>
      <Paragraph className="p59">
        <span className="s1">
          <strong>Binding Arbitration</strong>
        </span>
      </Paragraph>
      <Paragraph className="p60">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          If the Parties are unable to resolve a Dispute through informal
          negotiations, the Dispute (except those Disputes expressly excluded
          below) will be finally and exclusively resolved by binding
          arbitration. YOU UNDERSTAND{' '}
        </span>
        <span className="s2">THAT </span>
        <span className="s1">WITHOUT THIS PROVISION, YOU WOULD </span>
        <span className="s2">HAVE </span>
        <span className="s1">THE RIGHT TO SUE IN</span>{' '}
        <span className="s2">COURT</span>
      </Paragraph>
      <Paragraph className="p21">
        <span className="s1">AND </span>
        <span className="s2">HAVE </span>
        <span className="s1">
          A JURY TRIAL. The arbitration shall be commenced and conducted under
          the Commercial Arbitration Rules of the American Arbitration
          Association ("AAA") and, where appropriate, the{' '}
        </span>
        <span className="s2">AAA&rsquo;s </span>
        <span className="s1">
          Supplementary Procedures for Consumer Related Disputes ("AAA Consumer
          Rules"), both of which are available at the AAA website{' '}
          <a href="http://www.adr.org/">
            <span className="s10">www.adr.org. </span>
          </a>
        </span>
        <span className="s2">Your </span>
        <span className="s1">
          arbitration fees and your share of arbitrator compensation shall be
          governed by the AAA Consumer Rules and, where appropriate, limited by
          the AAA Consumer Rules. The arbitration may be conducted in person,
          through the submission of documents, by phone, or online. The
          arbitrator will make a decision in writing, but need not provide a
          statement of reasons unless requested by either{' '}
        </span>
        <span className="s4">Party. </span>
        <span className="s1">The arbitrator must follow applicable </span>
        <span className="s4">law, </span>
        <span className="s1">
          and any award may be challenged if the arbitrator fails to do so.
          Except where otherwise required by the applicable AAA rules or
          applicable{' '}
        </span>
        <span className="s4">law, </span>
        <span className="s1">
          the
        </span> <span className="s1">arbitration</span>{' '}
        <span className="s1">will</span> <span className="s1">take</span>{' '}
        <span className="s1">place</span> <span className="s1">in</span>{' '}
        <span className="s25">Ventura</span>{' '}
        <span className="s27">County,</span>{' '}
        <span className="s25">California</span>
        <span className="s1">.</span> <span className="s1">Except</span>{' '}
        <span className="s1">as</span> <span className="s1">otherwise</span>{' '}
        <span className="s1">provided</span> <span className="s1">herein,</span>{' '}
        <span className="s1">the</span>{' '}
        <span className="s1">
          Parties may litigate in court to compel arbitration, stay proceedings
          pending arbitration, or to confirm, modify, vacate, or enter judgment
          on the award entered by the arbitrator.
        </span>
      </Paragraph>
      <Paragraph className="p36">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          If for any reason, a Dispute proceeds in court rather than
          arbitration, the Dispute shall be commenced or prosecuted in the state
          and federal courts located in{' '}
        </span>
        <span className="s25">Ventura County, California</span>
        <span className="s1">
          , and the Parties hereby consent to, and waive all defenses of lack of
          personal jurisdiction, and forum non conveniens with respect to venue
          and jurisdiction in such state and federal courts. Application of the
          United Nations Convention on Contracts for the International Sale of
          Goods and the Uniform Computer Information Transaction Act (UCITA) are
          excluded from these Terms of Use.
        </span>
      </Paragraph>
      <Paragraph className="p36">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          In no event shall any Dispute brought by either Party related in any
          way to the Site be commenced more than one (1) years after the cause
          of action arose. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </span>
      </Paragraph>
      <Paragraph className="p31">&nbsp;</Paragraph>
      <Paragraph className="p59">
        <span className="s1">
          <strong>Restrictions</strong>
        </span>
      </Paragraph>
      <Paragraph className="p60">&nbsp;</Paragraph>
      <Paragraph className="p15">
        <span className="s1">
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually.{' '}
        </span>
        <span className="s6">To </span>
        <span className="s1">the full extent permitted by </span>
        <span className="s4">law, </span>
        <span className="s1">
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity{' '}
        </span>
        <span className="s3">on </span>
        <span className="s1">
          behalf of the general public or any other persons.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p59">
        <span className="s1">
          <strong>Exceptions to Informal Negotiations and Arbitration</strong>
        </span>
      </Paragraph>
      <Paragraph className="p60">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations and binding
          arbitration: (a) any Disputes seeking to enforce or protect, or
          concerning the validity of, any of the intellectual property rights of
          a Party; (b) any Dispute related to, or arising from, allegations of
          theft, piracy, invasion of privacy, or unauthorized use; and (c) any
          claim for injunctive relief. If this provision is found to be illegal
          or unenforceable, then neither Party will elect to arbitrate any
          Dispute falling within that portion of this provision found to be
          illegal or unenforceable and such Dispute shall be decided by a court
          of competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </span>
      </Paragraph>
      <Paragraph className="p49">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>CORRECTIONS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p26">&nbsp;</Paragraph>
      <Paragraph className="p15">
        <span className="s1">
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p53">
        <span className="s1">
          <strong>DISCLAIMER</strong>
        </span>
      </Paragraph>
      <Paragraph className="p17">
        <span className="s1">THE SITE IS PROVIDED ON AN AS-IS AND </span>
        <span className="s4">AS-AVAILABLE </span>
        <span className="s1">BASIS. YOU AGREE </span>
        <span className="s2">THAT </span>
        <span className="s1">
          YOUR USE OF THE SITE AND OUR SERVICES WILL BE{' '}
        </span>
        <span className="s12">AT </span>
        <span className="s1">
          YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY{' '}
        </span>
        <span className="s2">LAW, </span>
        <span className="s1">
          WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
          SITE AND YOUR USE{' '}
        </span>
        <span className="s4">THEREOF, </span>
        <span className="s1">INCLUDING, WITHOUT </span>
        <span className="s4">LIMITATION, </span>
        <span className="s1">THE IMPLIED WARRANTIES OF </span>
        <span className="s4">MERCHANTABILITY, </span>
        <span className="s1">
          FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE MAKE NO
          WARRANTIES OR{' '}
        </span>
        <span className="s4">REPRESENTATIONS </span>
        <span className="s1">
          ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE
          CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
          LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY{' '}
        </span>
        <span className="s4">NATURE </span>
        <span className="s1">
          WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY
          UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR{' '}
        </span>
        <span className="s2">ANY </span>
        <span className="s1">
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4){' '}
        </span>
        <span className="s2">ANY </span>
        <span className="s1">
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY
          BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH{' '}
        </span>
        <span className="s3">MAY </span>
        <span className="s1">
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD{' '}
        </span>
        <span className="s12">PARTY, </span>
        <span className="s1">
          AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
          ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A{' '}
        </span>
        <span className="s4">RESULT </span>
        <span className="s1">
          OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE{' '}
        </span>
        <span className="s2">AVAILABLE </span>
        <span className="s1">VIA THE SITE. </span>
        <span className="s3">WE </span>
        <span className="s1">DO NOT </span>
        <span className="s2">WARRANT, </span>
        <span className="s1">
          ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
          SERVICE ADVERTISED OR OFFERED BY A THIRD{' '}
        </span>
        <span className="s2">PARTY</span>{' '}
        <span className="s1">
          THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
          APPLICATION FEATURED IN ANY
        </span>{' '}
        <span className="s1">
          BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A{' '}
        </span>
        <span className="s2">PARTY </span>
        <span className="s1">TO OR IN ANY </span>
        <span className="s12">WAY </span>
        <span className="s1">
          BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF
          A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
          SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
        </span>{' '}
        <span className="s1">APPROPRIATE.</span>
      </Paragraph>
      <Paragraph className="p61">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>LIMITATIONS OF LIABILITY</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU{' '}
        </span>
        <span className="s12">OR </span>
        <span className="s1">ANY THIRD </span>
        <span className="s2">PARTY </span>
        <span className="s1">FOR ANY </span>
        <span className="s2">DIRECT, </span>
        <span className="s4">INDIRECT, </span>
        <span className="s1">CONSEQUENTIAL, </span>
        <span className="s2">EXEMPLARY, </span>
        <span className="s1">
          INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST{' '}
        </span>
        <span className="s2">PROFIT, </span>
        <span className="s1">LOST REVENUE, LOSS OF </span>
        <span className="s3">DATA, </span>
        <span className="s1">
          OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE{' '}
        </span>
        <span className="s2">HAVE </span>
        <span className="s1">
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
          ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR
          ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL{' '}
        </span>
        <span className="s12">AT </span>
        <span className="s1">
          ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT{' '}
        </span>
        <span className="s2">PAID, </span>
        <span className="s1">IF </span>
        <span className="s3">ANY, </span>
        <span className="s1">
          BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO ANY CAUSE OF
          ACTION ARISING OR $10,000.00 USD.{' '}
        </span>
        <span className="s4">CERTAIN </span>
        <span className="s3">STATE </span>
        <span className="s1">LAWS DO NOT ALLOW </span>
        <span className="s4">LIMITATIONS </span>
        <span className="s1">ON IMPLIED WARRANTIES OR THE EXCLUSION OR </span>
        <span className="s2">LIMITATION </span>
        <span className="s1">OF </span>
        <span className="s4">CERTAIN </span>
        <span className="s1">DAMAGES. IF THESE LAWS </span>
        <span className="s2">APPLY </span>
        <span className="s1">
          TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR{' '}
        </span>
        <span className="s4">LIMITATIONS </span>
        <span className="s3">MAY </span>
        <span className="s1">NOT </span>
        <span className="s2">APPLY </span>
        <span className="s1">TO YOU, AND YOU </span>
        <span className="s3">MAY </span>
        <span className="s2">HAVE </span>
        <span className="s1">ADDITIONAL RIGHTS.</span>
      </Paragraph>
      <Paragraph className="p62">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>INDEMNIFICATION</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p63">
        <span className="s1">
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or
        </span>{' '}
        <span className="s1">
          demand, including reasonable attorneys&rsquo; fees and expenses, made
          by any third party due to or arising out of: (1) your Contributions;
          (2) your use of the Site; (3) your breach of these Terms of Use; (4)
          any breach of your representations and warranties set forth in these
          Terms of Use; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or (6) any
          overt harmful act of you toward any other user of the Site with whom
          you connected via the Site. Notwithstanding the foregoing, we reserve
          the right, at your expense, to assume the exclusive defense and
          control of any matter for which you are required to indemnify us, and
          you agree to cooperate, at your expense, with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>USER DATA</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p54">
        <span className="s1">
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance{' '}
        </span>
        <span className="s3">of </span>
        <span className="s1">
          the Site, as well as data relating to your use of the Site. Although
          we perform regular routine backups{' '}
        </span>
        <span className="s3">of </span>
        <span className="s1">
          data, you are solely responsible for all data that you transmit or
          that relates to any activity you{' '}
        </span>
        <span className="s4">have</span>{' '}
        <span className="s1">undertaken using the Site. </span>
        <span className="s3">You </span>
        <span className="s1">
          agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption{' '}
        </span>
        <span className="s3">of </span>
        <span className="s1">such data.</span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND</strong>
        </span>{' '}
        <span className="s4">
          <strong>SIGNATURES</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications.{' '}
        </span>
        <span className="s3">You </span>
        <span className="s1">
          consent to receive electronic communications, and you agree that all
          agreements, notices, disclosures,{' '}
        </span>
        <span className="s3">and </span>
        <span className="s1">
          other communications we provide to you electronically, via email and
          on the Site, satisfy any legal requirement that such communication be
          in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
          CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
        </span>{' '}
        <span className="s1">DELIVERY</span> <span className="s1">OF</span>{' '}
        <span className="s1">NOTICES,</span>{' '}
        <span className="s1">POLICIES,</span> <span className="s1">AND</span>{' '}
        <span className="s1">RECORDS</span> <span className="s1">OF</span>{' '}
        <span className="s1">TRANSACTIONS</span>{' '}
        <span className="s1">INITIATED</span>
      </Paragraph>
      <Paragraph className="p15">
        <span className="s1">
          OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or
          requirements under any statutes, regulations, rules, ordinances, or
          other laws in any jurisdiction which require an original signature or
          delivery or retention of non-electronic records, or to payments or the
          granting of credits by any means other than electronic means.
        </span>
      </Paragraph>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>CALIFORNIA USERS AND RESIDENTS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p26">&nbsp;</Paragraph>
      <Paragraph className="p11">
        <span className="s1">
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-
        </span>
      </Paragraph>
      <Paragraph className="p17">
        <span className="s1">1254.</span>
      </Paragraph>
      <Paragraph className="p64">&nbsp;</Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>MISCELLANEOUS</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p17">
        <span className="s1">
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to the Site constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Terms of Use shall not operate as a
          waiver of such right or provision. These
        </span>{' '}
        <span className="s2">Terms </span>
        <span className="s1">
          of Use operate to the fullest extent permissible by{' '}
        </span>
        <span className="s4">law. </span>
        <span className="s1">
          We may assign any or all of our rights and obligations to others at
          any time. We shall not be responsible or liable for any loss, damage,{' '}
        </span>
        <span className="s4">delay, </span>
        <span className="s1">
          or failure to act caused by any cause beyond our reasonable control.
          If any provision or part of a provision of these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use is determined to be unlawful, void, or unenforceable, that
          provision or part of the provision is deemed severable from these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use and does not affect the validity and enforceability of any
          remaining provisions. There is no joint venture, partnership,
          employment or agency relationship created between you and us as a
          result of these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">of Use or use of the Site. </span>
        <span className="s3">You </span>
        <span className="s1">agree that these </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use will not be construed against us by virtue of having drafted
          them.{' '}
        </span>
        <span className="s3">You </span>
        <span className="s1">
          hereby waive any and all defenses you may have based on the electronic
          form of these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">
          of Use and the lack of signing by the parties hereto to execute these{' '}
        </span>
        <span className="s2">Terms </span>
        <span className="s1">of</span> <span className="s1">Use.</span>
      </Paragraph>
      <Paragraph className="p65">&nbsp;</Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>CONTACT US</strong>
        </span>
      </Paragraph>
      <Paragraph className="p10">&nbsp;</Paragraph>
      <Paragraph className="p35">
        <span className="s1">
          In order to resolve a complaint regarding the Site or to receive
          further information regarding use of the Site, please contact us at:
        </span>
      </Paragraph>
      <Paragraph className="p14">&nbsp;</Paragraph>
      <Paragraph className="p66">
        <span className="s1">
          <strong>Compass and Nail, Inc.</strong>
        </span>
      </Paragraph>
      <Paragraph className="p38">
        <span className="s1">
          <strong>PO Box 1114</strong>
        </span>
      </Paragraph>
      <Paragraph className="p66">
        <span className="s1">
          <strong>Ojai, CA 93023</strong>
        </span>
      </Paragraph>
      <Paragraph className="p67">
        <span className="s1">
          <strong>United States </strong>
        </span>
      </Paragraph>
      <Paragraph className="p68">
        <span className="s34">
          <strong>EMail:</strong>
        </span>
        <span className="s35">
          {' '}
          <a href="mailto:hello@compassandnail.com">
            <span className="s36">
              <strong>hello@compassandnail.com</strong>
            </span>
          </a>
        </span>
      </Paragraph>
      <Paragraph className="p69">&nbsp;</Paragraph>
      <Paragraph className="p69">&nbsp;</Paragraph>
      <Paragraph className="p69">&nbsp;</Paragraph>
      <Paragraph className="p69">&nbsp;</Paragraph>
      <Paragraph className="p69">&nbsp;</Paragraph>
      <Paragraph className="p70">&nbsp;</Paragraph>
      <Paragraph className="p7">&nbsp;</Paragraph>
    </TCWrapper>
  );
}

export function PrivacyPolicy() {
  return (
    <TCWrapper>
      <Paragraph className="p1">
        <span className="s1">
          <strong>PRIVACY POLICY</strong>
        </span>
      </Paragraph>
      <Paragraph className="p5">&nbsp;</Paragraph>
      <Paragraph className="p2">
        <span className="s1">
          <strong>Last updated&nbsp;July 14, 2019&nbsp;</strong>
        </span>
        <span className="s2">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p5">&nbsp;</Paragraph>
      <Paragraph className="p5">&nbsp;</Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          Thank you for choosing to be part of our community at&nbsp;Compass and
          Nail, Inc.&nbsp;(&ldquo;<strong>Company</strong>&rdquo;, &ldquo;
          <strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, or
          &ldquo;<strong>our</strong>&rdquo;). We are committed to protecting
          your Personal Information and your right to privacy. If you have any
          questions or concerns about our policy, or our practices with regards
          to your Personal Information, please contact us at&nbsp;
          <a href="mailto:hello@compassandnail.com">
            <span className="s3">hello@compassandnail.com</span>
          </a>
          . Throughout this privacy policy, &ldquo;Personal Information&rdquo;
          means any information that identifies, relates to, describes, is
          capable of being associated with, or could reasonably be linked with
          an individual or household.
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          When you visit our&nbsp;website&nbsp;or our platform at{' '}
          <a href="https://theplatform.compassandnail.com">
            <span className="s3">theplatform.compassandnail.com</span>
          </a>
          ,&nbsp;and use our services, you trust us with your Personal
          Information. We take your privacy very seriously. In this privacy
          notice, we describe our privacy policy. We seek to explain to you in
          the clearest way possible what Personal Information we collect, how we
          use it and what rights you have in relation to it. We hope you take
          some time to read through it carefully, as it is important. If there
          are any terms in this privacy policy that you do not agree with,
          please discontinue use of our&nbsp;Sites&nbsp;and our services.
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          This privacy policy applies to all Personal Information collected
          through our website&nbsp;and out platform (such
          as&nbsp;theplatform.compassandnail.com),&nbsp;and/or any related
          Service Provider (defined in Section 1 below) services, sales,
          marketing or events (we refer to them collectively in this privacy
          policy as the "<strong>Sites</strong>").&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your Personal Information with
            us.&nbsp;
          </strong>
          &nbsp;
        </span>
      </Paragraph>
      <br />
      <br />
      <Paragraph className="p4">
        <span className="s1">
          <strong>TABLE OF CONTENTS&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s4">1</span>
        <span className="s5">. WHAT INFORMATION DO WE COLLECT?</span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">2. HOW DO WE USE YOUR INFORMATION?</span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">
          3. WILL YOUR PERSONAL INFORMATION BE SHARED WITH ANYONE?
        </span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">4. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">
          5. HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">
          6. DO WE COLLECT INFORMATION FROM MINORS?&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">7. WHAT ARE YOUR PRIVACY RIGHTS?</span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">8. CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">
          9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">10. DO WE MAKE UPDATES TO THIS POLICY?</span>
      </Paragraph>
      <Paragraph className="p5">
        <span className="s5">
          11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        </span>
      </Paragraph>
      <br />
      <br />
      <Paragraph className="p4">
        <span className="s1">
          <strong>1. WHAT INFORMATION DO WE COLLECT?&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          &nbsp;<strong>Information automatically collected</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:</em>&nbsp;
          </strong>
          <em>
            Some information &ndash; such as IP address and/or browser and
            device characteristics &ndash; is collected automatically when you
            visit our&nbsp;Sites.
          </em>
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          We automatically collect certain information when you visit, use or
          navigate the&nbsp;Sites that identifies, relates to, describes, is
          capable of being associated with, or could reasonably be linked with
          you or your household. This information may not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our&nbsp;Sites&nbsp;and other technical information.
          This information is primarily needed to maintain the security and
          operation of our&nbsp;Sites, and for our internal analytics and
          reporting purposes.
        </span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p7">
        <span className="s1">
          The technologies we use for this automatic data collection may
          include:
        </span>
      </Paragraph>
      <ul className="ul1">
        <li className="li8">
          <span className="s8">
            <strong>Cookies (or browser cookies).</strong>
            &nbsp;&ldquo;Cookies&rdquo; are small text files that are placed on
            your device by a Web server when you access our Sites. We may use
            both session Cookies and persistent Cookies to tell us how and when
            you interact with the Sites. We may also use Cookies to monitor
            aggregate usage and web traffic routing on our Sites and to
            customize and improve the Sites. Unlike persistent Cookies, session
            Cookies are deleted when you navigate away from the Sites and close
            your browser. Although most browsers automatically accept Cookies,
            you can change your browser options to stop automatically accepting
            Cookies or to prompt you before accepting Cookies. Please note,
            however, that if you don&rsquo;t accept Cookies, you may not be able
            to access all portions or features of the Sites. Some Service
            Providers that we engage (including advertisers) may also place
            their own Cookies on your device. Note that this Privacy Policy
            covers only our use of Cookies and does not include use of Cookies
            by any Third Party or Service Provider you visit directly, both
            which should have their own privacy policies.
          </span>
        </li>
        <li className="li8">
          <span className="s8">
            <strong>Flash Cookies.</strong>&nbsp;Certain features of our Sites
            may use local stored objects (or Flash cookies) to collect and store
            information about your preferences and navigation to, from, and on
            our Sites. Flash cookies are not managed by the same browser
            settings as are used for browser cookies.{' '}
          </span>
        </li>
        <li className="li8">
          <span className="s8">
            <strong>Web Beacons.</strong>&nbsp;Pages of our Sites and our
            e-mails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related Sites
            statistics (for example, recording the popularity of certain Sites
            content and verifying system and server integrity).
          </span>
        </li>
      </ul>
      <Paragraph className="p9">
        <span className="s1">
          <strong>Information collected from other sources</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            We may collect limited data from public databases, marketing
            partners,&nbsp;and other outside sources.&nbsp;
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p7">
        <span className="s9">
          We may obtain Personal Information about you from other sources, such
          as public databases and other third parties.{' '}
        </span>
        <span className="s1">
          Such third parties are neither part of our Company nor a service
          provider with whom we have a contractual relationship (&ldquo;
          <strong>Third Party</strong>&rdquo;).
        </span>
        <span className="s9">&nbsp;</span>
        <span className="s1">
          All Personal Information that you provide on or to such Third Party or
          that is collected by a Third Party is provided directly to the
          controller, owner or operator of the Third Party and is subject to the
          owner&rsquo;s or operator&rsquo;s privacy policy. We do not monitor or
          control any data you provide to Third Parties. To that end, we are not
          responsible for the content, privacy or security practices of a Third
          Party. To assure the protection of your Personal Information, we
          recommend that you carefully review the privacy policies of any Third
          Party you access. You assume the risk of providing any information you
          provide to a Third Party.{' '}
        </span>
      </Paragraph>
      <Paragraph className="p10">
        <span className="s1">
          Other than Third Party services as noted above, we do share your
          Personal Information with certain Service Providers, who are defined
          as companies or organizations with whom we have a contractual
          relationship in connection with fulfilling our services to you
          (&ldquo;Service Providers&rdquo;). Any Personal Information collected
          may be shared with our Service Providers.
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          Examples of the information we receive from Third Parties or Service
          Providers include: social media profile information; marketing leads
          and search results and links, including paid listings (such as
          sponsored links).
        </span>
      </Paragraph>
      <Paragraph className="p11">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>2. HOW DO WE USE YOUR INFORMATION?&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            We process your Personal Information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          We use Personal Information collected via our&nbsp;Sites&nbsp;for a
          variety of business purposes described below. We process your Personal
          Information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.&nbsp;
        </span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p12">
        <span className="s1">
          We use the information we collect or receive as follows:&nbsp;
        </span>
        <span className="s6">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          <strong>For other Business Purposes.</strong>&nbsp;We may use your
          Personal Information for other Business Purposes, which includes data
          analysis, identifying usage trends, determining the effectiveness of
          our promotional campaigns and to evaluate and improve our&nbsp;Sites,
          products, services, marketing and your experience (collectively
          &ldquo;Business Purposes&rdquo;).&nbsp;&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p14">&nbsp;</Paragraph>
      <Paragraph className="p7">
        <span className="s1">
          <strong>International Transfers.</strong> Your Personal Information
          may be transferred to, and maintained on, computers located outside of
          your state, province, country or other governmental jurisdiction where
          the privacy laws may not be as protective as those in your
          jurisdiction. If you happen to be located outside the United States
          and choose to provide your Personal Information to us, we may transfer
          your Personal Information to the United States and process it there if
          we deem it necessary to perform our contractual responsibilities to
          you or in your best interests in the context of our contractual
          relationship.
        </span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p11">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>
            3. WILL YOUR PERSONAL INFORMATION BE SHARED WITH ANYONE?&nbsp;
          </strong>
          &nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            We only share information either with your consent, to comply with
            laws, to protect your rights, or to fulfill business
            obligations.&nbsp;
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p11">&nbsp;</Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          We may process or share data based on the following legal basis:
        </span>
      </Paragraph>
      <ul className="ul2">
        <li className="li13">
          <span className="s1">
            <strong>Consent:</strong>&nbsp;We may process your data if you have
            given us specific consent to use your Personal Information in a
            specific purpose.
          </span>
        </li>
        <li className="li13">
          <span className="s1">
            <strong>Legitimate Interests:</strong>&nbsp;We may process your data
            when it is reasonably necessary to achieve our legitimate business
            interests.
          </span>
        </li>
        <li className="li13">
          <span className="s1">
            <strong>Performance of a Contract:&nbsp;</strong>Where we have
            entered into a contract with you, we may process your Personal
            Information to fulfill the terms of our contract.
          </span>
        </li>
        <li className="li13">
          <span className="s1">
            <strong>Legal Obligations:</strong>&nbsp;We may disclose your
            information where we are legally required to do so in order to
            comply with applicable law, governmental requests, a judicial
            proceeding, court order, or legal process, such as in response to a
            court order or a subpoena (including in response to public
            authorities to meet national security or law enforcement
            requirements).&nbsp;
          </span>
        </li>
        <li className="li13">
          <span className="s1">
            <strong>Vital Interests:</strong>&nbsp;We may disclose your
            information where we believe it is necessary to investigate,
            prevent, or take action regarding potential violations of our
            policies, suspected fraud, situations involving potential threats to
            the safety of any person and illegal activities, or as evidence in
            litigation in which we are involved.
          </span>
        </li>
      </ul>
      <Paragraph className="p15">&nbsp;</Paragraph>
      <Paragraph className="p12">
        <span className="s1">
          Additionally, we may need to process your data or share your Personal
          Information in the following situations:
        </span>
        <span className="s6">&nbsp;</span>
        <span className="s1">&nbsp;</span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <ul className="ul2">
        <li className="li13">
          <span className="s1">
            <strong>Service Providers.</strong>&nbsp;We may share your data with
            our Service Providers. Examples include: payment processing, data
            analysis, email delivery, hosting services, customer service and
            marketing efforts. We may allow Service Providers to use tracking
            technology on the&nbsp;Sites, which will enable them to collect data
            about how you interact with the&nbsp;Sites&nbsp;over time. This
            information may be used to, among other things, analyze and track
            data, determine the popularity of certain content and better
            understand online activity. Unless described in this Policy, we do
            not share, sell, rent or trade any of your Personal Information with
            any Third Party or Service Provider for their promotional
            purposes.&nbsp;
          </span>
        </li>
        <li className="li13">
          <span className="s1">
            <strong>Business Transfers.</strong>&nbsp;We may share or transfer
            your Personal Information in connection with, or during negotiations
            of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.&nbsp;&nbsp;
          </span>
          <span className="s6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span className="s1">&nbsp;</span>
          <span className="s6">&nbsp;&nbsp;</span>
        </li>
      </ul>
      <Paragraph className="p16">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>4. HOW LONG DO WE KEEP YOUR INFORMATION?&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            We keep your Personal Information for as long as necessary to
            fulfill the purposes outlined in this privacy policy unless
            otherwise required by law.
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          We will only keep your Personal Information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). When we have no ongoing
          legitimate business need to process your Personal Information, we will
          either delete or anonymize it, or, if this is not possible (for
          example, because your Personal Information has been stored in backup
          archives), then we will securely store your Personal Information and
          isolate it from any further processing until deletion is possible.
        </span>
      </Paragraph>
      <Paragraph className="p4">
        <span className="s1">
          <strong>5. HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;&nbsp;</strong>
          &nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            We aim to protect your Personal Information through a system of
            organizational and technical security measures.
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any Personal Information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure. Although we will do our best
          to protect your Personal Information, transmission of Personal
          Information to and from our&nbsp;Sites&nbsp;is at your own risk. You
          should only access the services within a secure environment.&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>6. DO WE COLLECT INFORMATION FROM MINORS?&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            We do not knowingly collect data from or market to children under 18
            years of age.
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the&nbsp;Sites, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the&nbsp;Sites. If we
          learn that Personal Information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we have collected from children under age 18, please
          contact us at&nbsp;
          <a href="mailto:hello@compassandnail.com">
            <span className="s3">hello@compassandnail.com</span>
          </a>
        </span>
        <span className="s6">.</span>
      </Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>7. WHAT ARE YOUR PRIVACY RIGHTS?&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            In some regions, such as the European Economic Area and California,
            you have rights that allow you greater access to and control over
            your Personal Information.&nbsp;You may review, change, or terminate
            your account at any time.
          </em>
          &nbsp;&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          You have certain rights under applicable data protection laws. These
          may include the right (i) to request access and obtain a copy of your
          Personal Information, (ii) to request rectification or erasure; (iii)
          to restrict the processing of your Personal Information and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your Personal
          Information. To make such a request, please use the
        </span>
        <span className="s6">&nbsp;</span>
        <span className="s1">
          contact details&nbsp;provided below. We will consider and act upon any
          request in accordance with applicable data protection laws.
        </span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          If we are relying on your consent to process your Personal
          Information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal.&nbsp;&nbsp;
        </span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your Personal Information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
        </span>
        <span className="s6">
          &nbsp;
          <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            <span className="s11">
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </span>
          </a>
        </span>
        <span className="s12">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p9">
        <span className="s1">
          <strong>Account Information</strong>&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:&nbsp;
        </span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p17">
        <span className="s6">■ &nbsp;</span>
        <span className="s1">
          Log into your account settings and update your user account.&nbsp;
        </span>
        <span className="s6">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal
          requirements.&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span className="s6">&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s5">
          <strong>Opting out of email marketing:</strong>
        </span>
        <span className="s1">
          <strong>&nbsp;</strong>You can unsubscribe from our marketing email
          list at any time by clicking on the unsubscribe link in the emails
          that we send or by contacting us using the details provided below. You
          will then be removed from the marketing email list. However, we will
          still need to send you service-related emails that are necessary for
          the administration and use of your account. To otherwise opt-out, you
          may:&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p17">
        <span className="s6">■ &nbsp;</span>
        <span className="s1">
          Access your account settings and update preferences.&nbsp;&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p11">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>8. CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or
          setting you can activate to signal your privacy preference not to have
          data about your online browsing activities monitored and collected. No
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this Privacy
          Policy.&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p11">&nbsp;</Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>
            9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?&nbsp;
          </strong>
          &nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your Personal Information.&nbsp;
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p7">
        <span className="s1">
          If you are a California resident and you have provided us with your
          Personal Information, you have the right to request that we:
        </span>
      </Paragraph>
      <Paragraph className="p18">
        <span className="s1">
          i.<span className="Apple-converted-space">&nbsp; </span>Disclose the
          categories and specific pieces of Personal Information we have
          collected;
        </span>
      </Paragraph>
      <Paragraph className="p18">
        <span className="s1">
          ii. Disclose the categories of sources from which the Personal
          Information is collected;
        </span>
      </Paragraph>
      <Paragraph className="p19">
        <span className="s1">
          iii. Disclose the purpose for collecting or selling your Personal
          Information;
        </span>
      </Paragraph>
      <Paragraph className="p18">
        <span className="s1">
          iv. Disclose the categories of third parties with whom we share your
          Personal Information;
        </span>
      </Paragraph>
      <Paragraph className="p18">
        <span className="s1">
          v.<span className="Apple-converted-space">&nbsp; </span>Delete any
          Personal Information about you that we have collected from you,
          subject to certain exceptions; and
        </span>
      </Paragraph>
      <Paragraph className="p19">
        <span className="s1">vi. Not sell your Personal Information. </span>
      </Paragraph>
      <Paragraph className="p7">
        <span className="s1">
          Such requests must be submitted to us in writing either to or write us
          at the contact information under How You Contact Us below.{' '}
        </span>
      </Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>10. DO WE MAKE UPDATES TO THIS POLICY?&nbsp;</strong>&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          <strong>
            <em>In Short:&nbsp;</em>&nbsp;
          </strong>
          <em>
            Yes, we will update this policy as necessary to stay compliant with
            relevant laws.
          </em>
          &nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p10">
        <span className="s9">
          We may update this privacy policy from time to time.{' '}
        </span>
        <span className="s1">
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat your Personal
          Information, we will notify you by email to the email address
          specified in your account or through a notice on the Sites home page.
          The date the privacy policy was last revised is identified at the top
          of the page. You are responsible for ensuring we have an up-to-date
          active and deliverable email address for you, and for periodically
          visiting our Sites and this privacy policy to check for any changes.
        </span>
      </Paragraph>
      <Paragraph className="p6">
        <span className="s1">
          <strong>11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?&nbsp;</strong>
          &nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p3">
        <span className="s1">
          If you have questions or comments about this policy, you
          may&nbsp;email us at&nbsp;
          <a href="mailto:hello@compassandnail.com">
            <span className="s3">hello@compassandnail.com</span>
          </a>{' '}
          or by post to:
        </span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          Compass and Nail, Inc.&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">PO Box 1114</span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">Ojai,&nbsp;CA&nbsp;93023</span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">United States</span>
      </Paragraph>
      <Paragraph className="p20">&nbsp;</Paragraph>
      <Paragraph className="p21">
        <span className="s1">&nbsp;</span>
      </Paragraph>
      <Paragraph className="p22">
        <span className="s1">
          <strong>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </strong>
        </span>
      </Paragraph>
      <Paragraph className="p13">
        <span className="s1">
          Based on the laws of some countries, you may have the right to request
          access to the Personal Information we collect from you, change that
          information, or delete it in some circumstances. To request to review,
          update, or delete your Personal Information, please submit a request
          to&nbsp;
          <a href="mailto:hello@compassandnail.com">
            <span className="s3">hello@compassandnail.com</span>
          </a>
          . We will respond to your request within a reasonable time period or
          no longer than 30 days.
        </span>
      </Paragraph>
    </TCWrapper>
  );
}
