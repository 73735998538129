import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import SideBar from '../../components/navigation/sidebar';
import TextInput from '../../components/inputs/text';
import { ButtonHollow } from '../../components/buttons';
import useSession from '../../context/session';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import * as store from 'store';
import { UPDATE_ME } from '../account/account';
import { TermsAndConditions } from './terms';

export const LOGIN = gql`
  mutation ($email: String) {
    requestPassword(email: $email)
  }
`;

export const UPDATE_TERMS = gql`
  mutation {
    updateMe(input: { tc: 1 }) {
      id
    }
  }
`;

export const LoginButton = styled(ButtonHollow as any)`
  width: 100%;
  text-align: center;
  margin: 12px 0;
  padding: 16px;
  font-weight: 700;
`;

interface StakeHolderFindingsScreenProps {
  history?: any;
}

export const Logo = styled.div`
  text-align: center;
`;

export const FormError = styled.div`
  padding: 0.825rem;
  text-align: center;
  background: #de5f5f;
  margin: 0.5rem 0;
  border-radius: 4px;
  color: #fff;
`;

const TCScroller = styled.div`
  height: 500px;
  overflow-y: scroll;
`;

const termsprops: any = { name: 'termly-embed' };

/* tslint:disable */
export const terms: any = (<TermsAndConditions />) as any; /* tslint:disable */

export function RequestPassword(props: StakeHolderFindingsScreenProps) {
  const mutation = useMutation(LOGIN);
  const [error, setError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const login = async () => {
    try {
      const response: any = await mutation({
        variables: {
          email,
        },
      });
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
  };

  if (success) {
    return (
      <Container>
        <Grid>
          <Grid.Unit size={{ sm: 1 }}>
            <Padding all={2} />
          </Grid.Unit>
        </Grid>
        <Grid>
          <Grid.Unit size={{ sm: 3 / 12 }} />
          <Grid.Unit size={{ sm: 6 / 12 }}>
            <Padding all={2}>
              <Padding all={3}>
                <Logo>
                  <img src="/static/logo.png" />
                </Logo>
              </Padding>
              <Box>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>Reset Password</HeadlineTwo>
                      <Divider />

                      <Paragraph>
                        Check your inbox for instructions to reset your
                        password.
                      </Paragraph>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Box>
            </Padding>
          </Grid.Unit>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2} />
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 3 / 12 }} />
        <Grid.Unit size={{ sm: 6 / 12 }}>
          <Padding all={2}>
            <Padding all={3}>
              <Logo>
                <img src="/static/logo.png" />
              </Logo>
            </Padding>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={3}>
                    <HeadlineTwo>Reset Password</HeadlineTwo>
                    <Divider />

                    <TextInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="email"
                      label="Email"
                    />

                    <LoginButton onClick={login}>SUBMIT</LoginButton>
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export default RequestPassword;
