import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge
} from '../../../components/typography';

import LadderStep from './components/loyality.step';
import LadderHeader from './components/loyality.header';
import SideBar from '../../../components/navigation/sidebar';
import { getLoyaltyLadder } from '../../../data';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

interface LoyaltyLadderProps {
  brand: any;
  data: any;
}

export function LoyaltyLadder(props: LoyaltyLadderProps) {
  return (
    <Container>
      <SideBar active={'strategic'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <HeadlineOne>LOYALTY LADDER</HeadlineOne>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <ParagraphLarge>
                        Shows the steps to future state of loyalty and what
                        those steps mean to the customer.
                      </ParagraphLarge>
                    </Padding>
                  </Grid.Unit>
                </Grid>
                <LadderHeader />
                {props.data.map((step, i) => {
                  return <LadderStep key={i} index={i} {...step} />;
                })}
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export const BRAND = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      loyaltyLadder {
        id
        type
        contents
      }
    }
  }
`;

export function LoyaltyLadderScreenLoader(props: any) {
  const { data, error, loading } = useQuery(BRAND, {
    variables: { id: props.match.params.brand }
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <LoyaltyLadder
      data={data.brand.loyaltyLadder.contents}
      brand={props.match.params.brand}
    />
  );
}

export default LoyaltyLadderScreenLoader;
