import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import Grid from 'styled-components-grid';
import { Padding } from "styled-components-spacing";
import SVGInline from 'react-svg-inline';

import { Container } from "../../components/containers/container";
import SideBar from '../../components/navigation/sidebar';
import { Box } from '../../components/containers/box';
import { Divider } from '../../components/decoration/divider';
import { HeadlineOne, HeadlineTwo, Paragraph } from "../../components/typography";
import fiveBondsIcon from "../../components/typography/icons/fiveBondsIcon";

function FiveBonds(props: any) {
    return (
        <Container>
            <SideBar active={'strategic'} brand={props.brand.id} />
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <SVGInline
                            className="title-icon"
                            style={{ marginRight: '15px', marginTop: '15px', height: '45px', width: '45px' }}
                            svg={ fiveBondsIcon }/>
                        <HeadlineOne>FIVE BONDS OF LOYALTY</HeadlineOne>
                    </Padding>
                </Grid.Unit>
            </Grid>
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <Box>
                            <Padding all={3}>
                                <Grid>
                                    <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                                        <Padding all={3}>
                                            <HeadlineTwo>ETHICAL</HeadlineTwo>
                                            <Divider />
                                            <Paragraph>{props.brand.fiveBonds ? props.brand.fiveBonds.contents.ethical : ''}</Paragraph>
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>TRIBAL</HeadlineTwo>
                                            <Divider />
                                            <Paragraph>{props.brand.fiveBonds ? props.brand.fiveBonds.contents.tribal : ''}</Paragraph>
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>SUPERIORITY</HeadlineTwo>
                                            <Divider />
                                            <Paragraph>{props.brand.fiveBonds ? props.brand.fiveBonds.contents.superiority : ''}</Paragraph>
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>PROMOTIONAL</HeadlineTwo>
                                            <Divider />
                                            <Paragraph>{props.brand.fiveBonds ? props.brand.fiveBonds.contents.promotional : ''}</Paragraph>
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>CONVENIENCE</HeadlineTwo>
                                            <Divider />
                                            <Paragraph>{props.brand.fiveBonds ? props.brand.fiveBonds.contents.convenience : ''}</Paragraph>
                                        </Padding>
                                    </Grid.Unit>
                                    <Grid.Unit size={{ sm:1, md: 2/3 }}>
                                        <Padding all={4}>
                                            <div>
                                                <img style={{display:'block',maxWidth:'75%',margin:'auto'}} src="https://cn-staging-compassandnail-origin.s3.amazonaws.com/static/FB.png" />
                                            </div>
                                        </Padding>
                                    </Grid.Unit>
                                </Grid>
                            </Padding>
                        </Box>
                    </Padding>
                </Grid.Unit>
            </Grid>
        </Container>
    )
}

const BRAND = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      fiveBonds {
        id
        type
        contents
      }
    }
  }
`;

function FiveBondsLoader(props) {
  const { data, loading, error } = useQuery(BRAND, {
    variables: {
      id: props.match.params.brand
    }
  })

  if (loading || !data || error) return null;

  return (
    <FiveBonds
      brand={data.brand} />
  );
}

export default FiveBondsLoader;