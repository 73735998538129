import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { Container } from '../containers/container';
import useSession from '../../context/session';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

export const NavigationContainer = styled.div`
  padding: 30px 0;

  position: absolute;
  left: -48px;
  top: 86px;
  z-index: 999;

  &.open {
    padding-right: 130px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.open {
      svg {
        fill: #fff;
      }

      .active {
        .content {
          svg {
            fill: #fff;
          }
        }
      }
    }

    .active {
      .content {
        svg {
          fill: #334450;
        }
      }
    }

    li {
      margin-bottom: 1rem;
      color: #71b9ca;
      font-size: 1.2rem;
      // display: flex;
      // align-items: center;

      display: block;
      height: auto;

      span {
        color: #fff;
        font-family: proxima-nova, sans-serif;
        font-weight: 700;
        font-size: 14px;
        display: inline-flex;
        margin-left: 8px;

        :hover {
          // color: #509bb0;
        }
      }

      a {
        color: #71b9ca;
        text-decoration: none;
        display: flex;
        align-items: center;

        :hover {
          color: #509bb0;
        }
      }

      .content {
        display: flex;
        align-items: center;
        height: 24px;
      }

      svg {
        transition: fill 0.3s;
        fill: #71b9ca;
        stoke: #71b9ca;
        cursor: pointer;
      }

      ul {
        display: block;
        margin: 8px 0 8px 30px;

        li {
          color: #cecece;
          font-family: proxima-nova, sans-serif;
          font-weight: 700;
          font-size: 13px;
          margin-left: 8px;
          margin-bottom: 8px;

          a {
            color: #cecece;

            :hover {
              color: #509bb0;
            }
          }
        }
      }
    }
  }
`;

export const NavigationWrapper = styled(Container)`
  position: relative;
  z-index: 9999;
`;

export const NavigationBackground = styled.div`
  position: fixed;
  width: 100%;
  // height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(51, 68, 80, 0.93);
  z-index: 9998;
  opacity: ${(props: any) => (props.open ? '1' : '0')};
  transition: opacity 0.5s;
  pointer-events: none;
  margin: 0;
  padding: 0;
` as any;

export const MobileNavigation = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export function Navigation(props) {
  const [open, setOpen] = React.useState(false);
  const [segmentsOpen, setSegmentsOpen] = React.useState(true);
  const [libraryOpen, setLibraryOpen] = React.useState(true);
  const { session } = useSession();

  if (!session) return null;

  const closeAll = () => {
    setOpen(false);
    // setSegmentsOpen(false);
    // setLibraryOpen(false);
  };

  return (
    <div>
      <NavigationBackground open={open} />
      <NavigationWrapper>
        <NavigationContainer
          className={open ? 'open' : ''}
          onMouseEnter={(e) => setOpen(true)}
          onMouseLeave={(e) => setOpen(false)}
        >
          <ul className={open ? 'open' : ''}>
            <li className={props.active === 'home' ? 'active' : ''}>
              <div className="content">
                <Link
                  to={`/brands/${props.brand}`}
                  onClick={(e) => setOpen(false)}
                >
                  <SVGInline
                    svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                  />
                  {open ? <span>Home</span> : null}
                </Link>
              </div>
            </li>
            <li className={props.active === 'segments' ? 'active' : ''}>
              <div className="content">
                <SVGInline
                  svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path></svg>`}
                />
                {open ? <span>Customer Segments</span> : null}
              </div>
              {segmentsOpen && open ? (
                <ul>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/segment/prospect`}
                      onClick={(e) => closeAll()}
                    >
                      Prospects
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/segment/casual`}
                      onClick={(e) => closeAll()}
                    >
                      Casuals
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/segment/loyalist`}
                      onClick={(e) => closeAll()}
                    >
                      Loyalists
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/segment/cheerleader`}
                      onClick={(e) => closeAll()}
                    >
                      Cheerleaders
                    </Link>
                  </li>
                  {props.hasDataConfig && (
                    <li>
                      <Link
                        to={`/brands/${props.brand}/segment/lostsouls`}
                        onClick={(e) => closeAll()}
                      >
                        Lost Souls
                      </Link>
                    </li>
                  )}
                </ul>
              ) : null}
            </li>
            <li className={props.active === 'story' ? 'active' : ''}>
              <div className="content">
                <Link
                  onClick={(e) => setOpen(false)}
                  to={`/brands/${props.brand}/story-universe`}
                >
                  <SVGInline
                    svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M10 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM6 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-4 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>`}
                  />
                  {open ? <span>Story Universe</span> : null}
                </Link>
              </div>
            </li>

            <li className={props.active === 'strategic' ? 'active' : ''}>
              <div className="content">
                <SVGInline
                  svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z"></path></svg>`}
                />
                {open ? <span>Strategic Library</span> : null}
              </div>
              {open ? (
                <ul>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/findings/stakeholder`}
                      onClick={(e) => closeAll()}
                    >
                      Stakeholder Findings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/findings/consumer`}
                      onClick={(e) => closeAll()}
                    >
                      Consumer Findings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/ladders/affinity`}
                      onClick={(e) => closeAll()}
                    >
                      Affinity Ladder
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/ladders/loyalty`}
                      onClick={(e) => closeAll()}
                    >
                      Loyalty Ladder
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/`}
                      onClick={(e) => closeAll()}
                    >
                      Customer Activation Cycle
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/story-universe`}
                      onClick={(e) => closeAll()}
                    >
                      Story Universe
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/bonds`}
                      onClick={(e) => closeAll()}
                    >
                      Five Bonds of Loyalty
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/brands/${props.brand}/glossary-of-terms`}
                      onClick={(e) => closeAll()}
                    >
                      Glossary of Terms
                    </Link>
                  </li>
                </ul>
              ) : null}
            </li>
            <li className={props.active === 'downloads' ? 'active' : ''}>
              <div className="content">
                <Link
                  to={`/brands/${props.brand}/strategic-library`}
                  onClick={(e) => {
                    // e.preventDefault();
                    // setLibraryOpen(!libraryOpen);
                    // return false;
                  }}
                >
                  <SVGInline
                    svg={`<?xml version="1.0"?>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>`}
                  />
                  {open ? <span>Downloads</span> : null}
                </Link>
              </div>
              {open ? (
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        process.env.REACT_APP_API || 'http://localhost:3000'
                      }/${props.downloads.stakeholderFindings}`}
                    >
                      Stakeholder Findings
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        process.env.REACT_APP_API || 'http://localhost:3000'
                      }/${props.downloads.consumerFindings}`}
                    >
                      Consumer Findings
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        process.env.REACT_APP_API || 'http://localhost:3000'
                      }/${props.downloads.customerActivationCycle}`}
                    >
                      Customer Activation Cycle
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        process.env.REACT_APP_API || 'http://localhost:3000'
                      }/${props.downloads.loyaltyArchitecture}`}
                    >
                      Loyalty Architecture
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        process.env.REACT_APP_API || 'http://localhost:3000'
                      }/${props.downloads.loyaltyBusinessPlan}`}
                    >
                      Loyalty Business Plan
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        process.env.REACT_APP_API || 'http://localhost:3000'
                      }/${props.downloads.brandBook}`}
                    >
                      Brand Propagation Toolkit
                    </a>
                  </li>
                </ul>
              ) : null}
            </li>
            <li>
              <div className="content">
                <Link
                  to={`/brands/${props.brand}/account`}
                  onClick={(e) => {
                    // e.preventDefault();
                    // setLibraryOpen(!libraryOpen);
                    // return false;
                  }}
                >
                  <SVGInline
                    svg={`<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>`}
                  />
                  {open ? <span>Account</span> : null}
                </Link>
              </div>
            </li>
          </ul>
        </NavigationContainer>
      </NavigationWrapper>
    </div>
  );
}

export const GET_BRAND = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      dataConfig {
        reportingTerm
      }
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export function NavigationLoader(props: any) {
  const { data, error, loading } = useQuery(GET_BRAND, {
    variables: { id: props.brand },
  });

  if (error) return null;
  if (loading || !data) return null;

  return (
    <Navigation
      hasDataConfig={
        data.brand.dataConfig && data.brand.dataConfig.reportingTerm
      }
      downloads={data.brand.downloads}
      {...props}
    />
  );
}

export default NavigationLoader;
