import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from "styled-components-spacing";
import * as store from 'store';

import { Box } from '../../components/containers/box';
import { Divider } from '../../components/decoration/divider';
import { HeadlineTwo } from "../../components/typography";
import { StyledInput as StyledTextArea } from '../../components/admin/inputs/textarea';
import AdminScreen from '../../components/admin/containers/screen';
import notify from '../../utils/notify';

export const TextAreaInput = styled(StyledTextArea)`
  border: 1px solid #d7d7d836;
  background: #f6f3f038;
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  height: 140px;
  margin-bottom: 16px;
`;

export const UPDATE_DOC = gql`
  mutation($input: DocumentInput) {
    updateDoc(input: $input) {
      id
      type
      brand
      contents
    }
  }
`;

function AdminFiveBonds(props: any) {

    // Page header.

    let breadcrumbs = [];

    if (store.get('role') === 'Admin') {
        breadcrumbs = [
            { 
                label: 'Admin', 
                link: `/admin` 
            },
            {
                label: props.account.name,
                link: `/admin/account/${props.account.id}`,
            },
            {   label: 'Brands'   },
            {
                label: props.brand.name,
                link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
            },
            {   label: 'Five Bonds of Loyalty'   },
        ]
    } else {
        breadcrumbs = [
            {
                label: props.brand.name,
                link: `/admin/account/${props.account.id}/brand/${props.brand.id}`,
            },
            {   label: 'Five Bonds of Loyalty'   },
        ]
    }

    const buttons = [{
        label: 'SAVE',
        onClick: save,
    }]

    // Bonds.

    const [bonds, setBonds] = React.useState({
        ethical: '',
        tribal: '',
        superiority: '',
        promotional: '',
        convenience: ''
    })

    React.useEffect(() => {
        if (props.brand.fiveBonds) {
            setBonds(state => ({
                ...state,
                ...props.brand.fiveBonds.contents
            }))
        }
    }, [])
    
    const onChangeBond = (event) => {
        const {name, value} = event.target;
        setBonds(state => ({
            ...state,
            [name]: value
        }))
    }

    // Save.

    const [global, setGlobal] = useGlobal();
    const mutation = useMutation(UPDATE_DOC);

    function save() {
        try {
            mutation({
                variables: {
                    input: {
                        id: props.brand.fiveBonds ? props.brand.fiveBonds.id : '',
                        type: props.brand.fiveBonds ? props.brand.fiveBonds.type : 'FIVE_BONDS',
                        brand: props.brand.id,
                        contents: JSON.stringify(bonds)
                    },
                },
            });
            notify(setGlobal, 'Saved changes');
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <AdminScreen
            headline={'FIVE BONDS OF LOYALTY'}
            breadcrumbs={breadcrumbs}
            buttons={buttons}>
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <Box>
                            <Padding all={2}>
                                <Grid>
                                    <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                                        <Padding all={3}>
                                            <HeadlineTwo>ETHICAL</HeadlineTwo>
                                            <Divider />
                                            <TextAreaInput
                                                name="ethical"
                                                value={bonds.ethical}
                                                onChange={(e => onChangeBond(e))} />
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>TRIBAL</HeadlineTwo>
                                            <Divider />
                                            <TextAreaInput
                                                name="tribal"
                                                value={bonds.tribal}
                                                onChange={(e => onChangeBond(e))} />
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>SUPERIORITY</HeadlineTwo>
                                            <Divider />
                                            <TextAreaInput
                                                name="superiority"
                                                value={bonds.superiority}
                                                onChange={(e => onChangeBond(e))} />
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>PROMOTIONAL</HeadlineTwo>
                                            <Divider />
                                            <TextAreaInput
                                                name="promotional"
                                                value={bonds.promotional}
                                                onChange={(e => onChangeBond(e))} />
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>CONVENIENCE</HeadlineTwo>
                                            <Divider />
                                            <TextAreaInput
                                                name="convenience"
                                                value={bonds.convenience}
                                                onChange={(e => onChangeBond(e))} />
                                        </Padding>
                                    </Grid.Unit>
                                    <Grid.Unit size={{ sm:1, md: 2/3 }}>
                                        <Padding all={4}>
                                            <div>
                                                <img style={{display:'block',maxWidth:'75%',margin:'auto'}} src="https://cn-staging-compassandnail-origin.s3.amazonaws.com/static/FB.png" />
                                            </div>
                                        </Padding>
                                    </Grid.Unit>
                                </Grid>
                            </Padding>
                        </Box>
                    </Padding>
                </Grid.Unit>
            </Grid>
        </AdminScreen>
    )
}

const BRAND = gql`
  query($account: ID, $id: ID) {
    account(id: $account) {
      id
      name
    }
    brand(id: $id) {
      id
      name
      fiveBonds {
        id
        type
        contents
      }
    }
  }
`;

function AdminFiveBondsLoader(props) {
  const { data, loading, error } = useQuery(BRAND, {
    variables: {
      account: props.match.params.account,
      id: props.match.params.brand,
    },
  })

  if (loading || error || !data) return null;

  return (
    <AdminFiveBonds
      brand={data.brand}
      account={data.account} />
  );
}

export default AdminFiveBondsLoader;