import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import { Link } from 'react-router-dom';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
} from '../../components/typography';
import { LinkList, LinkListItem } from '../../components/lists';
import { Divider } from '../../components/decoration/divider';
import { ButtonHollow } from '../../components/buttons';
import { AlignCenter } from '../../components/containers/align';
import SideBar from '../../components/navigation/sidebar';
import breakpoint from 'styled-components-breakpoint';
import { getSegments } from '../../data';
import SVGInline from 'react-svg-inline';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { Brand } from './choose';
import cacIcon from "../../components/typography/icons/cacIcon";

export const SegmentTitle = styled(HeadlineThree)`
  color: #657c89;
  font-size: 14px;
`;
export const SegmentText = styled(Paragraph)``;

export const SegmentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  // pointer-events: none;

  ${breakpoint('md')`
    display: flex;
  `};
`;

export const SegmentCircle = styled.div`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
`;

export const SegmentCircleInner = styled.div`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: white;
  border: 2px solid #657c89;
  display: inline-flex;
`;

export const SegmentCircleOuterTopLeft = styled(Link)`
  width: 39px;
  height: 39px;
  background: #fff;
  border: 1px solid #509bb0;

  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #509bb0;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 1000;
  cursor: pointer;
  text-decoration: none;
`;

export const SegmentCircleOuterTopRight = styled(Link)`
  width: 32px;
  height: 32px;
  background: #fff;
  border: 4px solid #509bb0;

  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #509bb0;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 99;
  cursor: pointer;
  text-decoration: none;
`;

export const SegmentCircleOuterBottomRight = styled(Link)`
  width: 24px;
  height: 24px;
  background: #fff;
  border: 8px solid #509bb0;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #509bb0;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 99;
  cursor: pointer;
  text-decoration: none;
`;

export const SegmentCircleOuterBottomLeft = styled(Link)`
  width: 40px;
  height: 40px;
  background: #509bb0;

  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 99;
  cursor: pointer;
  text-decoration: none;
`;

export const Segments = styled.div`
  position: relative;
`;

interface SegmentPanelProps {
  segment: any;
  index: number;
  id: any;
  brand: any;
}

export function getBorderStyle(index) {
  switch (index) {
    case 0: {
      return {
        borderRight: '1px solid #d9ebf4',
        borderBottom: '1px solid #d9ebf4',
      };
    }

    case 1: {
      return {
        borderBottom: '1px solid #d9ebf4',
      };
    }

    case 2: {
      return {
        borderRight: '1px solid #d9ebf4',
      };
    }
  }
}

export function getBorderRight(index) {
  switch (index) {
    case 0: {
      return '1px solid #d9ebf4';
    }

    case 1: {
      return '1px solid #d9ebf4';
    }

    case 2: {
      return '1px solid #d9ebf4';
    }
  }

  return 'none';
}

export function getBorderBottom(index) {
  // switch (index) {
  //   case 0: {
  //     return '1px solid #d9ebf4';
  //   }

  //   case 1: {
  //     return '1px solid #d9ebf4';
  //   }
  // }

  return 'none';
}

export const SegmentGridUnit = styled(Grid.Unit)`
  ${breakpoint('md')`
    border-right: ${(props) => getBorderRight(props.index)}
    border-bottom: ${(props) => getBorderBottom(props.index)}

  `}// position: relative;
`;

const segmentCircles = [];

export function SegmentPanel(props: SegmentPanelProps) {
  function getCircle() {
    switch (props.index) {
      case 0: {
        return (
          <SegmentCircleOuterTopLeft to={`/brands/${props.brand}/segment/prospect`}>
            P
          </SegmentCircleOuterTopLeft>
        );
      }

      case 1: {
        return (
          <SegmentCircleOuterTopRight to={`/brands/${props.brand}/segment/casual`}>
            CA
          </SegmentCircleOuterTopRight>
        );
      }

      case 3: {
        return (
          <SegmentCircleOuterBottomLeft to={`/brands/${props.brand}/segment/cheerleader`}>
            CH
          </SegmentCircleOuterBottomLeft>
        );
      }

      case 2: {
        return (
          <SegmentCircleOuterBottomRight to={`/brands/${props.brand}/segment/loyalist`}>
            L
          </SegmentCircleOuterBottomRight>
        );
      }

      default: {
        return null;
      }
    }
  }

  return (
    <SegmentGridUnit index={props.index} size={{ sm: 1, md: 1 / 4 }}>
      <Padding
        all={{ xs: 3, sm: 4, md: 4 }}
        top={{ xs: 3, sm: 3, md: 3 }}
        bottom={{ xs: 3, sm: 3, md: 3 }}
        style={{
          position: 'relative',
          zIndex: 999,
          display: 'flex',
          flex: '0 0 100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          height: '100%',
        }}
      >
        <div
          style={{
            minHeight: '300px',
            width: '100%',
            flex: '0 0 100%',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'center' }}>{getCircle()}</div>
          <SegmentTitle>{props.segment.name}</SegmentTitle>
          <SegmentText>
            <b>Attributes:</b>
            <br />
            {props.segment.contents.attributes}
          </SegmentText>
          <SegmentText>
            <b>Goals:</b>
            <br />
            {props.segment.contents.goals}
          </SegmentText>
          <SegmentText>
            <b>Process:</b>
            <br />
            {props.segment.contents.process}
          </SegmentText>
          <SegmentText>
            <b>Key Consumer Insight:</b>
            <br />
            {props.segment.contents.insights}
          </SegmentText>
        </div>
        <div
          style={{
            paddingBottom: '3rem',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <AlignCenter>
            <Padding all={2}>
              <Link
                style={{ position: 'relative', zIndex: 999 }}
                to={`/brands/${props.brand}/segment/${props.id}`}
              >
                <ButtonHollow>SEGMENT DETAILS</ButtonHollow>
              </Link>
            </Padding>
          </AlignCenter>
        </div>
      </Padding>
    </SegmentGridUnit>
  );
}

interface DashboardProps {
  segments: any[];
  downloads: any;
  brand: any;
  history: any;
  setMode: any;
  hasDataConfig: boolean;
}

export const Toggle = styled.span`
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  margin: 8px 4px 0 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

export const ToggleActive = styled(Toggle)`
  color: #509bb0;
`;

export const ToggleActiveBorder = styled.div`
  position: absolute;
  height: 3px;
  background: #509bb0;
  width: 100%;
  top: 28px;
  left: 0;
`;

export function Dashboard(props: DashboardProps) {
  return (
    <Container>
      <SideBar active={'home'} brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          <Padding all={2}>
            <SVGInline
                className="title-icon"
                style={{ marginRight: '15px', marginTop: '15px', height: '45px', width: '45px' }}
                svg={ cacIcon }/>
            <HeadlineOne>CUSTOMER ACTIVATION CYCLE</HeadlineOne>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Padding top={3} left={2} right={2}>
                      {props.hasDataConfig && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            height: '31px',
                          }}
                        >
                          <Toggle onClick={(e) => props.setMode('data')}>
                            SEGMENT ACTIVATION
                          </Toggle>
                          <ToggleActive>
                            DESCRIPTIONS <ToggleActiveBorder />
                          </ToggleActive>
                        </div>
                      )}
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Padding left={2} right={2}>
                      <Divider />
                    </Padding>
                  </Grid.Unit>
                </Grid>
                <Segments>
                  <Grid>
                    {/* <SegmentWrapper>
                      <SegmentCircle>
                        <SegmentCircleOuterTopLeft
                          onClick={(e) => {

                            props.history.push(
                              `/brands/${props.brand}/segment/prospect`
                            );
                          }}
                        >
                          P
                        </SegmentCircleOuterTopLeft>
                        <SegmentCircleOuterTopRight
                          onClick={(e) =>
                            props.history.push(
                              `/brands/${props.brand}/segment/casual`
                            )
                          }
                        >
                          CA
                        </SegmentCircleOuterTopRight>
                        <SegmentCircleOuterBottomLeft
                          onClick={(e) =>
                            props.history.push(
                              `/brands/${props.brand}/segment/cheerleader`
                            )
                          }
                        >
                          CH
                        </SegmentCircleOuterBottomLeft>
                        <SegmentCircleOuterBottomRight
                          onClick={(e) =>
                            props.history.push(
                              `/brands/${props.brand}/segment/loyalist`
                            )
                          }
                        >
                          L
                        </SegmentCircleOuterBottomRight>
                        <SegmentCircleInner />
                      </SegmentCircle>
                    </SegmentWrapper> */}
                    {props.segments.map((segment, i) => (
                      <SegmentPanel
                        key={segment.id}
                        id={segment.id}
                        brand={props.brand}
                        index={i}
                        segment={segment}
                      />
                    ))}
                  </Grid>
                </Segments>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
        {/* <Grid.Unit size={{ sm: 1, md: 3 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                      <HeadlineTwo>STRATEGIC LIBRARY</HeadlineTwo>
                      <Divider />
                    </Padding>
                    <Padding all={2}>
                      <LinkList>
                        <LinkListItem>
                          <Link
                            to={`/brands/${props.brand}/findings/stakeholder`}
                          >
                            Stakeholder Findings
                          </Link>
                        </LinkListItem>
                        <LinkListItem>
                          <Link to={`/brands/${props.brand}/findings/consumer`}>
                            {' '}
                            Consumer Findings
                          </Link>
                        </LinkListItem>
                        <LinkListItem>
                          <Link to={`/brands/${props.brand}/ladders/affinity`}>
                            Affinity Ladder
                          </Link>
                        </LinkListItem>
                        <LinkListItem>
                          <Link to={`/brands/${props.brand}/ladders/loyalty`}>
                            Loyalty Ladder
                          </Link>
                        </LinkListItem>
                        <LinkListItem>
                          <Link to={`/brands/${props.brand}`}>
                            Customer Activation Cycle
                          </Link>
                        </LinkListItem>
                        <LinkListItem>
                          <Link to={`/brands/${props.brand}/story-universe`}>
                            Story Universe
                          </Link>
                        </LinkListItem>
                      </LinkList>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                      <HeadlineTwo>DOWNLOADS</HeadlineTwo>
                      <Divider />
                      <Padding all={2}>
                        <LinkList>
                          <LinkListItem>
                            <a
                              target="_blank"
                              style={{ display: 'flex', alignItems: 'center' }}
                              href={`${
                                process.env.REACT_APP_API ||
                                'http://localhost:3000'
                              }/${props.downloads.stakeholderFindings}`}
                            >
                              {' '}
                              <SVGInline
                                style={{ marginRight: '2px', height: '13px' }}
                                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                              />
                              Stakeholder Findings
                            </a>
                          </LinkListItem>
                          <LinkListItem>
                            <a
                              style={{ display: 'flex', alignItems: 'center' }}
                              href={`${
                                process.env.REACT_APP_API ||
                                'http://localhost:3000'
                              }/${props.downloads.consumerFindings}`}
                              target="_blank"
                            >
                              <SVGInline
                                style={{ marginRight: '2px', height: '13px' }}
                                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                              />
                              Consumer Findings
                            </a>
                          </LinkListItem>
                          <LinkListItem>
                            <a
                              style={{ display: 'flex', alignItems: 'center' }}
                              href={`${
                                process.env.REACT_APP_API ||
                                'http://localhost:3000'
                              }/${props.downloads.customerActivationCycle}`}
                              target="_blank"
                            >
                              <SVGInline
                                style={{ marginRight: '2px', height: '13px' }}
                                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                              />
                              Customer Activiation Cycle
                            </a>
                          </LinkListItem>
                          <LinkListItem>
                            <a
                              style={{ display: 'flex', alignItems: 'center' }}
                              target="_blank"
                              href={`${
                                process.env.REACT_APP_API ||
                                'http://localhost:3000'
                              }/${props.downloads.loyaltyArchitecture}`}
                            >
                              <SVGInline
                                style={{ marginRight: '2px', height: '13px' }}
                                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                              />
                              Loyalty Architecture
                            </a>
                          </LinkListItem>
                          <LinkListItem>
                            <a
                              style={{ display: 'flex', alignItems: 'center' }}
                              target="_blank"
                              href={`${
                                process.env.REACT_APP_API ||
                                'http://localhost:3000'
                              }/${props.downloads.loyaltyBusinessPlan}`}
                            >
                              <SVGInline
                                style={{ marginRight: '2px', height: '13px' }}
                                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                              />
                              Loyalty Business Plan
                            </a>
                          </LinkListItem>

                          <LinkListItem>
                            <a
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              target="_blank"
                              href={`${
                                process.env.REACT_APP_API ||
                                'http://localhost:3000'
                              }/${props.downloads.brandBook}`}
                            >
                              <SVGInline
                                style={{ marginRight: '2px', height: '13px' }}
                                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" fill="#509bb0" width="13" height="12" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                              />
                              Brand Propagation Toolkit
                            </a>
                          </LinkListItem>
                        </LinkList>
                      </Padding>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit> */}
      </Grid>
    </Container>
  );
}

export const GET_BRAND = gql`
  query ($id: ID) {
    me {
      id
    }
    brand(id: $id) {
      id
      name
      loyalist {
        id
        contents
      }
      prospect {
        id
        contents
      }
      cheerleader {
        id
        contents
      }
      casual {
        id
        contents
      }
      dataConfig {
        reportingTerm
        prospects {
          users
          costPer
        }
      }
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export function DashboardLoader(props: any) {
  const { data, error, loading } = useQuery(GET_BRAND, {
    variables: { id: props.match.params.brand },
  });

  if (error) return null;
  if (loading || !data) return null;

  const segments = [
    { ...data.brand.prospect, name: 'PROSPECTS', id: 'prospect' },
    { ...data.brand.casual, name: 'CASUALS', id: 'casual' },

    { ...data.brand.loyalist, name: 'LOYALISTS', id: 'loyalist' },
    { ...data.brand.cheerleader, name: 'CHEERLEADERS', id: 'cheerleader' },
  ];

  return (
    <Dashboard
      {...props}
      brand={props.match.params.brand}
      downloads={data.brand.downloads || {}}
      setMode={props.setMode}
      hasDataConfig={
        data.brand.dataConfig && data.brand.dataConfig.reportingTerm
      }
      segments={segments}
    />
  );
}

export default DashboardLoader;
