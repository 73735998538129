import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import {
  HeadlineTwo,
  HeadlineOne,
  Paragraph
} from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

export function AdminAccount(props) {
  const buttons = [
    { label: 'Add User', link: `/admin/account/${props.account.id}/add-user` },
    {
      label: 'Add Brand',
      link: `/admin/account/${props.account.id}/add-brand`
    },
    { label: 'Edit Account', link: `/admin/account/${props.account.id}/edit` }
  ];

  const breadcrumbs = [
    { label: 'Admin', link: `/admin` },
    { label: props.account.name, link: `/admin/account/${props.account.id}` }
  ];

  return (
    <AdminScreen
      breadcrumbs={breadcrumbs}
      headline={props.account.name}
      buttons={buttons}>
      <HeadlineTwo>Brands</HeadlineTwo>
      {props.account.brands.map((brand, key) => {
        return (
          <Row>
            <Link to={`/admin/account/${props.account.id}/brand/${brand.id}`}>
              {brand.name}
            </Link>
          </Row>
        );
      })}
      {props.account.brands.length === 0 && (
        <Paragraph>
          This account has no brands.{' '}
          <Link to={`/admin/account/${props.account.id}/add-brand`}>
            Add your first brand.
          </Link>
        </Paragraph>
      )}

      <HeadlineTwo>Users</HeadlineTwo>
      {props.account.users.map((user, key) => {
        return (
          <Row key={user.id}>
            <Link to={`/admin/account/${props.account.id}/user/${user.id}`}>
              {user.firstName} {user.lastName}
            </Link>
          </Row>
        );
      })}
      {props.account.users.length === 0 && (
        <Paragraph>
          This account has no users.{' '}
          <Link to={`/admin/account/${props.account.id}/add-user`}>
            Add your first user.
          </Link>
        </Paragraph>
      )}
    </AdminScreen>
  );
}

export const ACCOUNT = gql`
  query($id: ID) {
    account(id: $id) {
      id
      name
      brands {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(ACCOUNT, {
    variables: { id: props.match.params.account },
    fetchPolicy: 'network-only'
  });

  if (loading || !data) return null;
  if (error) return null;

  return <AdminAccount account={data.account} />;
}

export default AdminAccountLoader;
