import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import { getBrands } from '../../data';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

export const SegmentTitle = styled(HeadlineThree)``;
export const SegmentText = styled(Paragraph)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  brands: any;
}

export const BrandGridUnit = styled(Grid.Unit)`
  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    min-height: 200px;
  }
`;

export function Brand(props) {
  return (
    <BrandGridUnit size={{ sm: 3 / 12 }}>
      <Padding all={2}>
        <Box>
          <Padding all={2}>
            <Grid>
              <Grid.Unit size={{ sm: 12 / 12, md: 12 / 12 }}>
                <Padding all={3} style={{ textAlign: 'center' }}>
                  <Link to={`/brands/${props.id}`}>
                    <img
                      src={`${process.env.REACT_APP_API ||
                        'http://localhost:3000'}/${props.logo}`}
                    />
                  </Link>
                </Padding>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 12 / 12, md: 12 / 12 }}>
                <Padding all={2} style={{ textAlign: 'center' }}>
                  <Link to={`/brands/${props.id}`}>
                    <HeadlineTwo>{props.name}</HeadlineTwo>
                  </Link>
                </Padding>
              </Grid.Unit>
            </Grid>
          </Padding>
        </Box>
      </Padding>
    </BrandGridUnit>
  );
}

export function ChooseBrand(props: DashboardProps) {
  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <HeadlineOne>Your Brands</HeadlineOne>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        {props.brands.map(brand => {
          return (
            <Brand
              name={brand.name}
              logo={brand.logo}
              id={brand.id}
              key={brand.id}
            />
          );
        })}
      </Grid>
    </Container>
  );
}

export const BRANDS = gql`
  query {
    brands {
      id
      name
      logo
    }
  }
`;

export function ChooseBrandLoader(props: any) {
  const { data, error, loading } = useQuery(BRANDS);

  if (error) return null;
  if (loading || !data) return null;

  const brands = getBrands();
  return <ChooseBrand brands={data.brands} />;
}

export default ChooseBrandLoader;
